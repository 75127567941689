import {
  Button,
  CardActionArea,
  CardContent,
  CardMedia,
  Drawer,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import Card from "@mui/material/Card";
import { PositionDto } from "api";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { getLevelTitle } from "utils/model";
import { useState } from "react";
import DrawerContent from "components/drawers/DrawerContent";
import DrawerHeader from "components/drawers/DrawerHeader";
import PositionsInfoView from "views/CardViews/PositionsInfoView";
import { useLocales } from "locales";

const ReadMoreButton = styled(Button)({
  padding: "0px",
  height: "20px",
});

export interface PositionCardProps {
  data?: PositionDto | null;
}

export default function PositionCard({ data }: PositionCardProps) {
  const [open, setOpen] = useState(false);
  const { t, currentLang } = useLocales();

  function handleClose(): void {
    setOpen(false);
  }

  return (
    <>
      <Card
        elevation={0}
        sx={{
          display: "flex",
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          boxShadow: "0px 1px 24px #0000000F",
          padding: 1,
          mb: "5px",
          "& .MuiCardContent-root": {
            p: 0,
          },
          "& .MuiCardContent-root:last-child": {
            pb: 0,
          },
        }}
      >
        <CardActionArea onClick={() => setOpen(true)}>
          <Stack direction={"row"} spacing={1}>
            <CardMedia
              component={"img"}
              image={
                data?.positionImages && data?.positionImages.length >= 1
                  ? "\\PositionImages\\" + data?.positionImages[0].path
                  : "\\Images\\no-image.png"
              }
              alt="Position"
              sx={{
                height: 80,
                width: 80,
                borderRadius: "5px",
                m: 0,
              }}
            />

            <Stack direction={"column"}>
              <CardContent sx={{ p: 0 }}>
                <Typography fontSize={14} sx={{ fontWeight: 600 }} noWrap>
                  {data?.sanskrit}
                </Typography>
                <Typography fontSize={12}>{currentLang.value === 'en' ? data?.english : data?.swedish}</Typography>
                <Typography fontSize={12}>
                  {t(getLevelTitle(data?.level!))}
                </Typography>
                <ReadMoreButton
                  size="small"
                  endIcon={<ArrowCircleRightOutlinedIcon fontSize="inherit" />}
                >
                  {t("readMore")}
                </ReadMoreButton>
              </CardContent>
            </Stack>
          </Stack>
        </CardActionArea>
      </Card>
      <Drawer open={open} anchor={"right"} onClose={() => setOpen(false)}>
        <DrawerHeader handleClose={handleClose} />
        <DrawerContent>
          <PositionsInfoView position={data} />
        </DrawerContent>
      </Drawer>
    </>
  );
}
