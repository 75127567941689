import { Grid, Typography } from "@mui/material";
import ChangePasswordForm from "components/forms/ChangePasswordForm";
import { useLocales } from "locales";

export default function ChangePasswordView() {
  const {t} = useLocales();
  return (
    <Grid sx={{ ml: { xs: 0, md: "240px" }, mt: { xs: "89px", md: "49px" } }}>
      <Grid container>
        <Grid item xs={12} sx={{ mb: "40px" }}>
          <Grid container xs={12} sx={{ justifyContent: "center" }}>
            <img src="soulwiseWrapperLogo.png" width={146.45} alt="" />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mb: "40px" }}>
          <Grid container xs={12} sx={{ justifyContent: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("changePassword")}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container xs={12} sx={{ justifyContent: "center" }}>
            <Grid item maxWidth={"300px"}>
              <ChangePasswordForm />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
