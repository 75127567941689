import merge from "lodash/merge";
import { useMemo } from "react";
// @mui
import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  ThemeOptions,
} from "@mui/material/styles";

// system
import { palette } from "./palette";
import { shadows } from "./shadows";
import { customShadows } from "./custom-shadows";
import { componentsOverrides } from "./overrides";
// options
import { presets } from "./options/presets";
import { darkMode } from "./options/dark-mode";
import { contrast } from "./options/contrast";
import { direction } from "./options/right-to-left";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const darkModeOption = darkMode("light");

  const presetsOption = presets("default");

  const contrastOption = contrast(false, "light");

  const directionOption = direction("ltr");

  const baseOption = useMemo(
    () => ({
      palette: palette("light"),
      shadows: shadows("light"),
      customShadows: customShadows("light"),
      typography: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 14,
        h1: {
          fontWeight: 500,
          fontSize: "1.5rem",
        },
        h2: {
          fontWeight: 600,
          fontSize: "1.25rem",
        },
        h3: {
          fontWeight: 600,
          fontSize: "1rem",
          lineHeight: "24px",
        },
        h4: {
          fontWeight: 400,
          fontSize: "0.875rem",
          lineHeight: "24px",
        },
        caption: {
          fontWeight: 400,
          opacity: "60%",
          fontSize: "0.625rem",
        },
      },
      shape: { borderRadius: 8 },
    }),
    []
  );

  const memoizedValue = useMemo(
    () =>
      merge(
        // Base
        baseOption,
        // Direction: remove if not in use
        directionOption,
        // Dark mode: remove if not in use
        darkModeOption,
        // Presets: remove if not in use
        presetsOption,
        // Contrast: remove if not in use
        contrastOption.theme
      ),
    [
      baseOption,
      directionOption,
      darkModeOption,
      presetsOption,
      contrastOption.theme,
    ]
  );

  const theme = createTheme(memoizedValue as ThemeOptions);

  theme.components = merge(
    componentsOverrides(theme),
    contrastOption.components
  );

  const themeWithLocale = useMemo(() => createTheme(theme), [theme]);

  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
