import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ScrollToTop } from "utils/ScrollToTop";

// i18n
import './locales/i18n';

//Views
import { AuthView } from "views/OtherViews/AuthView";
import { HomeView } from "views/MainViews/HomeView";
import { ErrorView } from "views/OtherViews/ErrorView";
import SequenceView from "views/MainViews/SequenceView";
import PositionView from "views/MainViews/PositionView";
import PhilosophyView from "views/MainViews/PhilosophyView";
import InspirationView from "views/InspirationViews/InspirationView";
import InspirationListView from "views/InspirationViews/InspirationListView";
import SequenceInfoView from "views/CardViews/SequenceInfoView";
import MySequenceView from "views/MainViews/MySequenceView";
import NewSequenceView from "views/CreateSequenceViews/NewSequenceView";
import AccountView from "views/UserViews/AccountView";
import MySubscriptionsView from "views/UserViews/MySubscriptionsView";
import ChangeEmailView from "views/UserViews/ChangeEmailView";
import ChangePasswordView from "views/UserViews/ChangePasswordView";
import PhilosophyInfoView from "views/CardViews/PhilosophyInfoView";

//Layouts
import { FullScreenLayout } from "layouts/FullScreenLayout";
import PrivateRoute, { PrivateRouteProps } from "routes/PrivateRoute";
import { useSelector } from "react-redux";
import { useAuthMe } from "store/hooks/useAuthMe";
import { authStateSelector } from "store/slices/authSlice";
import { RegisterView } from "views/OtherViews/RegisterView";
import { MainLayout } from "layouts/MainLayout";
import FaqView from "views/MainViews/FaqView";
import ForgotPasswordView from "views/OtherViews/ForgotPasswordView";
import ResetPasswordView from "views/OtherViews/ResetPasswordView";

import "@fontsource/marcellus";
import PositionAdminView from "views/AdminViews/PositionAdminView";
import AdminView from "views/AdminViews/AdminView";
import ProfileView from "views/UserViews/ProfileView";
import ThemeProvider from "themes";
import { PaymentView } from "views/OtherViews/PaymentView";
import SubscriptionView from "views/OtherViews/SubscriptionView";
import VersionView from "views/OtherViews/VersionView";

import { MotionLazy } from "components/animate/motion-lazy";
import { LocalizationProvider } from "locales";

function App() {
  useAuthMe();
  const authState = useSelector(authStateSelector);
  const defaultProtectedRouteProps: Omit<PrivateRouteProps, "outlet"> = {
    isAuthenticated: authState.isAuthenticated,
    authenticationPath: "/login",
  };

  return (
    <LocalizationProvider>
      <ThemeProvider>
        <MotionLazy>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route element={<MainLayout />}>
                <Route
                  path="*"
                  element={
                    <ErrorView title={"404"} message={"404 not found"} />
                  }
                />
                <Route path={"payment"} element={<PaymentView />} />
                <Route path={"subscription"} element={<SubscriptionView />} />
                <Route
                  index
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<HomeView />}
                    />
                  }
                />
                <Route
                  path={"sequences"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<SequenceView />}
                    />
                  }
                />

                <Route
                  path={"versions"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<VersionView />}
                    />
                  }
                />

                <Route
                  path={"positions"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<PositionView />}
                    />
                  }
                />
                <Route
                  path={"mySequences"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<MySequenceView />}
                    />
                  }
                />
                <Route
                  path={"sequenceInfo"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<SequenceInfoView />}
                    />
                  }
                />

                <Route
                  path={"philosophyInfo"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<PhilosophyInfoView />}
                    />
                  }
                />

                <Route
                  path={"user/account"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<AccountView />}
                    />
                  }
                />
                <Route
                  path={"user/profile/:id"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<ProfileView />}
                    />
                  }
                />
                <Route
                  path={"ChangeEmail"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<ChangeEmailView />}
                    />
                  }
                />
                <Route
                  path={"mySubscription"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<MySubscriptionsView />}
                    />
                  }
                />
                <Route
                  path={"ChangePassword"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<ChangePasswordView />}
                    />
                  }
                />

                <Route
                  path={"philosophy"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<PhilosophyView />}
                    />
                  }
                />

                <Route
                  path={"inspiration"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<InspirationListView />}
                    />
                  }
                />
                <Route
                  path={"inspiration/:id"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<InspirationView />}
                    />
                  }
                />

                <Route
                  path={"faq"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<FaqView />}
                    />
                  }
                />

                <Route
                  path={"admin"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<AdminView />}
                    />
                  }
                />
                <Route
                  path={"admin/positions"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<PositionAdminView />}
                    />
                  }
                />
              </Route>

              <Route element={<FullScreenLayout />}>
                <Route
                  path={"newSequence"}
                  element={
                    <PrivateRoute
                      {...defaultProtectedRouteProps}
                      outlet={<NewSequenceView />}
                    />
                  }
                />

                <Route path={"login"} element={<AuthView />} />
                <Route path={"register"} element={<RegisterView />} />
                <Route path={"logout"} element={<HomeView />} />
                <Route
                  path={"forgotpassword"}
                  element={<ForgotPasswordView />}
                />
                <Route path={"resetpassword"} element={<ResetPasswordView />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </MotionLazy>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
