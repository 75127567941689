import {
  Avatar,
  Drawer,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { SequenceItemDto, SequenceItemType } from "api";
import React, { useState } from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DrawerContent from "components/drawers/DrawerContent";
import DrawerHeader from "components/drawers/DrawerHeader";
import PositionsInfoView from "views/CardViews/PositionsInfoView";
import InfoView from "views/CardViews/InfoView";
import PauseIcon from "@mui/icons-material/Pause";
import { useLocales } from "locales";

export type SequenceListItemProps = {
  item: SequenceItemDto;
  translateText?: string;
};

const SequenceListItem = ({ item, translateText }: SequenceListItemProps) => {
  const [open, setOpen] = useState(false);
  const { t, currentLang } = useLocales();

  function handleClose(): void {
    setOpen(false);
  }

  return (
    <>
      <ListItem
        sx={{
          background: "#fff",
          m: "5px",
          borderRadius: "10px",
          boxShadow: 1,
          minHeight: "55px",
          "&.MuiListItem-root": { py: "0px" },
        }}
      >
        {item?.type === SequenceItemType._1 && (
          <>
            <ListItemAvatar>
              <Avatar
                alt={item.position?.sanskrit!}
                sx={{
                  transform: `scaleX(${item.imageFlip ? item.imageFlip : 1})`,
                }}
                src={
                  "/PositionImages/" + item.position?.positionImages![0].path
                }
              />
            </ListItemAvatar>
            <ListItemText
              sx={{ cursor: "pointer" }}
              onClick={() => setOpen(true)}
              primary={item.position?.sanskrit}
              secondary={
                currentLang.value === "en"
                  ? item.position?.english
                  : item.position?.swedish
              }
            />
          </>
        )}
        {item?.type === SequenceItemType._2 && (
          <>
            <ListItemAvatar>
              <Avatar>
                <AssignmentIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{ cursor: "pointer" }}
              onClick={() => setOpen(true)}
              primary={t('note')}
              secondary={
                <Typography
                  variant="body2"
                  sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                >
                  {translateText ? translateText : item.information}
                </Typography>
              }
            />
          </>
        )}
        {item?.type === SequenceItemType._3 && (
          <>
            <ListItemAvatar>
              <Avatar>
                <PauseIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{ cursor: "pointer" }}
              onClick={() => setOpen(true)}
              primary={"Paus"}
              secondary={translateText ? translateText : item.information}
            />
          </>
        )}
      </ListItem>

      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#FFF",
            minWidth: "300px",
          },
        }}
      >
        <DrawerHeader handleClose={handleClose} />
        <DrawerContent>
          {item?.type === SequenceItemType._1 && (
            <PositionsInfoView position={item.position} refreshData />
          )}
          {item?.type === SequenceItemType._2 && (
            <InfoView item={item} handleClose={handleClose} type="view" translateText={translateText} />
          )}
          {item?.type === SequenceItemType._3 && (
            <InfoView item={item} handleClose={handleClose} type="view" translateText={translateText} />
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SequenceListItem;
