import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { PriceDto, ProductDto } from "api";
import { useLocales } from "locales";
import React from "react";

interface SubscriptionCardProps {
  product: ProductDto;
  price: PriceDto;
  selectedPriceId: number;
  onSelect(priceId: number): void;
}
const SubscriptionCard = ({
  product,
  price,
  selectedPriceId,
  onSelect,
}: SubscriptionCardProps) => {
  const { t } = useLocales();
  const PerMonth = () => {
    return price.trialPeriodDays ? (
      <>
        <Typography variant="h5" component="div">
          {product.name}
        </Typography>
        <Typography variant="h3" component="div">
          {price.trialPeriodDays} {t("daysFree")}
        </Typography>
        <Typography variant="body1" component="div">
          {t("then")} {price.unitAmount! / 100} {t("sekPerMonth")}
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="h4" component="div">
          {product.name}
        </Typography>
        <Typography variant="h2" component="div">
          {price.unitAmount! / 100} {t("sekPerMonth")}
        </Typography>
        <Typography variant="h5" component="div"></Typography>
      </>
    );
  };
  const PerYear = () => {
    return (
      <>
        <Typography variant="h5" component="div">
          {product.name}
        </Typography>
        {price.trialPeriodDays && (
          <>
            <Typography variant="h3" component="div">
              {price.trialPeriodDays} {t("daysFree")}
            </Typography>
            <Typography variant="body1" component="div">
              {t("then")} {price.unitAmount! / 100} {t("sekPerYear")}
            </Typography>
          </>
        )}
        {!price.trialPeriodDays && (
          <>
            <Typography variant="h2" component="div">
              {price.unitAmount! / 100} {t("sekPerYear")}
            </Typography>
            <Typography variant="h5" component="div"></Typography>
          </>
        )}
      </>
    );
  };

  return (
    <Card
      sx={{
        border: selectedPriceId == price.id ? 3 : 1,
        m: selectedPriceId == price.id ? "0px" : "2px",
        height: "100%",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 120,
        }}
      >
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {price.interval === "month" && <PerMonth />}
          {price.interval === "year" && <PerYear />}
        </Stack>
      </CardContent>
      <CardActions sx={{ marginTop: "auto", textAlign: "center" }}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => onSelect(price.id!)}
        >
          {t("select")}
        </Button>
      </CardActions>
    </Card>
  );
};

export default SubscriptionCard;
