import React from 'react'
import Paper from '@mui/material/Paper'

interface SoulwisePaperProps {
  children?: React.ReactNode | string
}

export const SoulwisePaper = (props: SoulwisePaperProps): JSX.Element => {
  return (
    <Paper
      elevation={0}
      
      sx={{
        p: "20px",
        borderRadius: "10px",
        backgroundColor: "#fff"
      }}
    >
      {props.children}
    </Paper>
  )
}
