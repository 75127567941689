import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Alert, Step, StepButton, Stepper } from "@mui/material";
import RegisterForm from "components/forms/RegisterForm";
import { Box, Container, Stack } from "@mui/system";
import SubscriptionList from "components/subscription/SubscriptionList";
import { useDispatch } from "react-redux";
import PaymentForm from "components/forms/payment/PaymentForm";
import {
  paymentStateSelector,
  setActiveStep,
  setPriceId,
  setSessionId,
} from "store/slices/paymentSlice";
import PaymentSummary from "components/forms/payment/PaymentSummary";
import { useLocales } from "locales";

export const RegisterView = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useLocales();

  const steps = [
    t("chooseSubscription"),
    t("createAccount"),
    t("pay"),
    t("compilation"),
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const { activeStep, selectedPriceId, hasError, error } =
    useSelector(paymentStateSelector);

  useEffect(() => {
    const step = searchParams.get("step");
    const sessionId = searchParams.get("session_id");
    console.log(step);
    console.log(sessionId);
    if (sessionId) {
      dispatch(setSessionId(sessionId));
      dispatch(setActiveStep(3));
    } else if (step) dispatch(setActiveStep(parseInt(step)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStep = (step: number) => () => {
    dispatch(setActiveStep(step));
  };

  const handleSelectPrice = (priceId: number) => {
    dispatch(setPriceId(priceId));
    dispatch(setActiveStep(1));
  };

  const handleUserCreated = () => {
    dispatch(setActiveStep(2));
  };

  return (
    <Container maxWidth={"md"}>
      <Stack>
        <Box
          sx={{
            mt: "50px",
            mb: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            height={56}
            src={"/images/soulwisewrapperlogo.png"}
            alt="soulwise yoga"
          />
        </Box>
        {hasError && (
          <Alert severity="error" variant="outlined" sx={{ mb: "20px" }}>
            {error}
          </Alert>
        )}
        <Stepper activeStep={activeStep} sx={{ mb: "20px" }} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};

            return (
              <Step key={label} {...stepProps}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === 0 && (
          <SubscriptionList
            selectedPriceId={selectedPriceId}
            onSelect={handleSelectPrice}
          />
        )}
        {activeStep === 1 && <RegisterForm onUserCreated={handleUserCreated} />}
        {activeStep === 2 && <PaymentForm returnPath="register" />}
        {activeStep === 3 && <PaymentSummary />}
      </Stack>
    </Container>
  );
};
