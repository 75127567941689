import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import PositionForm from "components/forms/PositionForm";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPositionsByFilter } from "store/selectors/position";
import {
  loadPositionsAsync,
  positionStateSelector,
} from "store/slices/positionSlice";
import { StdView } from "views/OtherViews/StdView";
import {
  positionFormStateSelector,
  setPosition,
} from "store/slices/positionFormSlice";
import { authStateSelector } from "store/slices/authSlice";
import _ from "lodash";

const PositionAdminView = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(authStateSelector);
  // const positionList = useSelector(getPositionsByFilter);
  const { positions } = useSelector(positionStateSelector);

  useEffect(() => {
    dispatch<any>(loadPositionsAsync());
  }, [dispatch]);

  return (
    <StdView title={"Positioner"}>
      {currentUser?.isAdmin! ? (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <List sx={{ height: "calc(100vh - 200px)", overflow: "scroll" }}>
              {positions &&
                _.sortBy(positions, "sanskrit").map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      background: "#fff",
                      borderRadius: "10px",
                      boxShadow: 1,
                      "&.MuiListItem-root": { py: "0px", my: "5px" },
                      "&:hover": { background: "rgb(235,235,235)" },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={item.sanskrit!}
                        src={
                          item.positionImages !== undefined &&
                          item.positionImages!.length > 0
                            ? "..\\PositionImages\\" +
                              item.positionImages![0].path
                            : ""
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ cursor: "pointer" }}
                      onClick={() => dispatch(setPosition(item))}
                      primary={item.sanskrit}
                      secondary={item.swedish}
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item xs={8}>
            <PositionForm />
          </Grid>
        </Grid>
      ) : (
        <Typography>Not Authorized</Typography>
      )}
    </StdView>
  );
};

export default PositionAdminView;
