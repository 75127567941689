import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { FormEvent, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, EditorState } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { PositionDto } from "api";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import positionFormSlice, {
  positionFormStateSelector,
  updatePosition,
} from "store/slices/positionFormSlice";
import { authStateSelector } from "store/slices/authSlice";
import AppTextField from "./AppTextField";
import { Form, FormikProvider, useFormik } from "formik";
import {
  getLevelTitle,
  getMainTypesText,
  levelIds,
  mainTypesIds,
} from "utils/model";
import {
  loadPositionsAsync,
  positionStateSelector,
} from "store/slices/positionSlice";
import _ from "lodash";
import { useLocales } from "locales";
import MainTypeItems from "components/sequence/MainTypeItems";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export interface PositionFormProps {}

const PositionForm = ({}: PositionFormProps) => {
  const dispatch = useDispatch();
  const { t } = useLocales();
  const authState = useSelector(authStateSelector);
  const { position } = useSelector(positionFormStateSelector);
  const { positions } = useSelector(positionStateSelector);
  const [saveMessage, setSaveMessage] = useState("");
  const [types, setTypes] = useState<string[]>([]);
  const [instructionText, setInstructionText] = useState(() =>
    EditorState.createEmpty()
  );
  const [benefitsText, setBenefitsText] = useState(() =>
    EditorState.createEmpty()
  );
  const [assistanceText, setAssistanceText] = useState(() =>
    EditorState.createEmpty()
  );
  const [payAttentionToText, setPayAttentionToText] = useState(() =>
    EditorState.createEmpty()
  );
  const [variantsText, setVariantsText] = useState(() =>
    EditorState.createEmpty()
  );

  const [instructionTextEnglish, setInstructionTextEnglish] = useState(() =>
    EditorState.createEmpty()
  );
  const [benefitsTextEnglish, setBenefitsTextEnglish] = useState(() =>
    EditorState.createEmpty()
  );
  const [assistanceTextEnglish, setAssistanceTextEnglish] = useState(() =>
    EditorState.createEmpty()
  );
  const [payAttentionToTextEnglish, setPayAttentionToTextEnglish] = useState(
    () => EditorState.createEmpty()
  );
  const [variantsTextEnglish, setVariantsTextEnglish] = useState(() =>
    EditorState.createEmpty()
  );

  const validationSchema = yup.object({
    sanskrit: yup.string().required("Obligatoriskt"),
  });

  const theme = useTheme();

  useEffect(() => {
    dispatch<any>(loadPositionsAsync());
  }, []);

  useEffect(() => {
    if (position != null && position?.instructionsText!) {
      const blocksFromHTML = convertFromHTML(position?.instructionsText!);
      setInstructionText(EditorState.createWithContent(blocksFromHTML));
    } else setInstructionText(EditorState.createEmpty());

    if (position != null && position?.instructionsTextEnglish!) {
      const blocksFromHTML = convertFromHTML(
        position?.instructionsTextEnglish!
      );
      setInstructionTextEnglish(EditorState.createWithContent(blocksFromHTML));
    } else setInstructionTextEnglish(EditorState.createEmpty());

    if (position != null && position?.benefitsText!) {
      const blocksFromHTML = convertFromHTML(position?.benefitsText!);
      setBenefitsText(EditorState.createWithContent(blocksFromHTML));
    } else setBenefitsText(EditorState.createEmpty());
    if (position != null && position?.benefitsTextEnglish!) {
      const blocksFromHTML = convertFromHTML(position?.benefitsTextEnglish!);
      setBenefitsTextEnglish(EditorState.createWithContent(blocksFromHTML));
    } else setBenefitsTextEnglish(EditorState.createEmpty());

    if (position != null && position?.assistanceText!) {
      const blocksFromHTML = convertFromHTML(position?.assistanceText!);
      setAssistanceText(EditorState.createWithContent(blocksFromHTML));
    } else setAssistanceText(EditorState.createEmpty());
    if (position != null && position?.assistanceTextEnglish!) {
      const blocksFromHTML = convertFromHTML(position?.assistanceTextEnglish!);
      setAssistanceTextEnglish(EditorState.createWithContent(blocksFromHTML));
    } else setAssistanceTextEnglish(EditorState.createEmpty());

    if (position != null && position?.payAttentionToText!) {
      const blocksFromHTML = convertFromHTML(position?.payAttentionToText!);
      setPayAttentionToText(EditorState.createWithContent(blocksFromHTML));
    } else setPayAttentionToText(EditorState.createEmpty());
    if (position != null && position?.payAttentionToTextEnglish!) {
      const blocksFromHTML = convertFromHTML(
        position?.payAttentionToTextEnglish!
      );
      setPayAttentionToTextEnglish(
        EditorState.createWithContent(blocksFromHTML)
      );
    } else setPayAttentionToTextEnglish(EditorState.createEmpty());

    if (position != null && position?.variantsText!) {
      const blocksFromHTML = convertFromHTML(position?.variantsText!);
      setVariantsText(EditorState.createWithContent(blocksFromHTML));
    } else setVariantsText(EditorState.createEmpty());
    if (position != null && position?.variantsTextEnglish!) {
      const blocksFromHTML = convertFromHTML(position?.variantsTextEnglish!);
      setVariantsTextEnglish(EditorState.createWithContent(blocksFromHTML));
    } else setVariantsTextEnglish(EditorState.createEmpty());

    if (position != null && position?.types) {
      setTypes(position?.types.toString().split(","));
    } else setTypes([]);
  }, [position]);

  const handleTypeChange = (event: SelectChangeEvent<typeof types>) => {
    const {
      target: { value },
    } = event;
    setTypes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const formik = useFormik({
    initialValues: {
      positionId: position ? position.positionId : 0,
      mainPurpose: position ? position.mainPurpose : "",
      mainPurposeEnglish: position ? position.mainPurposeEnglish : "",
      level: position ? position.level : 0,
      types: position ? position.types : [],
      sanskrit: position ? position.sanskrit : "",
      english: position ? position.english : "",
      swedish: position ? position.swedish : "",
      private: position ? position.private : true,
      prePose1Id: position ? position.prePose1?.positionId : 0,
      prePose2Id: position ? position.prePose2?.positionId : 0,
      prePose3Id: position ? position.prePose3?.positionId : 0,
      postPose1Id: position ? position.postPose1?.positionId : 0,
      postPose2Id: position ? position.postPose2?.positionId : 0,
      postPose3Id: position ? position.postPose3?.positionId : 0,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      // setShowError(false);
      console.log("Position", values);
     
      try {
        const result = await dispatch<any>(
          updatePosition(
            values.mainPurpose!,
            values.mainPurposeEnglish!,
            values.level!,
            types.map(Number),
            values.sanskrit!,
            values.english!,
            values.swedish!,
            values.private!,
            values.prePose1Id!,
            values.prePose2Id!,
            values.prePose3Id!,
            values.postPose1Id!,
            values.postPose2Id!,
            values.postPose3Id!,
            convertToHTML(instructionText.getCurrentContent()),
            convertToHTML(benefitsText.getCurrentContent()),
            convertToHTML(assistanceText.getCurrentContent()),
            convertToHTML(payAttentionToText.getCurrentContent()),
            convertToHTML(variantsText.getCurrentContent()),
            convertToHTML(instructionTextEnglish.getCurrentContent()),
            convertToHTML(benefitsTextEnglish.getCurrentContent()),
            convertToHTML(assistanceTextEnglish.getCurrentContent()),
            convertToHTML(payAttentionToTextEnglish.getCurrentContent()),
            convertToHTML(variantsTextEnglish.getCurrentContent())
          )
        );

        if (result) setSaveMessage("Sparad");
        else setSaveMessage("ej sparad");
      } catch (error) {
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  return (
    <Box>
      <FormikProvider value={formik}>
        <Form>
          <AppTextField
            color="primary"
            fullWidth
            required
            name="sanskrit"
            value={formik.values.sanskrit ? formik.values.sanskrit : ""}
            title="sanskrit"
            inputProps={{ maxLength: 50 }}
            onChange={formik.handleChange}
          />

          <AppTextField
            color="primary"
            fullWidth
            name="swedish"
            value={formik.values.swedish ? formik.values.swedish : ""}
            title="swedish"
            inputProps={{ maxLength: 50 }}
            onChange={formik.handleChange}
          />

          <AppTextField
            color="primary"
            fullWidth
            name="english"
            value={formik.values.english ? formik.values.english : ""}
            title="english"
            inputProps={{ maxLength: 50 }}
            onChange={formik.handleChange}
          />

          <FormControl sx={{ width: "100%", mt: "10px" }}>
            <InputLabel id="level-select-label">Nivå</InputLabel>
            <Select
              size="small"
              labelId="level-select-label"
              required
              // placeholder="Välj nivå"
              id="level"
              name="level"
              value={
                formik.values.level !== undefined
                  ? formik.values.level?.toString()
                  : "0"
              }
              onChange={(e: SelectChangeEvent) => {
                formik.handleChange(e);
              }}
              error={formik.touched.level && Boolean(formik.errors.level)}
              onBlur={formik.handleBlur}
            >
              <MenuItem key={"n-0"} value={0}>
                Välj Nivå
              </MenuItem>
              {levelIds.map((value, index) => (
                <MenuItem value={value} key={"n-" + index + 1}>
                  {t(getLevelTitle(value))}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
              {formik.touched.level && formik.errors.level}
            </FormHelperText>
          </FormControl>

          <AppTextField
            color="primary"
            fullWidth
            name="mainPurpose"
            value={formik.values.mainPurpose ? formik.values.mainPurpose : ""}
            title="Main Purpose"
            multiline
            rows={2}
            inputProps={{ maxLength: 200 }}
            onChange={formik.handleChange}
          />
          <AppTextField
            color="primary"
            fullWidth
            name="mainPurposeEnglish"
            multiline
            rows={2}
            value={
              formik.values.mainPurposeEnglish
                ? formik.values.mainPurposeEnglish
                : ""
            }
            title="Main Purpose English"
            inputProps={{ maxLength: 200 }}
            onChange={formik.handleChange}
          />

          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.private}
                  name="private"
                  onChange={formik.handleChange}
                />
              }
              label="Privat"
              sx={{ flexGrow: 1, pl: 3 }}
            />
          </Box>

          <FormControl sx={{ my: 1 }} fullWidth>
            <InputLabel id="types-label">Typ</InputLabel>
            <Select
              size="small"
              labelId="types-label"
              fullWidth
              id="types"
              multiple
              value={types}
              onChange={handleTypeChange}
              input={<OutlinedInput label="Typ" />}
              MenuProps={MenuProps}
            >
              {mainTypesIds.map((type) => (
                <MenuItem
                  key={type}
                  value={type.toString()}
                  style={getStyles(type.toString(), types, theme)}
                >
                  {t(getMainTypesText(type))}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: "100%", mt: "10px" }}>
            <InputLabel id="prepos1-select-label">Prepos1</InputLabel>
            <Select
              size="small"
              labelId="prepos1-select-label"
              placeholder="Välj PrePosition1"
              id="prePose1Id"
              name="prePose1Id"
              value={formik.values.prePose1Id?.toString()}
              onChange={(e: SelectChangeEvent) => {
                formik.handleChange(e);
              }}
              error={
                formik.touched.prePose1Id && Boolean(formik.errors.prePose1Id)
              }
              onBlur={formik.handleBlur}
            >
              <MenuItem key={"n-0"} value={0}>
                Välj prePose1
              </MenuItem>
              {_.sortBy(positions, "sanskrit").map((value, index) => (
                <MenuItem
                  value={value.positionId!.toString()}
                  key={"n-" + index + 1}
                >
                  {value.sanskrit}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
              {formik.touched.level && formik.errors.level}
            </FormHelperText>
          </FormControl>

          <FormControl sx={{ width: "100%", mt: "10px" }}>
            <InputLabel id="prepos2-select-label">Prepos2</InputLabel>
            <Select
              size="small"
              labelId="prepos2-select-label"
              placeholder="Välj PrePosition2"
              id="prePose2Id"
              name="prePose2Id"
              value={formik.values.prePose2Id?.toString()}
              onChange={(e: SelectChangeEvent) => {
                formik.handleChange(e);
              }}
              error={
                formik.touched.prePose2Id && Boolean(formik.errors.prePose2Id)
              }
              onBlur={formik.handleBlur}
            >
              <MenuItem key={"n-0"} value={0}>
                Välj prePose2
              </MenuItem>
              {_.sortBy(positions, "sanskrit").map((value, index) => (
                <MenuItem value={value.positionId} key={"n-" + index + 1}>
                  {value.sanskrit}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
              {formik.touched.level && formik.errors.level}
            </FormHelperText>
          </FormControl>

          <FormControl sx={{ width: "100%", mt: "10px" }}>
            <InputLabel id="prepos3-select-label">Prepos3</InputLabel>
            <Select
              size="small"
              labelId="prepos3-select-label"
              placeholder="Välj PrePosition3"
              id="prePose3Id"
              name="prePose3Id"
              value={formik.values.prePose3Id?.toString()}
              onChange={(e: SelectChangeEvent) => {
                formik.handleChange(e);
              }}
              error={
                formik.touched.prePose3Id && Boolean(formik.errors.prePose3Id)
              }
              onBlur={formik.handleBlur}
            >
              <MenuItem key={"n-0"} value={0}>
                Välj prePose3
              </MenuItem>
              {_.sortBy(positions, "sanskrit").map((value, index) => (
                <MenuItem value={value.positionId} key={"n-" + index + 1}>
                  {value.sanskrit}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
              {formik.touched.level && formik.errors.level}
            </FormHelperText>
          </FormControl>

          <FormControl sx={{ width: "100%", mt: "10px" }}>
            <InputLabel id="postpos1-select-label">Postpos1</InputLabel>
            <Select
              size="small"
              labelId="postpos1-select-label"
              placeholder="Välj PostPosition1"
              id="postPose1Id"
              name="postPose1Id"
              value={formik.values.postPose1Id?.toString()}
              onChange={(e: SelectChangeEvent) => {
                formik.handleChange(e);
              }}
              error={
                formik.touched.postPose1Id && Boolean(formik.errors.postPose1Id)
              }
              onBlur={formik.handleBlur}
            >
              <MenuItem key={"n-0"} value={0}>
                Välj postPose1
              </MenuItem>
              {_.sortBy(positions, "sanskrit").map((value, index) => (
                <MenuItem
                  value={value.positionId?.toString()}
                  key={"n-" + index + 1}
                >
                  {value.sanskrit}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
              {formik.touched.level && formik.errors.level}
            </FormHelperText>
          </FormControl>

          <FormControl sx={{ width: "100%", mt: "10px" }}>
            <InputLabel id="postpos2-select-label">Postpos2</InputLabel>
            <Select
              size="small"
              labelId="postpos2-select-label"
              placeholder="Välj PostPosition2"
              id="postPose2Id"
              name="postPose2Id"
              value={formik.values.postPose2Id?.toString()}
              onChange={(e: SelectChangeEvent) => {
                formik.handleChange(e);
              }}
              error={
                formik.touched.postPose2Id && Boolean(formik.errors.postPose2Id)
              }
              onBlur={formik.handleBlur}
            >
              <MenuItem key={"n-0"} value={0}>
                Välj postPose2
              </MenuItem>
              {_.sortBy(positions, "sanskrit").map((value, index) => (
                <MenuItem
                  value={value.positionId!.toString()}
                  key={"n-" + index + 1}
                >
                  {value.sanskrit}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
              {formik.touched.level && formik.errors.level}
            </FormHelperText>
          </FormControl>

          <FormControl sx={{ width: "100%", mt: "10px" }}>
            <InputLabel id="postpos3-select-label">Postpos3</InputLabel>
            <Select
              size="small"
              labelId="prepos3-select-label"
              placeholder="Välj PostPosition3"
              id="postPose3Id"
              name="postPose3Id"
              value={formik.values.postPose3Id?.toString()}
              onChange={(e: SelectChangeEvent) => {
                formik.handleChange(e);
              }}
              error={
                formik.touched.postPose3Id && Boolean(formik.errors.postPose3Id)
              }
              onBlur={formik.handleBlur}
            >
              <MenuItem key={"n-0"} value={0}>
                Välj postPose3
              </MenuItem>
              {_.sortBy(positions, "sanskrit").map((value, index) => (
                <MenuItem
                  value={value.positionId!.toString()}
                  key={"n-" + index + 1}
                >
                  {value.sanskrit}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
              {formik.touched.level && formik.errors.level}
            </FormHelperText>
          </FormControl>

          <Typography variant="h2">Instruktion för elever</Typography>
          <Box
            sx={{
              "& .editorClassName": { backgroundColor: "#fff", mb: "20px" },
            }}
          >
            <Editor
              editorState={instructionText}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setInstructionText}
            />
          </Box>

          <Box
            sx={{
              "& .editorClassName": { backgroundColor: "#fff", mb: "20px" },
            }}
          >
            <Editor
              editorState={instructionTextEnglish}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setInstructionTextEnglish}
            />
          </Box>

          <Typography variant="h2">Positiva effekter</Typography>
          <Box
            sx={{
              "& .editorClassName": { backgroundColor: "#fff", mb: "20px" },
            }}
          >
            <Editor
              editorState={benefitsText}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setBenefitsText}
            />
          </Box>
          <Box
            sx={{
              "& .editorClassName": { backgroundColor: "#fff", mb: "20px" },
            }}
          >
            <Editor
              editorState={benefitsTextEnglish}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setBenefitsTextEnglish}
            />
          </Box>

          <Typography variant="h2">Assitering</Typography>
          <Box
            sx={{
              "& .editorClassName": { backgroundColor: "#fff", mb: "20px" },
            }}
          >
            <Editor
              editorState={assistanceText}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setAssistanceText}
            />
          </Box>
          <Box
            sx={{
              "& .editorClassName": { backgroundColor: "#fff", mb: "20px" },
            }}
          >
            <Editor
              editorState={assistanceTextEnglish}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setAssistanceTextEnglish}
            />
          </Box>

          <Typography variant="h2">Uppmärksamma</Typography>
          <Box
            sx={{
              "& .editorClassName": { backgroundColor: "#fff", mb: "20px" },
            }}
          >
            <Editor
              editorState={payAttentionToText}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setPayAttentionToText}
            />
          </Box>
          <Box
            sx={{
              "& .editorClassName": { backgroundColor: "#fff", mb: "20px" },
            }}
          >
            <Editor
              editorState={payAttentionToTextEnglish}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setPayAttentionToTextEnglish}
            />
          </Box>

          <Typography variant="h2">Varianter</Typography>
          <Box
            sx={{
              "& .editorClassName": { backgroundColor: "#fff", mb: "20px" },
            }}
          >
            <Editor
              editorState={variantsText}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setVariantsText}
            />
          </Box>
          <Box
            sx={{
              "& .editorClassName": { backgroundColor: "#fff", mb: "20px" },
            }}
          >
            <Editor
              editorState={variantsTextEnglish}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setVariantsTextEnglish}
            />
          </Box>
          <Box>
            <Button type="submit" color="primary" variant="contained">
              Spara
            </Button>
          </Box>
          <Box>
            {saveMessage != "" && <Alert severity="info">{saveMessage}</Alert>}
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default PositionForm;
