import * as React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Card, Grid, List, ListItem, Typography, styled } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useState } from "react";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "15px",
  backgroundColor: "#FFF",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

interface PhilosophyInfoAccordionProps {
  title?: string | null;
  info?: string | null;
  children?: React.ReactNode;
}

export const PhilosophyInfoAccordion = (
  props: PhilosophyInfoAccordionProps
): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const bulletPoints = props.info?.split("\n").slice(0);

  return (
    <Accordion expanded={expanded} onChange={handleChange} sx={{ mb: "7px" }} >
      <AccordionSummary
        sx={{ mb: 0 }}
        expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
      >
        <Typography variant="h3" component={"h3"}>
          {props.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0, pb: "22px" }}>
        <Grid>
          <List>
            {bulletPoints?.map((point, index) => (
              <ListItem disablePadding alignItems="center" key={index}>
                <Typography
                  variant="h3"
                  fontWeight={"normal"}
                  lineHeight={"24px"}
                  gutterBottom
                >
                  {point}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
