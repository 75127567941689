import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  StepButton,
  Stepper,
  Toolbar,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Step from "@mui/material/Step";
import InformationView from "./InformationView";
import CoverView from "./CoverView";
import ReviewView from "./ReviewView";
import {
  resetState,
  sequenceFormStateSelector,
} from "store/slices/SequenceFormSlice";
import SelectPositionView from "./SelectPositionView";
import { useNavigate } from "react-router-dom";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import SequenceCancelDialog from "components/sequence/SequenceCancelDialog";
import SequenceSaveAndCloseDialog from "components/sequence/SequenceSaveAndCloseDialog";
import SequenceSaveDialog from "components/sequence/SequenceSaveDialog";
import SequenceSavedDialog from "components/sequence/SequenceSavedDialog";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { StdView } from "views/OtherViews/StdView";
import { useLocales } from "locales";

export default function NewSequenceView() {
  const { t } = useLocales();
  const [activeStep, setActiveStep] = useState(0);
  const [isSavedDialogOpen, setIsSavedDialogOpen] = useState(false);
  const activity = [t("createSequenceToolbarStep1"), t("createSequenceToolbarStep2"), t("createSequenceToolbarStep3"), t("createSequenceToolbarStep4")];
  const [isStep0Valid, setIsStep0Valid] = useState(false);
  const [isStep1Valid, setIsStep1Valid] = useState(false);
  const [isStep2Valid, setIsStep2Valid] = useState(false);
  const [isStep3Valid, setIsStep3Valid] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sequence, error, hasError } = useSelector(sequenceFormStateSelector);

  const handleNext = () => {
    if (activeStep < 4) setActiveStep((prevStep) => prevStep + 1);
  };
  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevStep) => prevStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleSaveOK = () => {
    setIsSavedDialogOpen(true);
    dispatch(resetState());
    navigate("/");
  };

  const handleStep0Validation = (isValid: boolean) => {
    setIsStep0Valid(isValid);
  };

  const handleStep1Validation = (isValid: boolean) => {
    setIsStep1Valid(isValid);
  };

  const handleStep2Validation = (isValid: boolean) => {
    setIsStep2Valid(isValid);
  };

  const isNextAvailable = () => {
    var isAvailable = false;

    if (activeStep === 0 && isStep0Valid) isAvailable = true;
    else if (activeStep === 1 && isStep1Valid) isAvailable = true;
    else if (activeStep === 2 && isStep2Valid) isAvailable = true;

    return isAvailable;
  };

  return (
    <>
      <SequenceSavedDialog isDialogOpen={isSavedDialogOpen} />

      <AppBar
        sx={{
          bgcolor: "white",
          boxShadow: "0px 2px 5px lightgrey",
          //alignItems: "center",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ "&.MuiContainer-root": { px: "0px" }, border: 0 }}
        >
          <Toolbar sx={{ "&.MuiToolbar-root": { px: "4px" } }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                border: 0,
                borderColor: "#000",
              }}
            >
              <Box
                sx={{
                  // width: "300px",
                  "& .MuiIconButton-root": {},
                }}
              >
                <Stack direction={"row"} spacing={0}>
                  <IconButton
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    sx={{
                      color: "#fff",
                      backgroundColor: (theme) => theme.palette.secondary.main,
                    }}
                  >
                    <NavigateBeforeOutlinedIcon />
                  </IconButton>
                  <SequenceCancelDialog />
                  {activeStep !== 3 && (
                    <SequenceSaveAndCloseDialog disabled={!isStep0Valid} />
                  )}
                </Stack>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Stepper
                  activeStep={activeStep}
                  sx={{
                    display: { xs: "none", md: "flex" },
                    justifyContent: "center",
                  }}
                >
                  {activity.map((step, index) => (
                    <Step
                      key={index}
                      sx={{
                        "& .MuiStepLabel-root .Mui-active": {
                          color: "#000000", // circle color (ACTIVE)
                        },
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "#000000", // circle color (COMPLETED)
                        },
                      }}
                    >
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {step}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <Stepper
                  activeStep={activeStep}
                  sx={{
                    display: { xs: "flex", md: "none" },
                    justifyContent: "center",
                  }}
                  alternativeLabel
                >
                  {activity.map((step, index) => (
                    <Step
                      key={index}
                      sx={{
                        "& .MuiStepLabel-root .Mui-active": {
                          color: "#000000", // circle color (ACTIVE)
                        },
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "#000000", // circle color (COMPLETED)
                        },
                        "& .MuiStepLabel-label": {
                          mt: "2px", // circle color (COMPLETED)
                          fontSize: "6pt",
                        },
                        "& .MuiStepIcon-root": {
                          height: "16px",
                          width: "16px",
                        },
                        "& .MuiStepConnector-line": {
                          marginTop: "-4px",
                        },
                      }}
                    >
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {step}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <Box
                sx={{
                  display: { xs: "flex" },
                  justifyContent: "flex-end",
                  width: "300px",
                }}
              >
                {activeStep !== 3 && (
                  <>
                    <Button
                      sx={{
                        display: { xs: "none", md: "flex" },
                        borderRadius: "46px",
                      }}
                      variant="contained"
                      color="secondary"
                      disabled={!isNextAvailable()}
                      onClick={handleNext}
                    >
                      {t("createSequenceToolbarContinue")}
                    </Button>
                    <IconButton
                      disabled={!isNextAvailable()}
                      onClick={handleNext}
                      sx={{
                        display: { xs: "flex", md: "none" },
                        color: "#fff",
                        backgroundColor: (theme) =>
                          theme.palette.secondary.main,
                      }}
                    >
                      <NavigateNextOutlinedIcon />
                    </IconButton>
                  </>
                )}

                {activeStep === 3 && (
                  <SequenceSaveDialog handleSaveOK={handleSaveOK} />
                )}
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <StdView title={""}>
        <Container
          maxWidth="lg"
          sx={{
            mt: 5,
            mb: "50px",
            border: 0,
          }}
        >
          {activeStep === 0 && (
            <SelectPositionView setIsValid={handleStep0Validation} />
          )}
          {activeStep === 1 && (
            <InformationView setIsValid={handleStep1Validation} />
          )}
          {activeStep === 2 && <CoverView setIsValid={handleStep2Validation} />}
          {activeStep === 3 && <ReviewView />}
        </Container>
      </StdView>
    </>
  );
}
