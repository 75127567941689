import { Navigate, useLocation } from 'react-router-dom'

export type PrivateRouteProps = {
  isAuthenticated: boolean
  authenticationPath: string
  outlet: JSX.Element
}

export default function PrivateRoute({
  isAuthenticated,
  authenticationPath,
  outlet,
}: PrivateRouteProps) {
  const location = useLocation()
  const uri = encodeURIComponent(`${location.pathname}${location.search}`)

  if (isAuthenticated) {
    return outlet
  } else {
    return (
      <Navigate
        to={{
          pathname: authenticationPath,
          search: `?redirect=${uri}`,
        }}
      />
    )
  }
}
