import axios from "axios";

import{v4 as uuidv4} from 'uuid'

let params = new URLSearchParams();
params.append("api-version", "3.0");
params.append("to", "sv");
params.append("to", "en");

const TranslateService = axios.create({
  baseURL: process.env.REACT_APP_TRANSLATOR_ENDPOINT,
  headers: {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TRANSLATOR_KEY,
    "Ocp-Apim-Subscription-Region": process.env.REACT_APP_TRANSLATOR_REGION,
    "Content-type": "application/json",
    "X-ClientTraceId": uuidv4().toString(),
  },
  params: params,
});

export default TranslateService;
