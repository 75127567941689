import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { UserDto } from "api";
import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { onDeleteUserImage } from "store/slices/userSlice";

export interface UserDeleteImageDialogProps {
  user: UserDto;
  disabled: boolean;
}

const UserDeleteImageDialog = ({
  user,
  disabled,
}: UserDeleteImageDialogProps) => {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (userId?: number) => {
    console.log(userId);
    await dispatch<any>(onDeleteUserImage(user.id!, t('savingSuccess')));
    handleClose();
  };

  return (
    <>
      <Tooltip title={t("delete")}>
        <span>
          <Button
            variant="contained"
            disabled={disabled}
            onClick={handleClickOpen}
            color="error"
            sx={{ width: "120px", mt: "20px" }}
          >
            {t("delete")}
          </Button>
        </span>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("confirmDeletePicture")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDelete(user?.id!)}
            autoFocus
          >
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserDeleteImageDialog;
