import * as React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useState } from "react";
import { PositionBaseDto, PositionDto } from "api";

import PositionAvatar from "components/avatars/PositionAvatar";
import DrawerContent from "components/drawers/DrawerContent";
import DrawerHeader from "components/drawers/DrawerHeader";
import PositionsInfoView from "views/CardViews/PositionsInfoView";
import { useDispatch } from "react-redux";
import { addSequenceItem } from "store/slices/SequenceFormSlice";
import { useLocales } from "locales";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "15px",
  backgroundColor: "#FFF",
  pb: "5px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

interface PostPositionsProps {
  title: string;
  children?: React.ReactNode;
  data?: PositionDto | null;
  refreshData?: boolean;
  addToSequence?: boolean;
}

export const PostPositionsAccordion = (
  props: PostPositionsProps
): JSX.Element => {
  const dispatch = useDispatch();
  const { t, currentLang } = useLocales();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectedPosition, setSelectedPosition] = useState<PositionDto>();

  const handleChange = () => {
    setExpanded(!expanded);
  };

  function handleClose(): void {
    setOpen(false);
  }

  const handleSelectPosition = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    position: PositionDto
  ) => {
    if (!position.private) {
      setSelectedPosition(position);
      setOpen(true);
    } else e.preventDefault();
  };

  function getPositionText(position: PositionBaseDto): string {
    var text = currentLang.value === "sv" ? position.swedish! : position.english!;
    // text +=  position.private ? " - " + position.sanskrit : "";
    return text;
  }

  const handleAddToSequence = () => {
    console.log("Add to sequence");
    dispatch(addSequenceItem(selectedPosition!));
    setOpen(false);
  };

  return (
    <>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}>
          <Typography variant="h6" component={"h3"}>
            {props.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <List dense>
            {props.data?.postPose1 && (
              <ListItem
                onClick={(e) => handleSelectPosition(e, props.data?.postPose1!)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  <PositionAvatar
                    images={props.data?.postPose1?.positionImages!}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={getPositionText(props.data?.postPose1!)}
                />
              </ListItem>
            )}
            {props.data?.postPose2 && (
              <ListItem
                onClick={(e) => handleSelectPosition(e, props.data?.postPose2!)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  <PositionAvatar
                    images={props.data?.postPose2?.positionImages!}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={getPositionText(props.data?.postPose2!)}
                />
              </ListItem>
            )}
            {props.data?.postPose3 && (
              <ListItem
                onClick={(e) => handleSelectPosition(e, props.data?.postPose3!)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  <PositionAvatar
                    images={props.data?.postPose3?.positionImages!}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={getPositionText(props.data?.postPose3!)}
                />
              </ListItem>
            )}
            {!props.data?.postPose1 &&
              !props.data?.postPose2 &&
              !props.data?.postPose3 && (
                <ListItem>
                  <ListItemText
                    primary={t("noPostPositions")}
                  />
                </ListItem>
              )}
          </List>
        </AccordionDetails>
      </Accordion>
      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#FFF",
          },
        }}
      >
        {props.addToSequence === undefined && (
          <DrawerHeader handleClose={handleClose} />
        )}
        {props.addToSequence !== undefined && props.addToSequence && (
          <DrawerHeader
            handleClose={handleClose}
            toolbar={
              <Button
                onClick={handleAddToSequence}
                variant="contained"
                color="secondary"
              >
                {t("addToSequence")}
              </Button>
            }
          />
        )}
        <DrawerContent>
          <PositionsInfoView
            position={selectedPosition}
            refreshData={props.refreshData}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
};
