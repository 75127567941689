import React from "react";
import Paper from "@mui/material/Paper";

interface LogInPaperProps {
  children?: React.ReactNode | string;
}

export const LogInPaper = (props: LogInPaperProps): JSX.Element => {
  return (
    <Paper
      elevation={0}
      square
      sx={{
        py: "1rem",
        px: "1rem",
        borderRadius: "15px",
        //borderTop: "5px solid #43594C",
        background: "#FFFFFF",
        //boxShadow: "0px 1px 24px #0000000F",
      }}
    >
      {props.children}
    </Paper>
  );
};
