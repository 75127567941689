import React from 'react'
import Paper from '@mui/material/Paper'
import { Avatar } from '@mui/material'

interface WarningPaperProps {
  children?: React.ReactNode | string
}

export const WarningPaper = (props: WarningPaperProps): JSX.Element => {
  return (
    <Paper
      square
      sx={{
        position: 'relative',
        p: '1.5rem',
        borderTop: '5px solid #FC5A03',
        background: '#FFDECC',
      }}
    >
      <Avatar
        sx={{
          position: 'absolute',
          top: -14,
          width: 24,
          height: 24,
          backgroundColor: '#FC5A03',
          fontWeight: 'bolder',
          fontSize: 'large',
          fontFamily: 'monospace',
        }}
      >
        !
      </Avatar>
      {props.children}
    </Paper>
  )
}
