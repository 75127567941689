import { useTheme } from "@emotion/react";
import { Avatar } from "@mui/material";
import { PositionImageDto } from "api";
import React, { useEffect, useState } from "react";

interface PositionAvatarProps {
  images: PositionImageDto[] | null;
  altText?: string;
  size?: number;
}

const PositionAvatar = ({
  images,
  altText,
  size = 32,
}: PositionAvatarProps) => {
  const theme = useTheme();
  const startPath = "..\\PositionImages\\";
  const [imgPath, setImgPath] = useState("/images/yoga.svg");

  useEffect(() => {
    if (images && images.length >= 1) {
      setImgPath(startPath + images[0].path);
    }
  }, [images]);

  return (
    <Avatar
      alt={altText}
      src={imgPath}
      sx={{
        width: size,
        height: size,
        border: 1,
        borderColor: (theme) => theme.palette.grey[400],
      }}
    />
  );
};

export default PositionAvatar;
