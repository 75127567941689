import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface CommonState {
  lastError: string | null;
  error: string | null;
  loading: boolean;
  isAppDrawerOpen: boolean;
  updatingContent: boolean;
  message: string | null;
}

const initialState: CommonState = {
  lastError: "",
  error: "",
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: "",
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    fetchStart: (state: CommonState) => {
      state.error = "";
      state.message = "";
      state.loading = true;
    },
    fetchSuccess: (state: CommonState) => {
      state.lastError = "";
      state.error = "";
      state.message = "";
      state.loading = false;
      state.updatingContent = false;
    },
    fetchError: (state: CommonState, action: PayloadAction<string | null>) => {
      state.lastError = action.payload;
      state.loading = false;
      state.error = action.payload;
      state.message = "";
      state.updatingContent = false;
    },
    showMessage: (state: CommonState, action: PayloadAction<string | null>) => {
      state.error = "";
      state.message = action.payload;
      state.loading = false;
      state.updatingContent = false;
    },
    hideMessage: (state: CommonState) => {
      state.loading = false;
      state.error = "";
      state.message = "";
      state.updatingContent = false;
    },
    onToggleAppDrawer: (state) => {
      state.isAppDrawerOpen = !state.isAppDrawerOpen;
    },
  },
});

export const { fetchStart, fetchSuccess, fetchError, showMessage, hideMessage, onToggleAppDrawer} = commonSlice.actions;

export default commonSlice.reducer;

export const commonStateSelector = (
  state: RootState,
): CommonState => state.common
