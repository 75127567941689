import {
  Avatar,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { SequenceDto, SequenceItemType } from "api";
import React from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PauseIcon from "@mui/icons-material/Pause";
import { useLocales } from "locales";

export interface SequencesInfoViewProps {
  sequence?: SequenceDto | null;
}

export const SequencePrintView = React.forwardRef<
  HTMLInputElement,
  SequencesInfoViewProps
>((props, ref) => {
  const date = new Date();
  const {t, currentLang} = useLocales();
  return (
    <div ref={ref}>
      <table className="report-container">
        <thead className="report-header">
          <tr>
            <th className="report-header-cell">
              <div className="header-info">
                <Grid container>
                  <Grid item xs={3}>
                    <img src="/images/symbol_svart.png" width={80} alt="logo" />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "end",
                    }}
                  >
                    <Typography fontSize={14} fontWeight={600} gutterBottom>
                      {props.sequence?.title!}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "end",
                    }}
                  >
                    {}
                  </Grid>
                </Grid>
              </div>
            </th>
          </tr>
        </thead>

        <tbody className="report-content">
          <tr>
            <td className="report-content-cell">
              <div className="main">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    {props.sequence &&
                      props.sequence?.sequenceItems!.map((item, index) => (
                        <TableRow
                          key={"pr-" + index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          {item?.type === SequenceItemType._1 && (
                            <>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: "50px" }}
                              >
                                <Avatar
                                  variant="rounded"
                                  sx={{ width: 56, height: 56,
                                    transform: `scaleX(${item.imageFlip? item.imageFlip : 1})`
                                   }}
                                
                                  alt={item.position?.sanskrit!}
                                  src={
                                    "..\\PositionImages\\" +
                                    item.position?.positionImages![0].path
                                  }
                                />
                              </TableCell>
                              <TableCell align="left">
                                <Typography variant="h1">
                                  {item.position?.sanskrit}
                                </Typography>
                                <Typography>
                                  {currentLang.value === 'sv' ?  item.position?.swedish : item.position?.english}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                {item.information}
                              </TableCell>
                            </>
                          )}
                          {item?.type === SequenceItemType._2 && (
                            <>
                              <TableCell component="th" scope="row">
                                <Avatar>
                                  <AssignmentIcon />
                                </Avatar>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  variant="body2"
                                  sx={{
                                    wordWrap: "break-word",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {item.information ? item.information : ""}
                                </Typography>
                              </TableCell>
                              <TableCell align="left"></TableCell>
                            </>
                          )}
                          {item?.type === SequenceItemType._3 && (
                            <>
                              <TableCell component="th" scope="row">
                                <Avatar>
                                  <PauseIcon />
                                </Avatar>
                              </TableCell>
                              <TableCell align="left">
                                {item.information}
                              </TableCell>

                              <TableCell align="left"></TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <Typography variant="body1" gutterBottom>
                  {props.sequence?.script}
                </Typography>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});
