import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import {
  Alert,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { getPositionsByFilter } from "store/selectors/position";
import { resetFilter, setSearchString } from "store/slices/positionFilterSlice";
import { loadPositionsAsync } from "store/slices/positionSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PositionFilterIcon from "components/drawers/PositionFilterDrawer";
import SearchIcon from "@mui/icons-material/Search";
import { DropResult } from "react-beautiful-dnd";
import {
  flipSequenceItem,
  sequenceFormStateSelector,
  setSequenceItemList,
  setTitle,
} from "store/slices/SequenceFormSlice";
import AppTextField from "components/forms/AppTextField";
import * as yup from "yup";
import { Form, Formik } from "formik";
import SequenceDraggableList from "components/sequence/SequenceDraggableList";
import { reorder } from "utils/model";
import PositionListItem from "components/position/PositionListItem";
import SequencePauseListItem from "components/sequence/SequencePauseListItem";
import SequenceNoteListItem from "components/sequence/SequenceNoteListItem";
import Iconify from "components/iconify";
import SelfImprovementOutlinedIcon from "@mui/icons-material/SelfImprovementOutlined";
import MySequenceButton from "components/sequence/MySequenceButton";
import { useLocales } from "locales";

export interface SelectPositionViewProps {
  setIsValid: (isValid: boolean) => void;
}

export default function SelectPositionView({
  setIsValid,
}: SelectPositionViewProps) {
  const dispatch = useDispatch();
  const { t } = useLocales();

  const [searchValue, setSearchValue] = useState("");

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const positionList = useSelector(getPositionsByFilter);

  const { sequence } = useSelector(sequenceFormStateSelector);

  useEffect(() => {
    dispatch(resetFilter());
  }, [dispatch]);

  useEffect(() => {
    dispatch<any>(loadPositionsAsync());
  }, [dispatch]);

  useEffect(() => {
    setIsValid(
      sequence?.title != "" &&
        sequence?.title?.length! > 1 &&
        sequence?.sequenceItems!.length! > 0
    );
  }, [sequence]);

  const onSearchEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    dispatch(setSearchString(e.target.value));
  };

  const onClearSearchEvent = () => {
    setSearchValue("");
    dispatch(setSearchString(""));
  };

  const handleTabChange = (e: React.SyntheticEvent, tabIndex: number) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };

  const validateForm = (title: string) => {
    setIsValid(
      title != "" && title.length > 1 && sequence?.sequenceItems!.length! > 0
    );
  };

  const validationSchema = yup.object({
    title: yup
      .string()
      .min(2, t("createSequenceValidationTitle"))
      .max(50, t("createSequenceValidationTitle2"))
      .required(t("createSequenceValidationTitleRequired")),
  });

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("TitleChange", e.target.value);
    sequence && dispatch(setTitle(e.target.value));
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(
      sequence!.sequenceItems!,
      source.index,
      destination.index
    );

    dispatch(setSequenceItemList(newItems));
  };

  function onToogleFlip(id: number): void {
    dispatch(flipSequenceItem(id));
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{ title: sequence?.title }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          try {
            console.log("Save");
          } catch (error) {
            console.log(error);
          }

          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
        }) => (
          <Form noValidate autoComplete="off">
            <Stack spacing={2}>
              <Typography variant="h2" sx={{ pt: "20px" }}>
                {t("createSequenceTitle")}
              </Typography>
              <AppTextField
                color="primary"
                required
                name="title"
                value={values.title ? values.title : ""}
                title=""
                maxLength={50}
                inputProps={{ maxLength: 50 }}
                subTitle=""
                onChange={(e: any) => {
                  handleChange(e);
                  validateForm(e.target.value);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleTitleChange(e);
                }}
              />
              <Typography variant="h2" sx={{ pt: "10px" }}>
                {t("createSequenceSelectContent")}
              </Typography>
              <Alert
                severity="info"
                variant="filled"
                sx={{ color: "#fff", background: "#43594C" }}
              >
                {t("createSequenceSelectInformation")}
              </Alert>
              <Box>
                <Grid container columnSpacing={"20px"}>
                  <Grid item xs={12} md={6}>
                    <Stack direction={"row"} sx={{ alignItems: "center" }}>
                      <Tabs
                        value={currentTabIndex}
                        onChange={handleTabChange}
                        textColor="primary"
                        indicatorColor="primary"
                        sx={{
                          "& .MuiTab-root": {
                            textTransform: "none",
                          },
                        }}
                      >
                        <Tab
                          icon={<SelfImprovementOutlinedIcon />}
                          iconPosition="start"
                          label={t("createSequenceTabPositions")}
                        />
                        <Tab
                          icon={
                            <Iconify
                              icon="material-symbols:other-admission"
                              width={24}
                            />
                          }
                          iconPosition="start"
                          label={t("createSequenceTabOther")}
                        />
                      </Tabs>
                      <Box
                        sx={{
                          flexGrow: 1,
                          justifyContent: "flex-end",
                          display: { xs: "flex", sm: "none" },
                        }}
                      >
                        <MySequenceButton
                          item={sequence!}
                          onDragEnd={onDragEnd}
                          onToogleFlip={onToogleFlip}
                        />
                      </Box>
                    </Stack>
                    <Box sx={{ pt: "10px" }}>
                      {currentTabIndex === 0 && (
                        <Stack spacing={1}>
                          <Stack direction={"row"} spacing={1}>
                            <OutlinedInput
                              value={searchValue}
                              sx={{
                                width: "100%",
                                borderRadius: "50px",
                                height: "46px",
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => onClearSearchEvent()}
                                  >
                                    <Iconify
                                      icon="basil:cross-outline"
                                      width={24}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              }
                              placeholder={t("positionSearchPlaceholder")}
                              onChange={onSearchEvent}
                            />
                            <PositionFilterIcon />
                          </Stack>

                          <Divider sx={{ color: "black" }} />
                          <List sx={{ "& .MuiListItem-root": { mb: "5px" } }}>
                            {positionList &&
                              positionList.map((position, index) => (
                                <PositionListItem item={position} key={index} />
                              ))}
                          </List>
                        </Stack>
                      )}
                      {currentTabIndex === 1 && (
                        <List sx={{ "& .MuiListItem-root": { mb: "5px" } }}>
                          <SequencePauseListItem />
                          <SequenceNoteListItem />
                        </List>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                      <Stack spacing={1}>
                        <Typography
                          variant="h1"
                          sx={{ mt: { xs: "0px", md: "76px" } }}
                        >
                          {t("createSequenceMyTitle")}
                        </Typography>
                        <Divider sx={{ color: "black", mb: "2px" }} />
                        <SequenceDraggableList
                          items={sequence?.sequenceItems!}
                          onDragEnd={onDragEnd}
                          onToogleFlip={onToogleFlip}
                        />
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
}
