import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store";
import ContentfulService from "utils/ContentfulService";
import {
  IFaqList,
  IInfoText,
  IInspirationList,
  INSPIRATION_LIST_ID,
  IVersionList,
  THANKS_FOR_SUBSCRIPTION,
} from "utils/contentfulModel";

export interface ContentfulState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  faqList: IFaqList | null;
  versionList: IVersionList | null;
  thanksForSubscriptionText: IInfoText | null;
  inspirationList: IInspirationList | null;
}

const initialState: ContentfulState = {
  loading: false,
  hasError: false,
  error: "",
  faqList: null,
  versionList: null,
  thanksForSubscriptionText: null,
  inspirationList: null,
};

export const contentfulSlice = createSlice({
  name: "contentful",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.loading = false;
      state.error = `${action.payload}`;
    },
    setFaqList: (state, action: PayloadAction<IFaqList>) => {
      state.faqList = action.payload;
    },
    setVersionList: (state, action: PayloadAction<IVersionList>) => {
      state.versionList = action.payload;
    },
    setPhilosophyList: (state, action: PayloadAction<IVersionList>) => {
      state.versionList = action.payload;
    },
    setInspirationList: (state, action: PayloadAction<IInspirationList>) => {
      state.inspirationList = action.payload;
    },
    resetState: (state) => {
      state.loading = false;
      state.hasError = false;
      state.error = "";
      state.faqList = null;
    },
    setThanksForSubscriptionText: (state, action: PayloadAction<IInfoText>) => {
      state.thanksForSubscriptionText = action.payload;
    },
  },
});

export const {
  loading,
  hasError,
  resetState,
  setFaqList,
  setThanksForSubscriptionText,
  setVersionList,
  setInspirationList,
} = contentfulSlice.actions;

export const loadFaqList =
  (locale: string): AppThunk =>
  (dispatch, getState) => {
    dispatch(loading(true));
    ContentfulService.post("/", {
      query: `query{faqList(id:\"3hYjkiV63BaqV8palfagvn\" locale:\"${locale}\"){listCollection{items{question answer{json}}}}}`,
    })
      .then((data) => {
        dispatch(loading(false));
        dispatch(setFaqList(data.data.data.faqList as IFaqList));
      })
      .catch((e) => {
        dispatch(hasError((e as Error).message));
      });
  };

export const loadVersionList =
  (locale: string): AppThunk =>
  (dispatch, getState) => {
    dispatch(loading(true));
    ContentfulService.post("/", {
      query: `query{versionList(id:\"4oN0g3bbnaffWFGvVOQoja\" locale:\"${locale}\"){listCollection{items{title description{json}}}}}`,
    })
      .then((data) => {
        dispatch(loading(false));
        dispatch(setVersionList(data.data.data.versionList as IVersionList));
      })
      .catch((e) => {
        dispatch(hasError((e as Error).message));
      });
  };

export const getThanksForSubscriptionText =
  (locale: string): AppThunk =>
  (dispatch, getState) => {
    ContentfulService.post("/", {
      query: `query{infoText(id:\"${THANKS_FOR_SUBSCRIPTION}\" locale:\"${locale}\"){title text {json}}}`,
    })
      .then((data) => {
        dispatch(setThanksForSubscriptionText(data.data.data.infoText));
        return data.data;
      })
      .catch((e) => {
        dispatch(hasError((e as Error).message));
      });
  };

export const getInspirationList =
  (locale: string): AppThunk =>
  (dispatch, getState) => {
    ContentfulService.post("/", {
      query: `query{inspirationList(id:"${INSPIRATION_LIST_ID}" locale:\"${locale}\"){itemsCollection{items{title subTitle sys{id}}}}}`,
    })
      .then((data) => {
        dispatch(
          setInspirationList(data.data.data.inspirationList)
        );
      })
      .catch((e) => {
        dispatch(hasError((e as Error).message));
      });
  };

export default contentfulSlice.reducer;

export const contentfulStateSelector = (state: RootState): ContentfulState =>
  state.contentful;
