import React from "react";

import { useSelector } from "react-redux";
import { commonStateSelector } from "store/slices/commonSlice";
import AppMessageView from "./AppMessageView";

const AppInfoView = () => {

  const error = useSelector(commonStateSelector).error
  const loading = useSelector(commonStateSelector).loading
  const message = useSelector(commonStateSelector).message


  const showMessage = () => {
    return <AppMessageView variant="success" message={message!.toString()} />;
  };

  const showError = () => {
    return <AppMessageView variant="error" message={error!.toString()} />;
  };

  return (
    <>
      {message && showMessage()}
      {error && showError()}
    </>
  );
};

export default AppInfoView;
