import { Outlet } from "react-router-dom";
import { Container } from "@mui/system";

export const FullScreenLayout = () => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        minHeight: "100vh",
      }}
    >
      <Outlet />
    </Container>
  );
};
