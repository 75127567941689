/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserDto } from '../models/UserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @returns UserDto Success
     * @throws ApiError
     */
    public static getApiUsers(): CancelablePromise<Array<UserDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users',
        });
    }

    /**
     * @param userId 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static getApiUsers1(
userId: number,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param currentEmail 
     * @param newEmail 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static putApiUsersUpdateEmail(
currentEmail: string,
newEmail: string,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/updateEmail/{currentEmail}/{newEmail}',
            path: {
                'currentEmail': currentEmail,
                'newEmail': newEmail,
            },
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param currentPassword 
     * @param newPassword 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static putApiUsersUpdatePassword(
currentPassword: string,
newPassword: string,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/updatePassword/{currentPassword}/{newPassword}',
            path: {
                'currentPassword': currentPassword,
                'newPassword': newPassword,
            },
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static putApiUsersUpdateUser(
requestBody?: UserDto,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/updateUser',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns UserDto Success
     * @throws ApiError
     */
    public static putApiUsersCancelSubscription(): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/cancelSubscription',
        });
    }

    /**
     * @returns UserDto Success
     * @throws ApiError
     */
    public static putApiUsersResumeSubscription(): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/resumeSubscription',
        });
    }

    /**
     * @param userId 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static postApiUsersHideVersionBanner(
userId?: number,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/hideVersionBanner',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * @param id 
     * @param formData 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static putApiUsersImage(
id: number,
formData?: {
file?: Blob;
},
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/{id}/image',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static deleteApiUsersImage(
id: number,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/users/{id}/image',
            path: {
                'id': id,
            },
        });
    }

}
