import { Document } from "@contentful/rich-text-types";

export interface IFaq {
  question: string;
  answer: {
    json: Document;
  };
}

export interface IFaqList {
  listCollection: {
    items: IFaq[];
  };
}

export interface IVersion {
  title: string;
  description: {
    json: Document;
  };
}

export interface IVersionList {
  listCollection: {
    items: IVersion[];
  };
}

export type IInfoText = {
  title: string;
  text: {
    json: Document;
  };
};

export interface IInspirationList {
  itemsCollection: {
    items: IInspiration[];
  };
}

export type IInspiration = {
  title: string;
  subTitle: string;
  introduction: {
    json: Document;
    links: ILinks;
  };
  sectionsCollection: { items: [{ sys: { id: string } }] };
  sys: { id: string };
};

export type IInspirationItem = {
  title: string;
  text: {
    json: Document;
    links: ILinks;
  };
  sys: { id: string };
};

export type ILinks = {
  assets: {
    block: [
      {
        sys: {
          id: string;
        };
        url: string;
        width: number;
        height: number;
      }
    ];
  };
};

export const WELECOME_TEXT_ID = "7sEIYfTlyUGqjVXE3t9q6y";
export const THANKS_FOR_SUBSCRIPTION = "4ZApMAUSRMXAHpA2A6ixgZ";
export const INSPIRATION_LIST_ID = "69YTnnw7RPCnLqnDAujDUG";
