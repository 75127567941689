import { Box } from "@mui/system";
import { SequenceCommentDto } from "api";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import SequenceCommentItem from "./SequenceCommentItem";
import { getDateFromString } from "utils/dateUtils";
import Grid from "@mui/material/Grid2";
import TranslateService from "utils/TranslateService";
import { TranslateDto, TranslationsRequestTextDto } from "locales/mstranslate";
import { useLocales } from "locales";

export type SequenceCommentDto2 = SequenceCommentDto & {
  translatedText: string;
};

type SequenceCommentListProps = {
  sequenceId: number;
  comments: SequenceCommentDto2[];
  translate: boolean;
};

const SequenceCommentList = ({
  sequenceId,
  comments,
  translate,
}: SequenceCommentListProps) => {
  const { t, currentLang } = useLocales();

  const [sequenceComments, setSequenceComments] = useState<
    SequenceCommentDto[]
  >([]);

  useEffect(() => {
    if (translate) handleTranslate();
    else setSequenceComments(comments);
  }, [translate, comments]);

  async function handleTranslate(): Promise<void> {
    let itemsTextData: TranslationsRequestTextDto[] = [];

    for (var i = 0; i < comments.length; i++) {
      if (comments[i].parentId !== null) continue;
      itemsTextData.push({ text: comments[i].message! });

      if (comments[i].replyComments === null) continue;

      for (var j = 0; j < comments[i].replyComments!.length; j++) {
        itemsTextData.push({ text: comments[i].replyComments![j].message! });
      }
    }

    console.log(itemsTextData);

    await TranslateService.post("/translate", itemsTextData).then(
      (response) => {
        const translations = response.data as TranslateDto[];

        let translatedComments: SequenceCommentDto[] = [];
        let translationCount = 0;

        for (var i = 0; i < comments.length; i++) {
          if (comments[i].parentId !== null) continue;
          let translatedComment = { ...comments[i] };
          const ts = translations[i].translations!.filter(
            (t) => t.to === currentLang.value
          );
          translatedComment.message = ts[0].text!;

          if (comments[i].replyComments !== null) {
            let translatedReplyComments: SequenceCommentDto[] = [];
            for (var j = 0; j < comments[i].replyComments!.length; j++) {
              translationCount = translationCount+1;
              let translatedReplyComment = { ...comments[i].replyComments![j] };
              const ts = translations[translationCount].translations!.filter(
                (t) => t.to === currentLang.value
              );
              translatedReplyComment.message = ts[0].text!;

              translatedReplyComments?.push(translatedReplyComment);
            }

            translatedComment.replyComments = translatedReplyComments;
          }

          translatedComments.push(translatedComment);
          translationCount = translationCount+1;
        }

        console.log(translatedComments)

        setSequenceComments(translatedComments);
      }
    );
  }

  const sortedComments = _.sortBy(sequenceComments, "postedAt");

  return (
    <Grid container>
      {sortedComments.map((comment) => {
        const { id, replyComments, user, message, postedAt, parentId, userId } =
          comment;

        if (parentId !== null) return;

        const hasReply = replyComments != null;

        const sortedReplyComments = _.sortBy(replyComments, "postedAt");

        return (
          <Grid size={12} key={id}>
            <SequenceCommentItem
              id={id!}
              userId={userId!}
              sequenceId={sequenceId}
              name={user?.firstName!}
              message={message!}
              postedAt={getDateFromString(postedAt!)!}
              avatarUrl={user?.imageUri!}
            />
            {hasReply &&
              sortedReplyComments!.map((reply) => {
                return (
                  <SequenceCommentItem
                    id={reply.id!}
                    userId={reply.userId!}
                    sequenceId={sequenceId}
                    key={reply.id}
                    name={reply.user?.firstName || ""}
                    message={reply.message!}
                    postedAt={getDateFromString(reply.postedAt!)!}
                    avatarUrl={reply.user?.imageUri || ""}
                    hasReply
                  />
                );
              })}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SequenceCommentList;
