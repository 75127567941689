import {
  Grid,
} from "@mui/material";
import SubscriptionCard from "components/account/SubscriptionCard";
import SubscriptionHistory from "components/account/SubscriptionHistory";
import { SoulwisePaper } from "components/papers/SoulwisePaper";
import React from "react";

const SubscriptionForm = () => {
  return (
    <SoulwisePaper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <SubscriptionCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <SubscriptionHistory />
        </Grid>
      </Grid>
    </SoulwisePaper>
  );
};

export default SubscriptionForm;
