import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  setLevel,
  setMusicLink,
  setOtherInformation,
  setScript,
  setYogaForm,
  setTheme,
  sequenceFormStateSelector,
  setEstimatedDuration,
} from "store/slices/SequenceFormSlice";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { getLevelTitle, levelIds, yogaFormIds } from "utils/model";
import { useLocales } from "locales";
import _ from "lodash";

type yogaFormItem = {
  id: number;
  label: string;
};

export interface InformationViewProps {
  setIsValid: (isValid: boolean) => void;
}

export default function InformationView({ setIsValid }: InformationViewProps) {
  const dispatch = useDispatch();
  const { t } = useLocales();
  const { sequence } = useSelector(sequenceFormStateSelector);

  const [yogaFormList, setYogaFormList] = useState<yogaFormItem[]>([]);

  const validationSchema = yup.object({
    yogaForm: yup.number().min(1, t("yogaFormMandatory")),
    level: yup.number().min(1, t("levelMandatory")),
    theme: yup.string().max(100, t("themeMax100")).nullable(),
    script: yup.string().max(500, t("scriptMax500")).nullable(),
    music: yup.string().max(100, t("musicMax100")).nullable(),
    other: yup.string().max(500, t("otherMax500")).nullable(),
    estimatedDuration: yup.number().min(1, t("estimatedDurationMin")).max(480, t("estimatedDurationMax")).nullable(),
  });

  useEffect(() => {
    const items = yogaFormIds.map((item) => {
      return { id: item, label: t(`yogaForm${item}`) } as yogaFormItem;
    });
    setYogaFormList(items);
  }, [t]);

  useEffect(() => {
    setIsValid(
      sequence?.yogaForm !== undefined &&
        sequence?.yogaForm!.toString() !== "0" &&
        sequence?.level !== undefined &&
        sequence?.level!.toString() !== "0"
    );
  }, [sequence]);

  const formik = useFormik({
    initialValues: {
      yogaForm: sequence!.yogaForm,
      level: sequence!.level,
      theme: sequence!.theme,
      script: sequence!.script,
      music: sequence!.musicLink,
      other: sequence!.otherInformation,
      estimatedDuration: sequence!.estimatedDuration,
    },
    onSubmit: (values) => {
      console.log(JSON.stringify(values));
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
  });

  const newYogaForm = (yogaForm: number) => {
    dispatch(setYogaForm(yogaForm));
  };

  const newLevel = (level: number) => {
    dispatch(setLevel(level));
  };

  const newTheme = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTheme(e.target.value));
  };

  const newScript = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setScript(e.target.value));
  };

  const newMusicLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMusicLink(e.target.value));
  };

  const newOtherInformation = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setOtherInformation(e.target.value));
  };

  const newEstimatedDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEstimatedDuration(parseInt(e.target.value)));
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      sx={{ width: "100%" }}
    >
      <Typography variant="h1" sx={{ mb: "30px" }}>
        {t("createSequenceInformationTitle")}
      </Typography>
      <Grid container sx={{ mb: "30px" }} spacing={1}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold" }}>
            {t("createSequenceInformationYogaformTitle")} *
          </Typography>
          <FormControl sx={{ width: "100%" }}>
            <Select
              required
              placeholder={t("createSequenceInformationSelectYogaform")}
              id="yogaForm"
              name="yogaForm"
              value={
                formik.values.yogaForm
                  ? formik.values.yogaForm?.toString()
                  : "0"
              }
              onChange={(e: SelectChangeEvent) => {
                formik.handleChange(e);
                newYogaForm(parseInt(e.target.value));
              }}
              error={formik.touched.yogaForm && Boolean(formik.errors.yogaForm)}
              onBlur={formik.handleBlur}
            >
              <MenuItem value={0}>
                {t("createSequenceInformationSelectYogaform")}
              </MenuItem>
              {_.sortBy(yogaFormList, "label").map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
              {formik.touched.yogaForm && formik.errors.yogaForm}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold" }}>
            {t("createSequenceInformationLevelTitle")} *
          </Typography>
          <FormControl sx={{ width: "100%" }}>
            <Select
              required
              placeholder={t("createSequenceInformationSelectLevel")}
              id="level"
              name="level"
              value={
                formik.values.level !== undefined
                  ? formik.values.level?.toString()
                  : "0"
              }
              onChange={(e: SelectChangeEvent) => {
                formik.handleChange(e);
                newLevel(parseInt(e.target.value));
              }}
              error={formik.touched.level && Boolean(formik.errors.level)}
              onBlur={formik.handleBlur}
            >
              <MenuItem key={"n-0"} value={0}>
                {t("createSequenceInformationSelectLevel")}
              </MenuItem>
              {levelIds.map((value, index) => (
                <MenuItem value={value} key={"n-" + index + 1}>
                  {t(getLevelTitle(value))}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
              {formik.touched.level && formik.errors.level}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography
              sx={{ fontWeight: "bold", display: "inline" }}
              onChange={newEstimatedDuration}
            >
              {t("estimatedDurationTitle")}
            </Typography>
            <Typography sx={{ display: "inline" }}>
              {" "}
              ({t("minutes")})
            </Typography>
            <Typography sx={{ display: "inline" }}>
              {" "}
              ({t("commonOptionally")})
            </Typography>
          </Box>
          <TextField
            id="estimatedDuration"
            name="estimatedDuration"
            variant="outlined"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 240 } }}
            fullWidth
            value={formik.values.estimatedDuration}
            onChange={formik.handleChange}
            onInput={newEstimatedDuration}
            error={
              formik.touched.estimatedDuration &&
              Boolean(formik.errors.estimatedDuration)
            }
            helperText={
              formik.touched.estimatedDuration &&
              formik.errors.estimatedDuration
            }
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold", display: "inline" }}>
            {t("createSequenceInformationThemeTitle")}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            {" "}
            ({t("createSequenceInformationMax100")})
          </Typography>
          <Typography sx={{ display: "inline" }}>
            {" "}
            ({t("commonOptionally")})
          </Typography>
          <TextField
            id="theme"
            name="theme"
            placeholder={t("freeText")}
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 100 }}
            value={formik.values.theme}
            onChange={formik.handleChange}
            onInput={newTheme}
            error={formik.touched.theme && Boolean(formik.errors.theme)}
            helperText={formik.touched.theme && formik.errors.theme}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography
              sx={{ fontWeight: "bold", display: "inline" }}
              onChange={newScript}
            >
              {t("createSequenceInformationScriptTitle")}
            </Typography>
            <Typography sx={{ display: "inline" }}>
              {" "}
              ({t("createSequenceInformationMax500")})
            </Typography>
            <Typography sx={{ display: "inline" }}>
              {" "}
              ({t("commonOptionally")})
            </Typography>
          </Box>
          <TextField
            id="script"
            name="script"
            placeholder={t("freeText")}
            variant="outlined"
            inputProps={{ maxLength: 500 }}
            multiline
            rows={3}
            fullWidth
            value={formik.values.script}
            onChange={formik.handleChange}
            onInput={newScript}
            error={formik.touched.script && Boolean(formik.errors.script)}
            helperText={formik.touched.script && formik.errors.script}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography sx={{ fontWeight: "bold", display: "inline" }}>
              {t("createSequenceInformationMusicTitle")}
            </Typography>
            <Typography sx={{ display: "inline" }}>
              {" "}
              ({t("createSequenceInformationMax100")})
            </Typography>
            <Typography sx={{ display: "inline" }}>
              {" "}
              ({t("commonOptionally")})
            </Typography>
          </Box>

          <TextField
            id="music"
            name="music"
            inputProps={{ maxLength: 100 }}
            placeholder={t("createSequenceInformationMusicPlacholder")}
            variant="outlined"
            fullWidth
            value={formik.values.music}
            onChange={formik.handleChange}
            onInput={newMusicLink}
            error={formik.touched.music && Boolean(formik.errors.music)}
            helperText={formik.touched.music && formik.errors.music}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography sx={{ fontWeight: "bold", display: "inline" }}>
              {t("createSequenceInformationOtherTitle")}
            </Typography>
            <Typography sx={{ display: "inline" }}>
              {" "}
              ({t("createSequenceInformationMax500")})
            </Typography>
            <Typography sx={{ display: "inline" }}>
              {" "}
              ({t("commonOptionally")})
            </Typography>
          </Box>
          <TextField
            id="other"
            name="other"
            placeholder={t("freeText")}
            variant="outlined"
            multiline
            fullWidth
            inputProps={{ maxLength: 500 }}
            rows={3}
            value={formik.values.other}
            onChange={formik.handleChange}
            onInput={newOtherInformation}
            error={formik.touched.other && Boolean(formik.errors.other)}
            helperText={formik.touched.other && formik.errors.other}
            onBlur={formik.handleBlur}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
