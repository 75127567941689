import { Logout } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authStateSelector, logout } from "store/slices/authSlice";
import AddIcon from "@mui/icons-material/Add";
import { setNewSequence } from "store/slices/SequenceFormSlice";
import PersonIcon from "@mui/icons-material/Person";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HelpIcon from "@mui/icons-material/Help";
import DescriptionIcon from "@mui/icons-material/Description";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { useLocales } from "locales";

const ProfileMenu = () => {
  const { currentUser } = useSelector(authStateSelector);
  const { t } = useLocales();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    dispatch<any>(logout());
  };

  return (
    <>
      <React.Fragment>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Button
            onClick={() => dispatch(setNewSequence())}
            component={Link}
            to={"/newSequence"}
            sx={{
        
              justifyContent: "center",
              display: { xs: "none", md: "flex" },
            }}
            variant="contained"
            color="secondary"
            endIcon={<AddIcon />}
          >
            {t("newSequence")}
          </Button>
          <IconButton
            onClick={() => dispatch(setNewSequence())}
            component={Link}
            to={"/newSequence"}
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <AddCircleIcon color="secondary" fontSize="large" sx={{}} />
          </IconButton>
          <Tooltip
            title={
              currentUser
                ? currentUser.firstName + " " + currentUser.lastName
                : ""
            }
          >
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: { xs: "1px", sm: 2 } }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{
                  width: 36,
                  height: 36,
                  "&:hover": { border: 3, bordercolor: "#fff" },
                }}
                color={"secondary"}
                alt={currentUser ? currentUser.firstName!.charAt(0) : ""}
                src={currentUser ? currentUser.imageUri! : ""}
              ></Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              //overflow: "visible",
              maxWidth: "250px",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              p: "10px",
              backgroundColor: "#fff",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: "5px",
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          sx={{ "& .MuiMenuItem-root": { minHeight: "0px" } }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={() => navigate("user/profile/" + currentUser!.id)}>
            <ListItemIcon>
              <Avatar
                sx={{
                  bgcolor: "#D0D6D2",
                  color: "#43594C",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                }}
                alt={currentUser?.firstName?.charAt(0)}
                src={currentUser?.imageUri!}
              />
            </ListItemIcon>
            <ListItemText
              primary={currentUser?.firstName + " " + currentUser?.lastName}
              secondary={currentUser?.email}
            />
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => navigate("user/account")}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            {t("account")}
          </MenuItem>

          <MenuItem onClick={() => navigate("faq")}>
            <ListItemIcon>
              <HelpIcon fontSize="small" />
            </ListItemIcon>
            FAQ
          </MenuItem>
          <MenuItem onClick={() => navigate("versions")}>
            <ListItemIcon>
              <ContentPasteSearchIcon fontSize="small" />
            </ListItemIcon>
            {t("versions")}
          </MenuItem>
          <MenuItem
            href="https://soulwise.yoga/allmanna-villkor/"
            target="_blank"
            component="a"
          >
            <ListItemIcon>
              <DescriptionIcon fontSize="small" />
            </ListItemIcon>
            {t("terms")}
          </MenuItem>
          <Divider />
          <Box sx={{ px: "10px", py: "5px" }}>
            <Typography fontSize={11}>{t("supportText")}</Typography>
            <MuiLink fontSize={11} href="mailto:support@soulwise.yoga">
              support@soulwise.yoga
            </MuiLink>
          </Box>
          <Divider />
          <MenuItem onClick={handleLogOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {t("logout")}
          </MenuItem>
        </Menu>
      </React.Fragment>
    </>
  );
};

export default ProfileMenu;
