import { Container, Grid, Skeleton } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { LoadingOverlay } from "components/loaders/LoadingOverlay";
import { NoSubscriptionOverlay } from "components/loaders/NoSubscriptionOverlay";

interface StdViewProps {
  title: React.ReactNode | string;
  children?: React.ReactNode;
  loading?: boolean;
  nosubscription?: boolean;
}

interface StdViewHeaderProps {
  title?: string;
}

const StdViewHeader = ({ title }: StdViewHeaderProps): JSX.Element => {
  return (
    <Typography sx={{ mb: 3 }} variant="h1" gutterBottom>
      {title}
    </Typography>
  );
};

export const StdView = (props: StdViewProps): JSX.Element => {
  return (
    <Container
      maxWidth={"lg"}
      sx={{ px: { xs: "5px", sm: "10px" }, pb: "40px" }}
    >
      <Grid container>
        <Grid item xs={12}>
          {props.loading && <Skeleton height={100} sx={{ mb: 3 }} />}
          {typeof props.title === "string" ? (
            <StdViewHeader title={props.title} />
          ) : (
            props.title
          )}
          <NoSubscriptionOverlay nosubscription={props.nosubscription}>
            <LoadingOverlay loading={props.loading ?? false}>
              {props.children}
            </LoadingOverlay>
          </NoSubscriptionOverlay>
        </Grid>
      </Grid>
    </Container>
  );
};
