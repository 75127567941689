import Typography from "@mui/material/Typography";
import { Box, Drawer, Grid, Stack } from "@mui/material";
import HomePhilosophyCard from "components/cards/HomePhilosophyCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadMySequencesAsync,
  loadAllSequencesAsync,
} from "store/slices/SequenceSlice";
import { authStateSelector } from "store/slices/authSlice";
import {
  getMySequenceOrderByDate,
  getSequenceNotByUser,
} from "store/selectors/sequence";
import {
  loadPhilosophiesAsync,
  philosophyStateSelector,
} from "store/slices/philosophySlice";
import { SequenceDto } from "api/models/SequenceDto";
import SequenceCard from "components/cards/SequenceCard";
import SequenceInfoView from "views/CardViews/SequenceInfoView";
import { StdView } from "views/OtherViews/StdView";
import { useLocales } from "locales";
import { contentfulStateSelector, getInspirationList } from "store/slices/contentfulSlice";
import InspirationCard from "views/InspirationViews/InsprationCard";

export const HomeView = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t, currentLang } = useLocales();
  const { currentUser } = useSelector(authStateSelector);
  const [open, setOpen] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState<SequenceDto>();
  const sequenceList = useSelector((state) =>
    getSequenceNotByUser(state, currentUser?.id)
  );
  const philosophies = useSelector(philosophyStateSelector).philosophies;
  const mySequenceOrderByDate = useSelector(getMySequenceOrderByDate);
  const { inspirationList } = useSelector(contentfulStateSelector);

  useEffect(() => {
    dispatch<any>(loadAllSequencesAsync());
    dispatch<any>(loadMySequencesAsync());
    // dispatch<any>(loadPhilosophiesAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch<any>(getInspirationList(currentLang.value));
  }, [currentLang]);


  useEffect(() => {
    const sequence = mySequenceOrderByDate.find((s) => s.sequenceId === selectedSequence?.sequenceId)
    if(sequence)
      setSelectedSequence(sequence)
  }, [mySequenceOrderByDate]);

  function handleClose(): void {
    setOpen(false);
  }

  function handleClick(sequence: SequenceDto): void {
    setSelectedSequence(sequence);
    setOpen(true);
  }

  return (
    <StdView title={t("homeTitle")}>
      <Stack spacing={3}>
        <Typography variant="h2">{t("homeMySequenceTitle")}</Typography>
        <Box>
          <Grid
            container
            spacing={{
              xs: 1,
            }}
            sx={{
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            {mySequenceOrderByDate ? (
              mySequenceOrderByDate.slice(0, 4).map((sequence, index) => (
                <Grid item key={"ms-" + index} xs={12} sm={6} md={4} lg={3}>
                  <SequenceCard data={sequence} handleClick={handleClick} />
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
        </Box>
        <Typography variant="h2">{t("homeInspiredTitle")}</Typography>
        <Box>
          <Grid
            container
            spacing={{
              xs: 1,
            }}
            sx={{
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            {sequenceList ? (
              sequenceList.slice(0, 6).map((sequence, index) => (
                <Grid item key={"s-" + index} xs={12} sm={6} md={4} lg={3}>
                  <SequenceCard data={sequence} handleClick={handleClick} />
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
        </Box>
        <Typography variant="h3">{t("homePhilosophiesTitle")}</Typography>
        <Box>
          <Grid
            container
            spacing={4.5}
            sx={{
              justifyContent: "center",
            }}
          >
            {inspirationList?.itemsCollection.items.slice(0,3).map((philosophy, index) => (
              <Grid item key={"p-" + index} xs={12} sm={6} lg={4}>
                <InspirationCard data={philosophy} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>

      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#F5F4F0",
          },
        }}
      >
        <SequenceInfoView
          sequence={selectedSequence}
          handleClose={handleClose}
        />
      </Drawer>
    </StdView>
  );
};
