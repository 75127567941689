import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PositionDto, PositionService } from "api";
import { AppThunk, RootState } from "store";

export interface PositionState {
  positions: PositionDto[];
  selectedPosition: PositionDto | null;
  loading: boolean;
  hasError: boolean;
  error?: string;
}

const initialState: PositionState = {
  positions: [],
  selectedPosition: null,
  loading: false,
  hasError: false,
  error: "",
};

export const positionSlice = createSlice({
  name: "position",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    update: (state, action: PayloadAction<PositionDto[]>) => {
      state.positions = action.payload;
    },
    setSelectedPosition: (state, action: PayloadAction<PositionDto | null>) => {
      state.selectedPosition = action.payload;
    },
    updatePos: (state, action: PayloadAction<PositionDto>) => {
      state.positions! = state.positions?.map((position) =>
        position.positionId === action.payload.positionId
          ? action.payload
          : position
      );
    },
  },
});

export const { hasError, loading, update, setSelectedPosition, updatePos } =
  positionSlice.actions;

export const loadPositionsAsync =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const data = await PositionService.getApiPositions();
      dispatch(update(data));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const loadPositionAsync =
  (positionId: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      return await PositionService.getApiPositions1(positionId);
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const positionStateSelector = (state: RootState): PositionState =>
  state.positions;

export default positionSlice.reducer;
