import React from "react";
import Slider from "@mui/material/Slider";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { setNoOfPositions } from "store/slices/sequenceFilterSlice";

export declare type PositionsSliderProps = {
  value: number[];
};

function valuetext(value: number) {
  return `${value}`;
}

export default function PositionsSlider({ value }: PositionsSliderProps) {
  const dispatch = useDispatch();

  const handleChange = (event: any, newValue: number | number[]) => {
    dispatch(setNoOfPositions(newValue as number[]));
  };

  return (
    <Box sx={{ width: "100%", px:1 }}>
      <Slider
        getAriaLabel={() => "Position range"}
        min={1}
        max={200}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
    </Box>
  );
}
