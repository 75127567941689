import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  IconButton,
  Tooltip,
} from "@mui/material";
import { SequenceDto, SequenceItemDto } from "api";
import React from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteSequence, removeSequenceItem } from "store/slices/SequenceFormSlice";
import { useLocales } from "locales";

export interface SequenceItemDeleteDialogProps {
  sequenceItem: SequenceItemDto;
  disabled: boolean;
}

const SequenceItemDeleteDialog = ({
  sequenceItem,
  disabled,
}: SequenceItemDeleteDialogProps) => {
  const dispatch = useDispatch();
  const {t} = useLocales();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (sequenceItem: SequenceItemDto) => {
    console.log(sequenceItem);
    dispatch<any>(removeSequenceItem(sequenceItem));
    handleClose();
  };

  return (
    <>
      <Tooltip title={t('delete')}>
        <span>
          <IconButton
            aria-label="delete"
            disabled={disabled}
            onClick={handleClickOpen}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {t("sequenceItemDeleteInfo")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDelete(sequenceItem)}
            autoFocus
          >
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SequenceItemDeleteDialog;
