import { createSelector } from "@reduxjs/toolkit";
import { SequenceDto, Status, UserDto } from "api";
import _ from "lodash";
import { sequenceFilterStateSelector } from "store/slices/sequenceFilterSlice";
import { sequenceStateSelector } from "store/slices/SequenceSlice";

export const getSequenceByFilter = createSelector(
  [sequenceStateSelector, sequenceFilterStateSelector],
  (sequenceState, sequenceFilterState): SequenceDto[] => {
    const filterd = sequenceState.sequences.filter((sequence) => {
      return (
        (isInString(sequenceFilterState.searchString, sequence.title ?? "") ||
          isInString(
            sequenceFilterState.searchString,
            sequence.user?.firstName ?? ""
          ) ||
          isInString(
            sequenceFilterState.searchString,
            sequence.user?.lastName ?? ""
          )) &&
        isInArray(sequence.level ?? 0, sequenceFilterState.sequenceLevels) &&
        isInArray(sequence.yogaForm ?? 0, sequenceFilterState.yogaForm) &&
        isBoolEqual(sequence.private === false) &&
        sequence.sequenceItems?.length! >=
          sequenceFilterState.noOfPositions[0] &&
        sequence.sequenceItems?.length! <=
          sequenceFilterState.noOfPositions[1] &&
        ((sequenceFilterState.notSpecifiedEstimateDuration &&
          sequence.estimatedDuration === null) ||
          (sequence.estimatedDuration! >=
            sequenceFilterState.estimateDuration[0] &&
            sequence.estimatedDuration! <=
              sequenceFilterState.estimateDuration[1]))
      );
    });

    var filteredSequnece = _.sortBy(filterd, "modifiedDate").reverse();
    return filteredSequnece;
  }
);

export const getSequenceFinished = createSelector(
  [sequenceStateSelector, sequenceFilterStateSelector],
  (sequenceState, sequenceFilterState): SequenceDto[] => {
    const finished = sequenceState.mySequences.filter((finishSequence) => {
      return (
        isInString(
          sequenceFilterState.searchString,
          finishSequence.title ?? ""
        ) &&
        isInArray(
          finishSequence.level ?? 0,
          sequenceFilterState.sequenceLevels
        ) &&
        isInArray(finishSequence.yogaForm ?? 0, sequenceFilterState.yogaForm) &&
        isBoolEqual(finishSequence.status === Status._2) &&
        finishSequence.sequenceItems?.length! >=
          sequenceFilterState.noOfPositions[0] &&
        finishSequence.sequenceItems?.length! <=
          sequenceFilterState.noOfPositions[1]
        //(isBoolEqual(sequence.finished ===true, [...Array(length)].map((x => x) ))
      );
    });
    var sequneceFinished = _.sortBy(finished, "modifiedDate");
    return sequneceFinished;
  }
);

export const getSequenceDraft = createSelector(
  [sequenceStateSelector, sequenceFilterStateSelector],
  (sequenceState, sequenceFilterState): SequenceDto[] => {
    const draft = sequenceState.mySequences.filter((draftSequence) => {
      return (
        isInString(
          sequenceFilterState.searchString,
          draftSequence.title ?? ""
        ) &&
        isInArray(
          draftSequence.level ?? 0,
          sequenceFilterState.sequenceLevels
        ) &&
        isInArray(draftSequence.yogaForm ?? 0, sequenceFilterState.yogaForm) &&
        isBoolEqual(draftSequence.status === Status._1) &&
        draftSequence.sequenceItems?.length! >=
          sequenceFilterState.noOfPositions[0] &&
        draftSequence.sequenceItems?.length! <=
          sequenceFilterState.noOfPositions[1]
      );
    });
    var sequneceDraft = _.sortBy(draft, "modifiedDate").reverse();
    return sequneceDraft;
  }
);

export const getSequenceNotByUser = createSelector(
  [sequenceStateSelector, (state, userId) => userId],
  (sequenceState, userId): SequenceDto[] => {
    const filterd = sequenceState.sequences.filter((sequence) => {
      return (
        isBoolEqual(sequence.publishDate !== "") &&
        isBoolEqual(sequence.private === false) &&
        isBoolEqual(sequence.user?.id !== userId)
      );
    });
    var filteredSequnece = _.sortBy(filterd, "Title");
    return filteredSequnece;
  }
);

export const getSequenceByUser = createSelector(
  [sequenceStateSelector, (state, user) => user],
  (sequenceState, user: UserDto): SequenceDto[] => {
    if (user === null) return [];

    const filterd = sequenceState.sequences.filter((sequence) => {
      return isBoolEqual(sequence.user?.id === user.id);
    });
    var filteredSequnece = _.sortBy(filterd, "Title");
    return filteredSequnece;
  }
);

export const getMySequenceOrderByDate = createSelector(
  [sequenceStateSelector, sequenceFilterStateSelector],
  (sequenceState, sequenceFilterState): SequenceDto[] => {
    var mySequences = _.sortBy(
      sequenceState.mySequences,
      "modifiedDate"
    ).reverse();
    return mySequences;
  }
);

const isInString = (needle: string, haystack: string) => {
  if (needle.length < 1) return true;
  return haystack.match(new RegExp(needle, "i"));
};

const isInArray = (value: number, searchArray: number[]): boolean => {
  if (searchArray.length < 1) return true;
  return searchArray.includes(value);
};

const isBoolEqual = (value1: Boolean) => {
  if (value1 !== true) {
    return false;
  } else {
    return true;
  }
};
