import { Container, Grid, Skeleton } from "@mui/material";
import React from "react";
import { LoadingOverlay } from "components/loaders/LoadingOverlay";

interface CenterViewProps {
  children?: React.ReactNode;
  loading?: boolean;
}

export const CenterView = (props: CenterViewProps): JSX.Element => {
  return (
    <Container maxWidth={"lg"} sx={{display:"flex", justifyContent:"center", alignItems:"center", height:"100vh"}}>
      <Grid container>
        <Grid item xs={12}>
          {props.loading && <Skeleton height={100} sx={{ mb: 3 }} />}
          
          <LoadingOverlay loading={props.loading ?? false}>
            {props.children}
          </LoadingOverlay>
        </Grid>
      </Grid>
    </Container>
  );
};
