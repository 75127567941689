import React, { useEffect } from "react";
import { StdView } from "./StdView";
import { useSelector } from "react-redux";
import { authStateSelector } from "store/slices/authSlice";
import { Container, Stack } from "@mui/system";
import { Alert, Step, StepButton, Stepper } from "@mui/material";
import SubscriptionList from "components/subscription/SubscriptionList";
import PaymentForm from "components/forms/payment/PaymentForm";
import { useSearchParams } from "react-router-dom";
import {
  paymentStateSelector,
  setActiveStep,
  setPriceId,
  setSessionId,
} from "store/slices/paymentSlice";
import { useDispatch } from "react-redux";
import PaymentSummary from "components/forms/payment/PaymentSummary";
import { useLocales } from "locales";


const SubscriptionView = () => {
  const dispatch = useDispatch();
  const { t } = useLocales();

  const steps = [t('selectSubscription'), t('pay'), t('compilation')];

  const authState = useSelector(authStateSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  const { activeStep, selectedPriceId, hasError, error } =
    useSelector(paymentStateSelector);

  useEffect(() => {
    const step = searchParams.get("step");
    const sessionId = searchParams.get("session_id");
    console.log(step);
    console.log(sessionId);
    if (sessionId) {
      dispatch(setSessionId(sessionId));
      dispatch(setActiveStep(2));
    } else if (step) dispatch(setActiveStep(parseInt(step)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStep = (step: number) => () => {
    dispatch(setActiveStep(step));
  };

  const handleSelectPrice = (priceId: number) => {
    dispatch(setPriceId(priceId));
    dispatch(setActiveStep(1));
  };

  return (
    <StdView title={t("subscription")} nosubscription>
      <Container maxWidth={"lg"}>
        <Stack>
          {hasError && (
            <Alert severity="error" variant="outlined" sx={{ mb: "20px" }}>
              {error}
            </Alert>
          )}
          <Stepper activeStep={activeStep} sx={{ mb: "20px" }}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 0 && (
            <SubscriptionList
              selectedPriceId={selectedPriceId}
              onSelect={handleSelectPrice}
            />
          )}
          {activeStep === 1 && <PaymentForm returnPath="subscription" />}
          {activeStep === 2 && <PaymentSummary />}
        </Stack>
      </Container>
    </StdView>
  );
};

export default SubscriptionView;
