import { useCallback } from "react";
import { m } from "framer-motion";
// @mui
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
// locales

// components
import Iconify from "../iconify";
import { varHover } from "../animate";
import CustomPopover, { usePopover } from "../custom-popover";
import { useLocales } from "locales";
import {
  Box,
  Button,
  InputAdornment,
  Select,
  SxProps,
  Typography,
} from "@mui/material";

// ----------------------------------------------------------------------
interface LanguagePopoverProps {
  color?: string;
}

export default function LanguagePopover({ color }: LanguagePopoverProps) {
  const locales = useLocales();

  const popover = usePopover();

  const handleChangeLang = useCallback(
    (newLang: string) => {
      locales.onChangeLang(newLang);
      popover.onClose();
    },
    [locales, popover]
  );

  const renderNormal = (
    <Select
      value={locales.currentLang.value}
      onChange={(e) => handleChangeLang(e.target.value)}
      displayEmpty
      size="small"
      startAdornment={<Iconify width={32} icon={"flowbite:language-outline"} />}
      sx={{
        display: { sm: "flex", xs: "none" },
        backgroundColor: "transparent",
        "&.MuiSelect-outlined": {
          fontSize: "12",
        },
        color: {color},
        borderColor: {color}
   
      }}
    >
      {locales.allLangs.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );

  const renderMobile = (
    <>
      <Button
        onClick={popover.onOpen}
        sx={{
          display: { sm: "none", xs: "flex" },
          ...(popover.open && {
            bgcolor: "action.selected",
          }),
        }}
        variant="outlined"
        endIcon={
          <Iconify
            width={16}
            icon={"material-symbols:arrow-drop-down-rounded"}
          />
        }
      >
        <Iconify width={24} icon={"flowbite:language-outline"} />
      </Button>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 160 }}
      >
        {locales.allLangs.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === locales.currentLang.value}
            onClick={() => handleChangeLang(option.value)}
            dense
          >
            {option.label}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );

  return (
    <>
      {renderNormal}
      {renderMobile}
    </>
  );
}
