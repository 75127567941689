import { Grid, Typography } from "@mui/material";
import ChangeEmailForm from "components/forms/ChangeEmailForm";

export default function ChangeEmailView() {
  return (
    <Grid sx={{ ml: { xs: 0, md: "240px" }, mt: { xs: "89px", md: "49px" } }}>
      <Grid container>
        <Grid item xs={12} sx={{ mb: "40px" }}>
          <Grid container xs={12} sx={{ justifyContent: "center" }}>
            <img src="soulwiseWrapperLogo.png" width={146.45} />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mb: "40px" }}>
          <Grid container xs={12} sx={{ justifyContent: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Ändra e-postadress
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container xs={12} sx={{ justifyContent: "center" }}>
            <Grid item maxWidth={"300px"}>
              <ChangeEmailForm />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
