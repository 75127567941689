import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/index";
import { boolean } from "yup";

export interface PositionFilterState {
  levels: number[];
  mainTypes: number[];
  searchString: string;
  page: number;
  pageSize: number;
  filtering: boolean;
}

const initialState: PositionFilterState = {
  levels: [],
  mainTypes: [],
  searchString: "",
  page: 0,
  pageSize: 10,
  filtering: false,
};

export const positionFilterSlice = createSlice({
  name: "positionFilter",
  initialState,
  reducers: {
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLevels: (state, action: PayloadAction<number[]>) => {
      state.levels = action.payload;
    },
    setMainTypes: (state, action: PayloadAction<number[]>) => {
      state.mainTypes = action.payload;
    },
    resetFilter: (state) => {
      state.levels = [];
      state.mainTypes = [];
      state.searchString = "";
    },
    Filtering: (state, action: PayloadAction<boolean>) => {
      state.filtering = action.payload;
    },
  },
});

export const {
  setSearchString,
  setPage,
  setLevels,
  setMainTypes,
  resetFilter,
  Filtering,
} = positionFilterSlice.actions;

export default positionFilterSlice.reducer;

export const positionFilterStateSelector = (
  state: RootState
): PositionFilterState => state.positionFilter;
