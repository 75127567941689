import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { SequenceItemDto } from "api";
import { useFormik } from "formik";
import { useLocales } from "locales";
import React from "react";
import { useDispatch } from "react-redux";
import { updateSequenceItem } from "store/slices/SequenceFormSlice";

export interface InfoViewProp {
  item?: SequenceItemDto | null;
  handleClose: () => void;
  type: "edit" | "view";
  translateText?: string;
}

const InfoView = ({ item, handleClose, type, translateText }: InfoViewProp) => {
  const dispatch = useDispatch();
  const { t } = useLocales();
  const formik = useFormik({
    initialValues: {
      information: item!.information,
    },
    onSubmit: (values) => {
      console.log(JSON.stringify(values));
      const newItem = { ...item };
      newItem.information = values.information;
      dispatch(updateSequenceItem(newItem));
      handleClose();
    },
  });

  return (
    <>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        sx={{ width: "300px" }}
      >
        {type === "view" && (
          <Typography sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
            {translateText ? translateText : item?.information}
          </Typography>
        )}
        {type === "edit" && (
          <>
            <TextField
              sx={{ marginTop: 1 }}
              name="information"
              label="Information"
              multiline
              value={formik.values.information}
              rows={10}
              fullWidth
              InputLabelProps={{ shrink: true }}
              onChange={formik.handleChange}
            />
            <Container
              maxWidth={false}
              sx={{
                display: "flex",
                flexGrow: 1,
                //backgroundColor: "#fff",
                p: 1,
              }}
            >
              <Grid container sx={{ width: "100%" }} spacing={"10px"}>
                <Grid item xs={12} sm={6}>
                  <Button
                    color="primary"
                    variant="outlined"
                    sx={{
                      width: "100%",
                      height: "32x",
                      borderRadius: "50px",
                    }}
                    onClick={handleClose}
                  >
                    {t("cancel")}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "32x",
                      borderRadius: "50px",
                    }}
                    type="submit"
                  >
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </Box>
    </>
  );
};

export default InfoView;
