import {
  Box,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import PositionCard from "components/cards/PositionCard";
import PositionFilterIcon from "components/drawers/PositionFilterDrawer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadPositionsAsync } from "store/slices/positionSlice";
import { setSearchString } from "store/slices/positionFilterSlice";
import { getPositionsByFilter } from "store/selectors/position";
import SearchIcon from "@mui/icons-material/Search";
import { StdView } from "views/OtherViews/StdView";
import { useLocales } from "locales";

export default function PositionView() {
  const dispatch = useDispatch();
  const { t } = useLocales();
  const positionList = useSelector(getPositionsByFilter);

  useEffect(() => {
    dispatch<any>(loadPositionsAsync());
  }, [dispatch]);

  const onSearchEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchString(e.target.value));
  };

  return (
    <StdView
      title={`${t("positionTitle")} (${
        positionList ? positionList.length : ""
      })`}
    >
      <Stack direction={"column"} spacing={3}>
        <Typography variant="h3" fontWeight={400}>
          {t("positionDescription")}
        </Typography>
        <Stack direction={"row"} spacing={1}>
          <OutlinedInput
            sx={{ width: "100%", borderRadius: "50px", height: "46px" }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            placeholder={t("positionSearchPlaceholder")}
            onChange={onSearchEvent}
          />
          <PositionFilterIcon />
        </Stack>
        <Box>
          <Grid container spacing={{ xs: 1, sm: 1 }}>
            {positionList &&
              positionList.map((position, index) => (
                <Grid key={index} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <PositionCard data={position} />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Stack>
    </StdView>
  );
}
