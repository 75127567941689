import {
  Avatar,
  Drawer,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { SequenceItemDto, SequenceItemType } from "api";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SequenceItemDeleteDialog from "./SequenceItemDeleteDialog";
import DrawerContent from "components/drawers/DrawerContent";
import DrawerHeader from "components/drawers/DrawerHeader";
import PositionsInfoView from "views/CardViews/PositionsInfoView";
import InfoView from "views/CardViews/InfoView";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import PauseIcon from "@mui/icons-material/Pause";
import { useLocales } from "locales";

export type SequenceDraggableListItemProps = {
  item: SequenceItemDto;
  index: number;
  onToogleFlip: (id: number) => void;
};

const SequenceDraggableListItem = ({
  item,
  index,
  onToogleFlip,
}: SequenceDraggableListItemProps) => {
  const [open, setOpen] = useState(false);
  const { t, currentLang } = useLocales();

  function handleClose(): void {
    setOpen(false);
  }

  return (
    <>
      <Draggable
        draggableId={item.id ? item.id?.toString() : "0"}
        index={index}
      >
        {(provided, snapshot) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              background: snapshot.isDragging ? "rgb(235,235,235)" : "#fff",
              my: "5px",
              borderRadius: "10px",
              boxShadow: 1,
              "&.MuiListItem-root": { py: "5px", pl: "3px" },
              "&:hover": { background: "rgb(235,235,235)" },
            }}
            secondaryAction={
              <SequenceItemDeleteDialog sequenceItem={item} disabled={false} />
            }
          >
            {item?.type === SequenceItemType._1 && (
              <>
                <DragIndicatorIcon
                  sx={{ color: "#bdbdbd", "&:hover": { color: "#000" } }}
                />
                <ListItemAvatar>
                  <Avatar
                    alt={item.position?.sanskrit!}
                    sx={{
                      transform: `scaleX(${
                        item.imageFlip ? item.imageFlip : 1
                      })`,
                    }}
                    src={
                      "..\\PositionImages\\" +
                      item.position?.positionImages![0].path
                    }
                    onClick={() => onToogleFlip(item.id!)}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{ cursor: "pointer" }}
                  onClick={() => setOpen(true)}
                  primary={item.position?.sanskrit}
                  secondary={
                    currentLang.value === "en"
                      ? item.position?.english
                      : item.position?.swedish
                  }
                />
              </>
            )}
            {item?.type === SequenceItemType._2 && (
              <>
                <DragIndicatorIcon
                  sx={{ color: "#bdbdbd", "&:hover": { color: "#000" } }}
                />
                <ListItemAvatar>
                  <Avatar>
                    <AssignmentIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ cursor: "pointer" }}
                  onClick={() => setOpen(true)}
                  primary={t("note")}
                  secondary={
                    <Typography
                      variant="body2"
                      sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                    >
                      {item.information
                        ? item.information
                        : t("enterInformation")}
                    </Typography>
                  }
                />
              </>
            )}
            {item?.type === SequenceItemType._3 && (
              <>
                <DragIndicatorIcon
                  sx={{ color: "#bdbdbd", "&:hover": { color: "#000" } }}
                />
                <ListItemAvatar>
                  <Avatar>
                    <PauseIcon fontSize="small" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ cursor: "pointer" }}
                  onClick={() => setOpen(true)}
                  primary={"Paus"}
                  secondary={
                    item.information ? item.information : t('enterInformation')
                  }
                />
              </>
            )}
          </ListItem>
        )}
      </Draggable>
      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#FFF",
            minWidth: "300px",
          },
        }}
      >
        <DrawerHeader handleClose={handleClose} />
        <DrawerContent>
          {item?.type === SequenceItemType._1 && (
            <PositionsInfoView position={item.position} />
          )}
          {item?.type === SequenceItemType._2 && (
            <InfoView item={item} handleClose={handleClose} type="edit" />
          )}
          {item?.type === SequenceItemType._3 && (
            <InfoView item={item} handleClose={handleClose} type="edit" />
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SequenceDraggableListItem;
