import { SequenceItemDto } from "api";
import React from "react";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import SequenceDraggableListItem from "./SequenceDraggableListItem";

export type SequenceDraggableListProps = {
  items: SequenceItemDto[];
  onDragEnd: OnDragEndResponder;
  onToogleFlip: (id: number) => void;
};

const SequenceDraggableList = React.memo(
  ({ items, onDragEnd, onToogleFlip }: SequenceDraggableListProps) => {
    return (
      items && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((item, index) => (
                  <SequenceDraggableListItem
                    item={item}
                    index={index}
                    key={item.id}
                    onToogleFlip={onToogleFlip}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )
    );
  }
);

export default SequenceDraggableList;
