import { Box, Stack, SxProps } from "@mui/system";
import React from "react";
import DrawerCloseButton from "./DrawerCloseButton";
import { AppBar, Divider, Grid, Toolbar } from "@mui/material";

export type DrawerHeaderProps = {
  handleClose: () => void;
  toolbar?: React.ReactNode;
  sx?: SxProps;
};

const DrawerHeader = ({ handleClose, toolbar, sx }: DrawerHeaderProps) => {
  return (
    <Toolbar sx={{ "&.MuiToolbar-root": { px: "16px" }, mb: "10px", backgroundColor: "#FFFFFF", ...sx }}>
      <Stack direction={"row"} sx={{flexGrow:1}}>
        <DrawerCloseButton handleClick={() => handleClose!()} />
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "flex-end",
            alignItems: "center",
            mr: "25px"
          }}
        >
          {toolbar}
        </Box>
      </Stack>
    </Toolbar>
  );
};

export default DrawerHeader;
