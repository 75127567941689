import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getMeAsync } from 'store/slices/authSlice'

export const useAuthMe = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getMeAsync())
  }, [dispatch])
  
}
