import { Card, CardMedia, Grid } from "@mui/material";
import PhilosophyCard from "components/cards/PhilosophyCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  loadPhilosophiesAsync,
  philosophyStateSelector,
} from "store/slices/philosophySlice";
import { StdView } from "views/OtherViews/StdView";

const PhilosophyViewHeader = (): JSX.Element => {
  return (
    <Card sx={{ mb: "15px", borderRadius: "10px" }}>
      <CardMedia
        sx={{ height: { xs: 200, sm: 336 } }}
        image="/Backgrounds/Philosophy.png"
        title="Filosofi"
      />
    </Card>
  );
};

export default function PhilosophyView() {
  const dispatch = useDispatch();
  const { philosophies } = useSelector(philosophyStateSelector);

  useEffect(() => {
    dispatch<any>(loadPhilosophiesAsync());
  }, [dispatch]);

  return (
    <StdView title={<PhilosophyViewHeader />}>
      <Grid container spacing={2} sx={{ border: 0 }}>
        {philosophies.map((philosophy, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            lg={4}
            sx={{
              maxWidth: {
                xs: "100%",
              },
            }}
          >
            <PhilosophyCard data={philosophy} />
          </Grid>
        ))}
      </Grid>
    </StdView>
  );
}
