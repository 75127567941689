import React from "react";
import { StdView } from "./StdView";
import Alert from "@mui/material/Alert";

interface ErrorViewProps {
  title: string;
  message: string;
}

export const ErrorView = (props: ErrorViewProps): JSX.Element => {
  return (
    <StdView title={props.title}>
      <Alert severity="error">{props.message}</Alert>
    </StdView>
  );
};
