import { Card, Typography } from "@mui/material";
import { Container, Box } from "@mui/system";
import React from "react";
import { CenterView } from "views/OtherViews/CenterView";

export interface AuthCardProps {
  title?: string;
  subTitle?: string;
  form?: React.ReactElement;
  footer?: React.ReactElement;
}

const AuthCard = ({ title, subTitle, form, footer }: AuthCardProps) => {
  return (
    <CenterView>
      <Container maxWidth={"xs"} sx={{ border: 0 }}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            sx={{
              borderRadius: 3,
              textAlign: "center",
              padding: "40px",
              overflow: "hidden",
              backgroundColor:"#fff",
              boxShadow:
                "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
            }}
          >
            <Box
              sx={{
                mb: "10px",
                ".logo": {
                  height: 56,
                },
              }}
            >
              <img
                className="logo"
                src={"/images/soulwisewrapperlogo.png"}
                alt="soulwise yoga"
              />
            </Box>
            <Box
              sx={{
                mb: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {title && (
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: 20,
                  }}
                >
                  {title}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                mb: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {subTitle && (
                <Typography
                  
                >
                  {subTitle}
                </Typography>
              )}
            </Box>

            {form && (
              <Box
                sx={{
                  mb: 3,
                }}
              >
                {form}
              </Box>
            )}

            {footer && <Box>{footer}</Box>}
          </Card>
        </Box>
      </Container>
    </CenterView>
  );
};

export default AuthCard;
