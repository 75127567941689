import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PhilosophyContentDto, PhilosophyDto, PhilosophyService } from "api";
import { AppThunk, RootState } from "store";

export interface PhilosophyState {
  philosophies: PhilosophyDto[];
  philosophyContent: PhilosophyContentDto[];
  loading: boolean;
  hasError: boolean;
  error?: string;
  latestSelected: PhilosophyDto | null;
}

const initialState: PhilosophyState = {
  philosophies: [],
  philosophyContent: [],
  loading: false,
  hasError: false,
  error: "",
  latestSelected: null,
};

export const philosophySlice = createSlice({
  name: "philosophy",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    update: (state, action: PayloadAction<PhilosophyDto[]>) => {
      state.philosophies = action.payload;
      state.loading = false;
    },
    updateAccordionContent: (
      state,
      action: PayloadAction<PhilosophyContentDto[]>
    ) => {
      state.philosophyContent = action.payload;
      state.loading = false;
    },
    updateLatestSelected: (state, action: PayloadAction<PhilosophyDto>) => {
      state.latestSelected = action.payload;
    },
  },
});

export const {
  hasError,
  loading,
  update,
  updateAccordionContent,
  updateLatestSelected,
} = philosophySlice.actions;

export const loadPhilosophiesAsync =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const data = await PhilosophyService.getApiPhilosophy();
      dispatch(update(data));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const loadPhilosophyContentAsync =
  (
    content: PhilosophyContentDto[],
    titel: string,
    introduction: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      //const data = await PhilosophyService.getApiPhilosophy();
      dispatch(updateAccordionContent(content));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const philosophyStateSelector = (state: RootState): PhilosophyState =>
  state.philosophy;

export default philosophySlice.reducer;
