import { FieldHookConfig, useField } from "formik";
import TextField from "@mui/material/TextField";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import {
  FormControl,
  FormLabel,
  Stack,
} from "@mui/material";

export declare type AppTextFieldProps = {
    title?: string;
    subTitle?: string;
  };

const AppTextField = (props: TextFieldProps & FieldHookConfig<string> & AppTextFieldProps) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl fullWidth={props.fullWidth} error={!!errorText ? true : false}>
      <Stack direction={"row"} spacing={"2px"}>
        <FormLabel sx={{ fontWeight: 600, mb: "3px" }}>{props.title}</FormLabel>
        <FormLabel sx={{ fontWeight: 300, mb: "3px" }}>{props.subTitle}</FormLabel>
      </Stack>
      
      <TextField
        {...field}
        {...props}
        value={field.value ? field.value : ""}
        error={!!errorText}
        helperText={errorText}
        size="small"
      />
    </FormControl>
  )
}

export default AppTextField