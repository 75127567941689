import React from "react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { IconButton, alpha, useTheme } from "@mui/material";

export type DrawerCloseButtonProps = {
  handleClick: () => void;
};

const DrawerCloseButton = ({ handleClick }: DrawerCloseButtonProps) => {
  const theme = useTheme();
  return (
    <IconButton
     
      onClick={() => handleClick()}
      sx={{
        width: "40px",
        mt: "10px",
        mb: "10px",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, 0.8),
        },
      }}
    >
      <ArrowBackIosNewRoundedIcon />
    </IconButton>
  );
};

export default DrawerCloseButton;
