import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import MainHeader from "./MainHeader";
import NewVersionAlert from "components/alerts/NewVersionAlert";
import NoSubscriptionAlert from "components/alerts/NoSubscriptionAlert";

export const MainLayout = () => {
  return (
    <Container
      maxWidth={false}   
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <MainHeader />
      <Container
        maxWidth={'lg'}
        sx={{ "&.MuiContainer-root": { px: "10px", pt: "30px" } }}
      >
        <NewVersionAlert/>
        <NoSubscriptionAlert />
        <Outlet />
      </Container>
    </Container>
  );
};
