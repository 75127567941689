/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { ForgotPasswordRequest } from '../models/ForgotPasswordRequest';
import type { RegisterRequest } from '../models/RegisterRequest';
import type { Tokens } from '../models/Tokens';
import type { UserDto } from '../models/UserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountService {

    /**
     * @param username 
     * @param password 
     * @returns Tokens Success
     * @throws ApiError
     */
    public static postApiAccountLogin(
username?: string,
password?: string,
): CancelablePromise<Tokens> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/login',
            query: {
                'username': username,
                'password': password,
            },
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Tokens Success
     * @throws ApiError
     */
    public static postApiAccountRegister(
requestBody?: RegisterRequest,
): CancelablePromise<Tokens> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/register',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Tokens Success
     * @throws ApiError
     */
    public static postApiAccountRefreshTokens(
requestBody?: Tokens,
): CancelablePromise<Tokens> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/refresh-tokens',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns UserDto Success
     * @throws ApiError
     */
    public static getApiAccountCurrent(): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/account/current',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiAccountForgotPassword(
requestBody?: ForgotPasswordRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/forgotPassword',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiAccountResetPassword(
requestBody?: ChangePasswordRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/resetPassword',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
