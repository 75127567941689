import * as React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Grid, Typography, styled } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useState } from "react";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "15px",
  backgroundColor: "#FFF",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

interface InfoAccordionProps {
  title: string;
  text: string;
}

export const InfoAccordion = ({
  title,
  text,
}: InfoAccordionProps): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}>
        <Typography variant="h6" component={"h3"}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>
        <Grid>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
