import { Divider, IconButton, Tooltip } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { SequenceDto } from "api";
import DrawerHeader from "components/drawers/DrawerHeader";
import Iconify from "components/iconify";
import SequenceCommentForm from "components/sequence/SequenceCommentForm";
import SequenceCommentList, { SequenceCommentDto2 } from "components/sequence/SequenceCommentList";
import { useLocales } from "locales";
import React, { useState } from "react";

export interface SequenceCommentViewProps {
  sequence: SequenceDto;
  handleClose?: () => void;
}

const SequenceCommentView = ({
  sequence,
  handleClose,
}: SequenceCommentViewProps) => {
  const { t, currentLang } = useLocales();

  const [translate, setTranslate] = useState(false)
  function handleTranslate(): void {
    setTranslate(true)
  }

  return (
    <>
      <DrawerHeader
        handleClose={() => handleClose!()}
        sx={{ backgroundColor: "#fff" }}
        toolbar={<Tooltip title={t("translate")}>
        <span>
          <IconButton aria-label="translate" onClick={handleTranslate}>
            <Iconify width={24} icon={"flowbite:language-outline"} />
          </IconButton>
        </span>
      </Tooltip>}
      />
      <Box sx={{ px: "20px", my: "10px" }}>
        <SequenceCommentForm sequenceId={sequence.sequenceId!} />
      </Box>
      <Divider sx={{ mb: "5px" }} />
      <Stack
        sx={{
          overflowY: "scroll",
          width: { xs: "100%", md: "500px" },
          px: "20px",
        }}
      >
        <SequenceCommentList
          sequenceId={sequence.sequenceId!}
          comments={sequence.comments! as SequenceCommentDto2[]}
          translate={translate}
        />
      </Stack>
    </>
  );
};

export default SequenceCommentView;
