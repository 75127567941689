import FaqAccordation from "components/accordions/FaqAccordation";
import { useLocales } from "locales";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  contentfulStateSelector,
  loadFaqList,
} from "store/slices/contentfulSlice";
import { StdView } from "views/OtherViews/StdView";

const FaqView = () => {
  const dispatch = useDispatch<any>();
  const { t, currentLang } = useLocales();
  const { faqList } = useSelector(contentfulStateSelector);

  useEffect(() => {
    dispatch(loadFaqList(currentLang.value));
  }, [dispatch, currentLang]);

  return (
    <StdView title={t("faqTitle")} nosubscription>
      {faqList &&
        faqList.listCollection.items.map((item, index) => {
          return <FaqAccordation item={item} key={index} />;
        })}
    </StdView>
  );
};

export default FaqView;
