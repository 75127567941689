import {
  Grid,
  Card,
  CardHeader,
  Divider,
  Button,
  CardContent,
  Alert,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authStateSelector } from "store/slices/authSlice";
import {
  cancelSubscriptionAsync,
  resumeSubscriptionAsync,
} from "store/slices/paymentSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useBoolean } from "api/hooks/use-boolean";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog";
import { getDatePartFromString } from "utils/dateUtils";
import { SubscriptionDto } from "api";
import { useLocales } from "locales";

interface SubscriptionCardProps {}

const SubscriptionCard = ({}: SubscriptionCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useLocales();

  const [subscription, setSubscription] = useState<SubscriptionDto | null>(
    null
  );

  const openCancel = useBoolean();

  const { currentUser } = useSelector(authStateSelector);

  useEffect(() => {
    if (currentUser?.subscriptions) {
      setSubscription(currentUser?.subscriptions[0]);
    }
  }, [currentUser]);

  const handleCancelSubscription = useCallback(() => {
    dispatch<any>(cancelSubscriptionAsync());
  }, [dispatch]);

  const handleResumeSubscription = useCallback(() => {
    dispatch<any>(resumeSubscriptionAsync());
  }, [dispatch]);

  const renderSubscriptionInfo = () => {
    return (
      <Stack spacing={2} sx={{ p: 1, pt: 0, typography: "body2" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} sx={{ color: "text.secondary" }}>
            {t("product")}
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ typography: "h3", textTransform: "capitalize" }}
          >
            {subscription && subscription.isActive
              ? subscription.product?.name
              : "-"}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} sx={{ color: "text.secondary" }}>
            {t("subscriptionStart")}
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ typography: "subtitle2", textTransform: "capitalize" }}
          >
            {subscription && subscription.isActive
              ? getDatePartFromString(subscription.currentPeriodStart)
              : "-"}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} sx={{ color: "text.secondary" }}>
            {t("subscriptionEnd")}
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ typography: "subtitle2", textTransform: "capitalize" }}
          >
            {subscription && subscription.isActive
              ? getDatePartFromString(subscription.currentPeriodEnd)
              : "-"}
          </Grid>
        </Grid>
        {!subscription?.isCanceled && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} sx={{ color: "text.secondary" }}>
              <Typography sx={{ fontStyle: "italic" }} variant="body2">
                {t("renewalSubscription")}
              </Typography>
            </Grid>
          </Grid>
        )}
        {subscription?.isCanceled && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} sx={{ color: "text.secondary" }}>
              {t("subscriptionCanceled")}
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ typography: "subtitle2", textTransform: "capitalize" }}
            >
              {subscription
                ? getDatePartFromString(subscription.canceledAt!)
                : "-"}
            </Grid>
          </Grid>
        )}
        {subscription?.isCanceled && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} sx={{ color: "text.secondary" }}>
              {t("subscriptionLastDay")}
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ typography: "subtitle2", textTransform: "capitalize" }}
            >
              {subscription
                ? getDatePartFromString(subscription.cancelAt!)
                : "-"}
            </Grid>
          </Grid>
        )}
      </Stack>
    );
  };

  const renderNoSubscription = () => {
    return <Alert variant="soulwise">{t("subscriptionMandatory")}</Alert>;
  };

  return (
    <>
      <Card>
        <CardHeader title={t("subscription")} sx={{ mb: "10px" }} />
        <CardContent>
          {subscription ? renderSubscriptionInfo() : renderNoSubscription()}
        </CardContent>

        <Divider sx={{ borderStyle: "dashed" }} />

        {subscription?.isActive && !subscription?.isCanceled && (
          <Stack
            spacing={1.5}
            direction="row"
            justifyContent="flex-end"
            sx={{ p: 3 }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={openCancel.onToggle}
            >
              {t("abortSubscription")}
            </Button>
          </Stack>
        )}

        {!subscription?.isActive && (
          <Stack
            spacing={1.5}
            direction="row"
            justifyContent="flex-end"
            sx={{ p: 3 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/subscription")}
            >
              {t("selectSubscription")}
            </Button>
          </Stack>
        )}

        {subscription?.isActive && subscription?.isCanceled && (
          <Stack
            spacing={1.5}
            direction="row"
            justifyContent="flex-end"
            sx={{ p: 3 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleResumeSubscription()}
            >
              {t("wakeUpSubscription")}
            </Button>
          </Stack>
        )}
      </Card>
      <CancelSubscriptionDialog
        open={openCancel.value}
        onClose={openCancel.onFalse}
        onCancelSubscription={handleCancelSubscription}
      />
    </>
  );
};

export default SubscriptionCard;
