import { useTheme } from "@emotion/react";
import { Badge, BadgeProps, IconButton, styled } from "@mui/material";
import { SequenceDto } from "api";
import Iconify from "components/iconify";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { onLikeSequenceAsync } from "store/slices/SequenceSlice";
import { authStateSelector } from "store/slices/authSlice";

export interface SequenceLikeButtonProps {
  item: SequenceDto;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 1,
    top: 4,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 3px",
    fontSize: 9,
    height: 16,
    minWidth: 16,
  },
}));

const SequenceLikeButton = ({ item }: SequenceLikeButtonProps) => {
  const { currentUser } = useSelector(authStateSelector);
  const dispatch = useDispatch();

  const handleLikeSequence = () => {
    dispatch<any>(onLikeSequenceAsync(item.sequenceId!));
  };

  const hasUserLike = (): boolean => {
    const hasLike = item.likes?.findIndex(
      (like) => like.userId! === currentUser?.id!
    );

    if (hasLike !== -1) {
      return true;
    } else return false;
  };

  return (
    <IconButton
      onClick={handleLikeSequence}
      sx={{ "&.MuiIconButton-root": { p: "1px" } }}
    >
      <StyledBadge badgeContent={item.likes?.length} color="secondary">
        {hasUserLike() ? (
          <Iconify
            icon="mdi:heart"
            color={(theme) => theme.palette.primary.main}
            width={24}
          />
        ) : (
          <Iconify icon="mdi:heart-outline" width={24} />
        )}
      </StyledBadge>
    </IconButton>
  );
};

export default SequenceLikeButton;
