import { Button, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { PhilosophyDto } from "api";
import { useDispatch } from "react-redux";
import { updateLatestSelected } from "store/slices/philosophySlice";
import PhilosophyDrawer from "components/drawers/PhilosophyDrawer";

export interface PhilosophyCardProps {
  data: PhilosophyDto | null;
}
export default function PhilosophyCard({ data }: PhilosophyCardProps) {
  const dispatch = useDispatch();

  return (
    <Card
      sx={{
        borderRadius: "15px",
        background: "#43594C",
        height: "264px",
        position: "relative",
        pl: { xs: "6%", sm: 0 },
        pr: { xs: "6%", sm: 0 },
      }}
    >
      <Grid sx={{ mt: "41px", mb: "21px" }}>
        <Grid sx={{ pl: "12px", pr: "12px", pt: 0, pb: "0px" }}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{ color: "#fff", textAlign: "center", mb: "21px" }}
          >
            {data?.cardTitel}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "#fff",
              textAlign: "center",
              textShadow: "0px 3px 6px #00000029",
              lineHeight: "22px",
            }}
          >
            {data?.cardDescription}
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        <CardActions sx={{ p: 0 }}>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid sx={{ position: "absolute", bottom: "31px" }}>
              <PhilosophyDrawer philosophy={data!}/>
            </Grid>
          </Grid>
        </CardActions>
      </Grid>
    </Card>
  );
}
