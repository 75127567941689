import axios from "axios";

const ContentfulService = axios.create({
  baseURL: "https://graphql.contentful.com/content/v1/spaces/fh5hsh84e0vt", 
  headers: {
    "Content-Type": "application/json",
    Authorization : "Bearer hvvmwQETuyOINF7lGhwOGJAXUhjUsgxin-e4XbRAbgE"
  },
 
});


export default ContentfulService;