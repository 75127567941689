import { MenuItem, ToggleButton } from "@mui/material";
import { useLocales } from "locales";
import React, { useEffect, useState } from "react";
import _ from "lodash";

type yogaFormItem = {
  id: number;
  label: string;
};

interface YogaFormItemsProps {
  itemType: "MenuItem" | "ToggleButton";
}

const YogaFormItems = ({ itemType }: YogaFormItemsProps) => {
  const { t } = useLocales();

  const yogaFormIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

  const [yogaFormList, setYogaFormList] = useState<yogaFormItem[]>([]);

  useEffect(() => {
    const items = yogaFormIds.map((item) => {
      return { id: item, label: t(`yogaForm${item}`) } as yogaFormItem;
    });
    setYogaFormList(items);
  }, [t]);

  return _.sortBy(yogaFormList, "label").map((item, index) => {
    return itemType === "MenuItem" ? (
      <MenuItem key={index} value={item.id}>
        {item.label}
      </MenuItem>
    ) : (
      <ToggleButton value={item.id} key={index} aria-label="laptop">
        {item.label}
      </ToggleButton>
    );
  });
};

export default YogaFormItems;
