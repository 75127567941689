import { MenuItem, ToggleButton } from "@mui/material";
import { useLocales } from "locales";
import React, { useEffect, useState } from "react";
import _ from "lodash";

type mainTypeItem = {
  id: number;
  label: string;
};

interface MainTypeItemsProps {
  itemType: "MenuItem" | "ToggleButton";
}

const MainTypeItems = ({ itemType }: MainTypeItemsProps) => {
  const { t } = useLocales();

  const mainTypeIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const [mainTypeList, setMainTypeList] = useState<mainTypeItem[]>([]);

  useEffect(() => {
    const items = mainTypeIds.map((item) => {
      return { id: item, label: t(`mainTypeText${item}`) } as mainTypeItem;
    });
    setMainTypeList(items);
  }, [t]);

  return (
    <>
      {_.sortBy(mainTypeList, "label").map((item, index) => {
        return itemType === "MenuItem" ? (
          <MenuItem key={index} value={item.id}>
            {item.label}
          </MenuItem>
        ) : (
          <ToggleButton value={item.id} key={index} aria-label="laptop">
            {item.label}
          </ToggleButton>
        );
      })}
    </>
  );
};

export default MainTypeItems;
