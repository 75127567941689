import { Alert, Link as MuiLink } from "@mui/material";
import { useLocales } from "locales";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as ReactRouterLink } from "react-router-dom";
import { authStateSelector } from "store/slices/authSlice";


const NoSubscriptionAlert = () => {
  const dispatch = useDispatch();
  const {t} = useLocales();
  const { currentUser } = useSelector(authStateSelector);

  const [hasActiveSubscription, setHasActiveSubscription] = useState(true);

  useEffect(() => {
    if(currentUser)
    {
      if (
        currentUser?.subscriptions &&
        currentUser?.subscriptions.length > 0 &&
        currentUser?.subscriptions[0].isActive ||
        currentUser?.isAdmin
      )
        setHasActiveSubscription(true);
      else setHasActiveSubscription(false);
    }
    
  }, [currentUser]);

  return (
    <>
      {!hasActiveSubscription && (
        <Alert severity="info" variant="soulwiseLight" sx={{mb:'15px'}}>
          {t('subscriptionMandatory')} {t('createThat')} <MuiLink component={ReactRouterLink} to={"/user/account?tabid=1"} underline="always" >{t('here')}</MuiLink>.
        </Alert>
      )}
    </>
  );
};
export default NoSubscriptionAlert;
