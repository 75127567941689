import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  loadProductsAsync,
  paymentStateSelector,
} from "store/slices/paymentSlice";
import SubscriptionCard from "./SubscriptionCard";

interface SubscriptionListProps {
  onSelect(priceId: number): void;
  selectedPriceId: number;
}

const SubscriptionList = ({
  selectedPriceId,
  onSelect,
}: SubscriptionListProps) => {
  const dispatch = useDispatch();

  const { products } = useSelector(paymentStateSelector);

  useEffect(() => {
    dispatch<any>(loadProductsAsync());
  }, [dispatch]);

  return (
    <Box sx={{ mt: "30px" }}>
      <Grid container spacing={2}>
        {products?.map((product) => (
          <>
            {product.prices?.map((price, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <SubscriptionCard
                  product={product}
                  price={price}
                  selectedPriceId={selectedPriceId}
                  onSelect={onSelect}
                />
              </Grid>
            ))}
          </>
        ))}
      </Grid>
    </Box>
  );
};

export default SubscriptionList;
