import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { SequenceDto, SequenceUserDto } from "api";
import React from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import "react-multi-email/dist/style.css";
import isEmailValidator from "validator/lib/isEmail";
import * as yup from "yup";
import {
  addSequenceAccess,
  removeSequenceAccess,
} from "store/slices/SequenceSlice";
import { Form, FormikProvider, useFormik } from "formik";
import AppTextField from "components/forms/AppTextField";
import { UserIcon } from "@iconicicons/react";
import { getDatePartFromString } from "utils/dateUtils";
import { showMessage } from "store/slices/commonSlice";
import AppInfoView from "components/common/AppInfoView";
import { useLocales } from "locales";

export interface SequenceDeleteDialogProps {
  sequence: SequenceDto;
  onShared: (sequenceId: number) => void;
  disabled: boolean;
}

const SequenceShareDialog = ({
  sequence,
  onShared,
  disabled,
}: SequenceDeleteDialogProps) => {
  const dispatch = useDispatch<any>();
  const { t } = useLocales();
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const [selectedUser, setSelectedUser] = React.useState(0);
  const [saving, setSaving] = React.useState(false);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t('enterValidEmail'))
      .required("En e-postadress krävs")
      .test("is-valid", t('enterValidEmail'), (value) =>
        value
          ? isEmailValidator(value)
          : new yup.ValidationError(t('enterValidEmail'))
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      console.log("User", values);
      try {
        const sequenceUser = {
          email: values.email,
          sequenceId: sequence.sequenceId,
        } as SequenceUserDto;
        const result = await dispatch(
          addSequenceAccess(sequence.sequenceId!, sequenceUser)
        );
        await dispatch(showMessage(t('shareSend')));
        resetForm();
      } catch (error) {
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClickOpen = (userId: number) => {
    setSelectedUser(userId);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleRemoveUserAccess = async () => {
    try {
      setSaving(true);
      const result = await dispatch(
        removeSequenceAccess(sequence.sequenceId!, selectedUser)
      );
      handleDeleteClose();
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Tooltip title={t("share")}>
        <span>
          <IconButton
            aria-label="share"
            disabled={disabled}
            onClick={handleClickOpen}
          >
            <ShareIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <AppInfoView />
        <DialogTitle>{t('shareSequence')}</DialogTitle>
        <DialogContent>
          <Stack>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Stack flexDirection={"row"} sx={{ mb: "10px" }}>
                  <Box sx={{ width: "100%" }}>
                    <AppTextField
                      color="primary"
                      fullWidth
                      required
                      name="email"
                      value={formik.values.email ? formik.values.email : ""}
                      title=""
                      placeholder={t('enterEmail')}
                      inputProps={{ maxLength: 50 }}
                      onChange={formik.handleChange}
                    />
                  </Box>
                  <Box sx={{ width: "70px", pl: "10px", pt: "4px" }}>
                    <Tooltip title={t('clickToShare')}>
                      <Button
                        variant="contained"
                        color="secondary"
                        aria-label="comments"
                        type="submit"
                        disabled={formik.isSubmitting}
                      >
                        {t('share')}
                      </Button>
                    </Tooltip>
                  </Box>
                </Stack>
              </Form>
            </FormikProvider>
            <Typography sx={{ fontSize: 16, fontWeight: 700, mt: "10px" }}>
              {t('peopleWithAccess')}
            </Typography>
            <Box style={{ maxHeight: 200, overflow: "auto" }}>
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                {sequence.sequenceUsers?.map((user, index) => (
                  <ListItem
                    key={index}
                    sx={{ paddingX: 0 }}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="comments"
                        onClick={() => handleDeleteClickOpen(user.id!)}
                        disabled={saving}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <UserIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.userId ? user.user?.firstName : user.email}
                      secondary={
                        user.userId
                          ? user.user?.email
                          : getDatePartFromString(user.createdAt)
                      }
                    />
                  </ListItem>
                ))}
                {sequence.sequenceUsers?.length === 0 && (
                  <Typography sx={{ fontStyle: "italic" }}>{t('notShared')}</Typography>
                )}
              </List>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('removeSharedQuestion')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDeleteClose}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRemoveUserAccess}
            autoFocus
          >
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SequenceShareDialog;
