import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import './utils/i18n'
import { store } from 'store'
import { Provider } from 'react-redux'
import { OpenAPI } from 'api';

OpenAPI.BASE = process.env.REACT_APP_SOULWISE_API_BASE ?? ''

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
