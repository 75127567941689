import * as React from 'react'
import { ReactNode } from 'react'
import { CircularProgress } from '@mui/material'

interface LoadingOverlayProps {
  loading: boolean
  children?: ReactNode
}

export const LoadingOverlay = (props: LoadingOverlayProps): JSX.Element => {
  if (!props.loading) return <>{props.children}</>

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        top: 0,
      }}
    >
      <div style={{ display: 'flex', margin: '0 auto' }}>
        <CircularProgress />
      </div>
    </div>
  )
}
