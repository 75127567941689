import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiError, UserDto, UsersService } from "api";
import { AppThunk, RootState } from "store";
import { currentUser } from "./authSlice";
import { showMessage } from "./commonSlice";
import { ApiErrorBody } from "utils/model";

export interface UserState {
  user: UserDto | null;
  loading: boolean;
  hasError: boolean;
  error?: string;
}

const initialState: UserState = {
  user: null,
  loading: false,
  hasError: false,
  error: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    update: (state, action: PayloadAction<UserDto>) => {
      state.user = action.payload;
    },
  },
});

export const { hasError, loading, update } = userSlice.actions;

export const loadUserAsync =
  (userId: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const data = await UsersService.getApiUsers1(userId);
      dispatch(update(data));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const updateUserAsync =
  (user: UserDto, successMessage: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const updatedUser = await UsersService.putApiUsersUpdateUser(user);
      dispatch(currentUser(updatedUser));
      dispatch(showMessage(successMessage));
      return true;
    } catch (e) {
      var error = e as ApiError;
      var text = error.body
        ? (error.body as ApiErrorBody).error
        : error.message;
      dispatch(hasError(text!));
    }
  };

export const hideNewVersionBannerAsync =
  (userId: number, successMessage: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const updatedUser = await UsersService.postApiUsersHideVersionBanner(
        userId
      );
      dispatch(currentUser(updatedUser));
      dispatch(showMessage(successMessage));
      return true;
    } catch (e) {
      var error = e as ApiError;
      var text = error.body
        ? (error.body as ApiErrorBody).error
        : error.message;
      dispatch(hasError(text!));
    }
  };

  export const onCreateUserImage =
  (userId: number, file: Blob, successMessage: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const updatedUser = await UsersService.putApiUsersImage(
        userId, {file}
      );
      dispatch(currentUser(updatedUser));
      dispatch(showMessage(successMessage));
      return true;
    } catch (e) {
      var error = e as ApiError;
      var text = error.body
        ? (error.body as ApiErrorBody).error
        : error.message;
      dispatch(hasError(text!));
    }
  };

  export const onDeleteUserImage =
  (userId: number, successMessage: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const updatedUser = await UsersService.deleteApiUsersImage(
        userId
      );
      dispatch(currentUser(updatedUser));
      dispatch(showMessage(successMessage));
      return true;
    } catch (e) {
      var error = e as ApiError;
      var text = error.body
        ? (error.body as ApiErrorBody).error
        : error.message;
      dispatch(hasError(text!));
    }
  };

export const userStateSelector = (state: RootState): UserState => state.user;

export default userSlice.reducer;
