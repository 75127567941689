export interface ApiErrorBody {
  error?: string;
}

export const getStatusText = (status: number) => {
  return `statusText${status}`;
};

export const levelIds = [1, 2, 3];

export function getLevelTitle(levelId: number): string {
  let level: string = "";

  switch (levelId) {
    case 1:
      level = "beginner";
      break;
    case 2:
      level = "average";
      break;
    case 3:
      level = "advanced";
      break;
  }

  return level;
}

export const yogaFormIds = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16
];

export const getYogaFormText = (yogaFormId: number): string => {
  return `yogaForm${yogaFormId}`;
};

export const mainTypesIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const getMainTypesText = (mainTypeId: number): string => {
  return `mainTypeText${mainTypeId}`;
};

export const reorder = <T>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
