import { Alert, Link as MuiLink } from "@mui/material";
import { useLocales } from "locales";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as ReactRouterLink } from "react-router-dom";
import { authStateSelector } from "store/slices/authSlice";
import { hideNewVersionBannerAsync } from "store/slices/userSlice";

const NewVersionAlert = () => {
  const dispatch = useDispatch();
  const { t } = useLocales();
  const { currentUser } = useSelector(authStateSelector);

  const onHideNewVersionAlert = async () => {
    await dispatch<any>(hideNewVersionBannerAsync(currentUser?.id!, t('savingSuccess')));
  };
  return (
    <>
      {currentUser?.showNewVersionBanner && (
        <Alert
          severity="info"
          onClose={onHideNewVersionAlert}
          sx={{ mb: "15px" }}
        >
          {t("newVersionText")}{" "}
          <MuiLink
            component={ReactRouterLink}
            to={"/versions"}
            underline="always"
          >
            {t("newVersionLink")}
          </MuiLink>
          .
        </Alert>
      )}
    </>
  );
};
export default NewVersionAlert;
