import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { useLocales } from "locales";
import { useSelector } from "react-redux";
import { sequenceFormStateSelector } from "store/slices/SequenceFormSlice";

export default function ReviewTextCard() {
  const { sequence } = useSelector(sequenceFormStateSelector);
  const {t} = useLocales();

  return (
    <Card
      elevation={0}
      sx={{
        maxWidth: "400px",
        minHeight: "314px",
        borderRadius: "10px",
      }}
    >
      <CardContent>
        {sequence ? (
          <Stack>
            <Typography sx={{ fontWeight: "bold" }}>{t('theme')}</Typography>
            <Typography
              style={{ overflowWrap: "break-word" }}
              sx={{ pb: "15px" }}
            >
              {sequence!.theme}
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>{t('script')}</Typography>
            <Typography
              style={{ overflowWrap: "break-word", whiteSpace: 'pre-line' }}
              sx={{ pb: "15px" }}
            >
              {sequence!.script}
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>{t('musicLink')}</Typography>
            <Typography
              style={{ overflowWrap: "break-word" }}
              sx={{ pb: "15px" }}
            >
              {sequence!.musicLink}
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>{t('otherInformation')}</Typography>
            <Typography
              style={{ overflowWrap: "break-word", whiteSpace: 'pre-line' }}
              sx={{ pb: "15px" }}
            >
              {sequence!.otherInformation}
            </Typography>
          </Stack>
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  );
}
