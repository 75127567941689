import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Grid,
  Button,
} from "@mui/material";
import { useLocales } from "locales";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { resetState } from "store/slices/SequenceFormSlice";

export interface SequenceSavedDialogProps {
  isDialogOpen: boolean;
}

const SequenceSavedDialog = ({ isDialogOpen }: SequenceSavedDialogProps) => {
  const dispatch = useDispatch();
  const {t} = useLocales();

  const [isSequenceSavedDialogOpen, setIsSequenceSavedDialogOpen] =
    useState(false);

  useEffect(() => {
    setIsSequenceSavedDialogOpen(isDialogOpen);
  }, [isDialogOpen]);

  const handleSequenceSavedDialogClose = () => {
    setIsSequenceSavedDialogOpen(false);
    dispatch(resetState());
  };
  return (
    <Dialog
      open={isSequenceSavedDialogOpen}
      onClose={() => handleSequenceSavedDialogClose()}
      maxWidth={"xs"}
    >
      <DialogTitle>{"Klar!"}</DialogTitle>
      <DialogContentText>
        <DialogContentText id="alert-dialog-description">
          {t('sequenceSaved')}
        </DialogContentText>
      </DialogContentText>
      <DialogContent>
        <DialogContentText>
          {t('shareSequenceInfo')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
            <Button
              sx={{
                width: "208px",
                height: "56px",
                borderRadius: "46px",
                justifyContent: "center",
                mb: "15px",
              }}
              component={Link}
              to={"/"}
              variant="contained"
              color="primary"
              onClick={() => handleSequenceSavedDialogClose()}
            >
              {t('okClose')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default SequenceSavedDialog;
