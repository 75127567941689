import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { IFaq } from "utils/contentfulModel";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import RichTextField from "components/forms/RichTextField";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "15px",
  backgroundColor: "#FFF",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

interface FaqAccordionProps {
  item: IFaq;
}

const FaqAccordation = ({ item }: FaqAccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion expanded={expanded} onChange={handleChange} sx={{ mb: "7px" }}>
      <AccordionSummary expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}>
        <Typography variant="h6" component={"h3"}>
          {item.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0, pb: "22px" }}>
        <RichTextField document={item.answer.json}/>
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqAccordation;
