import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IInspiration, IInspirationItem } from "utils/contentfulModel";
import { StdView } from "views/OtherViews/StdView";
import { InspirationInfoAccordion } from "./InspirationInfoAccordion";
import ContentfulService from "utils/ContentfulService";
import RichTextField from "components/forms/RichTextField";
import { ElectricalServicesOutlined } from "@mui/icons-material";
import { useLocales } from "locales";

const InspirationView = () => {
  let params = useParams();
  const { t, currentLang } = useLocales();

  const [inspiration, setInspiration] = useState<IInspiration | null>(null);
  const [inspirationItems, setInspirationItems] = useState<IInspirationItem[]>(
    []
  );

  const getInspiration = (id: string, locale: string) => {
    ContentfulService.post("/", {
      query: `query{inspiration(id:"${id}" locale:\"${locale}\"){title subTitle introduction{json links{assets{block{sys {id} url width height}}}} sectionsCollection{items{sys{id}}}}}`,
    })
      .then((data) => {
        //console.log(data);
        const inspirationData = data.data.data.inspiration as IInspiration;
        setInspiration(inspirationData);

        const ids = inspirationData.sectionsCollection.items.map((item) => {
          return item.sys.id;
        });
        //console.log(ids);
        getInspirationItems(ids!, locale);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getInspirationItems = (ids: string[], locale: string) => {
    ContentfulService.post("/", {
      query: `query ($itemIds: [String]) {inspirationItemCollection(where: {sys:{id_in: $itemIds}} limit:10 locale:\"${locale}\"){items{sys {id} title text{json links{assets{block{sys {id} url width height}}} }}}}`,
      variables: { itemIds: ids },
    })
      .then((data) => {
        //console.log(data);
        setInspirationItems(
          data.data.data.inspirationItemCollection.items as IInspirationItem[]
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getInspiration(params.id!, currentLang.value);
  }, [params, currentLang]);

  return (
    <StdView title={inspiration?.title} nosubscription>
      <Stack sx={{ pt: "1px" }} spacing={2}>
        <RichTextField
          document={inspiration?.introduction.json!}
          links={inspiration?.introduction.links}
        />

        <Box>
          {inspiration?.sectionsCollection.items.map((data, index) => {
            const inspirationData = inspirationItems.find(
              (item) => item.sys.id === data.sys.id
            );
            if (inspirationData)
              return (
                <InspirationInfoAccordion data={inspirationData!} key={index} />
              );
            else return <p />;
          })}
        </Box>
      </Stack>
    </StdView>
  );
};

export default InspirationView;
