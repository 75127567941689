import * as React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Typography, styled } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useState } from "react";
import RichTextField from "components/forms/RichTextField";
import { IInspirationItem } from "utils/contentfulModel";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "15px",
  backgroundColor: "#FFF",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

interface InspirationInfoAccordionProps {
  data: IInspirationItem;
}

export const InspirationInfoAccordion = ({
  data,
}: InspirationInfoAccordionProps): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange} sx={{ mb: "7px" }}>
      <AccordionSummary
        sx={{ mb: 0 }}
        expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
      >
        <Typography variant="h3" component={"h3"}>
          {data.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0, pb: "22px" }}>
        <RichTextField document={data.text.json} links={data.text.links} />
      </AccordionDetails>
    </Accordion>
  );
};
