import { Grid } from "@mui/material";
import InspirationCard from "./InsprationCard";
import { useEffect } from "react";
import { StdView } from "views/OtherViews/StdView";

import { useLocales } from "locales";
import {
  contentfulStateSelector,
  getInspirationList,
} from "store/slices/contentfulSlice";
import { useDispatch, useSelector } from "react-redux";

export default function InspirationListView() {
  const dispatch = useDispatch<any>();
  const { t, currentLang } = useLocales();

  const { inspirationList } = useSelector(contentfulStateSelector);

  useEffect(() => {
    dispatch(getInspirationList(currentLang.value));
  }, [currentLang]);

  return (
    <StdView title={t("InspirationTitle")}>
      <Grid container spacing={2} sx={{ border: 0 }}>
        {inspirationList?.itemsCollection.items.map((inspiration, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            lg={4}
            sx={{
              maxWidth: {
                xs: "100%",
              },
            }}
          >
            <InspirationCard data={inspiration} key={index} />
          </Grid>
        ))}
      </Grid>
    </StdView>
  );
}
