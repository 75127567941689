import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  FormControl,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { FieldHookConfig, useField } from "formik";
import TextField from "@mui/material/TextField";
import { TextFieldProps } from "@mui/material/TextField/TextField";

const PasswordField = (props: TextFieldProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <FormControl sx={{ my: 1 }} fullWidth variant="outlined">
      <TextField
        {...field}
        {...props}
        value={field.value ? field.value : ""}
        error={!!errorText}
        helperText={errorText}
        type={showPassword ? "text" : "password"}
        InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
      />
    </FormControl>
  );
};

export default PasswordField;
