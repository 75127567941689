import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
} from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";
import SelfImprovementOutlinedIcon from "@mui/icons-material/SelfImprovementOutlined";
import Iconify from "components/iconify";
import { useResponsive } from "hooks/use-responsive";
import LanguagePopover from "components/language/language-popover";
import { useLocales } from "locales";

const drawerWidth = 240;

const MainHeader = () => {
  const navigate = useNavigate();
  const {t} = useLocales();
  const smUp = useResponsive("up", "sm");

  const pages = [
    {
      title: t("sequenceTitle"),
      url: "sequences",
      icon: (
        <Iconify
          icon="fluent:text-bullet-list-square-shield-20-filled"
          width={24}
        />
      ),
    },
    {
      title: t("positionTitle"),
      url: "positions",
      icon: <SelfImprovementOutlinedIcon />,
    },
    {
      title: t("InspirationTitle"),
      url: "inspiration",
      icon: <Iconify icon="material-symbols:mindfulness" width={24} />,
    },
  ];
  
  const mypages = [
    {
      title: t("mySequenceTitle"),
      url: "mysequences",
      icon: (
        <Iconify
          icon="fluent:text-bullet-list-square-person-20-filled"
          width={24}
        />
      ),
    },
  ];

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ p: "10px" }}>
        <Button component={Link} to={"/"} sx={{ p: 0 }}>
          <img
            src="/images/soulwiseWrapperLogo.png"
            width={120}
            alt="Soulwise"
          />
        </Button>
      </Box>

      <Divider />
      <List
        sx={{
          "& .navItem": {
            textDecoration: "none",
            width: "auto",
            cursor: "pointer",
            color: "#000",

            "&.active": {
              color: "#000",
              textDecoration: "underline",
              textUnderlineOffset: "10px",
            },
          },
        }}
      >
        {pages.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => navigate(item.url)}
              sx={{ textAlign: "left" }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <NavLink
                    className="navItem"
                    onClick={() => navigate(item.url)}
                    to={item.url}
                  >
                    {item.title}
                  </NavLink>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider
          orientation="horizontal"
          variant="middle"
          sx={{ color: "#fff" }}
        />
        {mypages.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => navigate(item.url)}
              sx={{ textAlign: "left" }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <NavLink
                    className="navItem"
                    onClick={() => navigate(item.url)}
                    to={item.url}
                  >
                    {item.title}
                  </NavLink>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const renderMobile = (
    <Toolbar
      disableGutters
      sx={{
        boxSizing: "border-box",
        minHeight: { sm: 80, md: 80 },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mx: 1, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display:"flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <NavLink to={"/"}>
          <img src="/images/text_vit.png" width={110} alt="Soulwise" />
        </NavLink>
      </Box>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <LanguagePopover  />
        <ProfileMenu />
      </Stack>
    </Toolbar>
  );

  const renderNormal = (
    <Toolbar
      disableGutters
      sx={{
        boxSizing: "border-box",
        minHeight: { sm: 80, md: 80 },
      }}
    >
      <Box sx={{ mr: "40px" }}>
        <NavLink to={"/"}>
          <img src="/images/text_vit.png" width={110} alt="Soulwise" />
        </NavLink>
      </Box>

      <Stack
        direction={"row"}
        sx={{
          flexGrow: 1,
          "& .navItem": {
            textDecoration: "none",
            pr: "20px",
            color: "#fff",
            fontFamily: "Marcellus",
            fontSize: 18,
            "&.active": {
              textDecoration: "underline",
              textUnderlineOffset: "10px",
            },
          },
        }}
      >
        {pages.map((page, index) => (
          <NavLink to={page.url} key={index} className={"navItem"}>
            {page.title}
          </NavLink>
        ))}
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{ color: "#fff" }}
        />
        {mypages.map((page, index) => (
          <NavLink to={page.url} key={index} className={"navItem"}>
            {page.title}
          </NavLink>
        ))}
      </Stack>
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <LanguagePopover color="white" />
        <ProfileMenu />
      </Stack>

    </Toolbar>
  );

  return (
    <>
      <AppBar color="primary">
        <Container
          maxWidth="lg"
          disableGutters
          sx={{
            "&.MuiContainer-root": { px: { xs: "0px", sm: "10px", lg: "0px" } },
          }}
        >
          {smUp && renderNormal}
          {!smUp && renderMobile}
        </Container>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#fff",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Toolbar
        sx={{
          boxSizing: "border-box",
          minHeight: { sm: 80, md: 80 },
        }}
      />
    </>
  );
};

export default MainHeader;
