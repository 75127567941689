import {
  Badge,
  BadgeProps,
  Drawer,
  IconButton,
  SxProps,
  styled,
} from "@mui/material";
import { SequenceDto } from "api";
import Iconify from "components/iconify";
import React, { useState } from "react";
import { OnDragEndResponder } from "react-beautiful-dnd";
import MySequenceListView from "views/CreateSequenceViews/MySequenceListView";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 1,
    top: 4,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 3px",
    fontSize: 9,
    height: 16,
    minWidth: 16,
  },
}));

export interface MySequenceButtonProps {
  item: SequenceDto;
  onDragEnd: OnDragEndResponder;
  sx?: SxProps;
  onToogleFlip: (id: number) => void;
}

const MySequenceButton = ({ item, onDragEnd, sx, onToogleFlip }: MySequenceButtonProps) => {
  const [open, setOpen] = useState(false);
  
  function handleClose(): void {
    setOpen(false);
  }

  const hasSequenceItems = (): boolean => {
    const hasItems: number = item.sequenceItems ? item.sequenceItems?.length! : 0;
    if (hasItems > 0) {
      return true;
    } else return false;
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)} sx={{ ...sx }}>
        {hasSequenceItems() ? (
          <StyledBadge badgeContent={item.sequenceItems?.length!} color="secondary">
            <Iconify
              icon="fa-solid:list-ol"
              color={(theme) => theme.palette.primary.main}
              width={24}
            />
          </StyledBadge>
        ) : (
          <Iconify icon="fa-solid:list-ol" width={24} />
        )}
      </IconButton>
      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            width: '100%',
            backgroundColor: "#F5F4F0",
          },
        }}
      >
        <MySequenceListView
          sequence={item!}
          onDragEnd={onDragEnd}
          handleClose={handleClose}
          onToogleFlip={onToogleFlip}
        />
      </Drawer>
    </>
  );
};

export default MySequenceButton;
