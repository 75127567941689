import merge from 'lodash/merge';
import { enUS as enUSAdapter, sv as svSEAdapter } from 'date-fns/locale';
// core
import { enUS as enUSCore, svSE as svSECore } from '@mui/material/locale';
// date-pickers
import { enUS as enUSDate, svSE as svSEDate } from '@mui/x-date-pickers/locales';


// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Svenska',
    value: 'sv',
    systemValue: merge(svSEDate, svSECore),
    adapterLocale: svSEAdapter,
    icon: 'flagpack:se',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
];

export const defaultLang = allLangs[0]; // Swedish

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
