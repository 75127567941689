import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/index";
import {
  AccountService,
  UsersService,
  OpenAPI,
  UserDto,
  Tokens,
  ApiError,
  RegisterRequest,
  PaymentService,
  SubscriptionDto,
} from "api";
import { ApiErrorBody } from "utils/model";
import { showMessage } from "./commonSlice";

interface Session {
  organisationId?: number;
}

export interface AuthState {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  tokens: Tokens | null;
  hasError: boolean;
  hasRegError: boolean;
  error?: string;
  regError?: string;
  currentUser: UserDto | null;
  session: Session;
}

function writeState(tokens: Tokens) {
  sessionStorage.setItem("tokens", JSON.stringify(tokens));
}

function deleteState() {
  sessionStorage.removeItem("tokens");
}

const createInitialState = (): Tokens | null => {
  if (sessionStorage.getItem("tokens") == null) {
    return null;
  } else {
    return JSON.parse(sessionStorage.getItem("tokens") as string) as Tokens;
  }
};

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  tokens: createInitialState(),
  hasError: false,
  error: "",
  regError: "",
  currentUser: null,
  session: {} as Session,
} as AuthState;

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    authenticating: (state) => {
      state.isAuthenticating = true;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
      state.isAuthenticating = false;
    },
    hasRegError: (state, action: PayloadAction<string>) => {
      state.hasRegError = true;
      state.regError = `${action.payload}`;
      state.isAuthenticating = false;
    },
    authenticated: (state, action: PayloadAction<Tokens>) => {
      state.tokens = action.payload;
      writeState(action.payload as Tokens);
    },
    currentUser: (state, action: PayloadAction<UserDto>) => {
      state.isAuthenticating = false;
      state.isAuthenticated = true;
      state.hasError = false;
      state.error = "";
      state.currentUser = action.payload;
    },
    sessionOrganisation: (state, action: PayloadAction<number>) => {
      state.session.organisationId = action.payload;
    },
    logout: (state) => {
      state.hasError = false;
      state.isAuthenticating = false;
      state.isAuthenticated = false;
      state.tokens = null;
      state.currentUser = null;
      OpenAPI.TOKEN = "";
      deleteState();
    },
    isNotAuthenticating: (state) => {
      state.isAuthenticating = false;
      state.isAuthenticated = true;
      state.hasError = false;
      state.hasRegError = false;
      state.error = "";
      state.regError = "";
    },
    clearError: (state) => {
      state.hasError = false;
      state.hasRegError = false;
      state.error = "";
      state.regError = "";
    },
    addSubscription: (state, action: PayloadAction<SubscriptionDto>) => {
      state.currentUser?.subscriptions?.push(action.payload);
    },
  },
});

export const {
  logout,
  authenticating,
  currentUser,
  authenticated,
  hasError,
  hasRegError,
  sessionOrganisation,
  isNotAuthenticating,
  clearError,
  addSubscription,
} = authSlice.actions;

/**
 * Login async
 * @param username
 * @param password
 */
export const loginAsync =
  (username: string, password: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authenticating());
      dispatch(clearError());
      const tokenResponse = await AccountService.postApiAccountLogin(
        username,
        password
      );
      OpenAPI.TOKEN = tokenResponse.token ?? "";
      dispatch(authenticated(tokenResponse));
      dispatch(getMeAsync());
      dispatch(isNotAuthenticating());
    } catch (e) {
      var error = e as ApiError;
      var text = error.body
        ? (error.body as ApiErrorBody).error
        : error.message;
      dispatch(hasError(text!));
    }
  };

export const registerAsync =
  (request: RegisterRequest): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(authenticating());
      dispatch(clearError());
      await AccountService.postApiAccountRegister(request);
      const tokenResponse = await AccountService.postApiAccountLogin(
        request.email,
        request.password
      );
      OpenAPI.TOKEN = tokenResponse.token ?? "";
      dispatch(authenticated(tokenResponse));
      dispatch(getMeAsync());
      dispatch(isNotAuthenticating());

      return true;
    } catch (e) {
      var error = e as ApiError;
      var text = error.body
        ? (error.body as ApiErrorBody).error
        : error.message;
      dispatch(hasRegError(text!));
    }
  };

export const getMeAsync = (): AppThunk => async (dispatch, getState) => {
  try {
    OpenAPI.TOKEN = getState().auth.tokens?.token ?? "";
    const personResponse = await AccountService.getApiAccountCurrent();
    dispatch(currentUser(personResponse));
  } catch (e) {
    dispatch(logout());
  }
};

export const updateEmailAsync =
  (currentEmail: string, newEmail: string, successMessage: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(clearError());
      OpenAPI.TOKEN = getState().auth.tokens?.token ?? "";
      const updatedUser = await UsersService.putApiUsersUpdateEmail(
        currentEmail,
        newEmail
      );
      dispatch(currentUser(updatedUser));
      dispatch(showMessage(successMessage));
      return true;
    } catch (e) {
      var error = e as ApiError;
      var text = error.body
        ? (error.body as ApiErrorBody).error
        : error.message;
      dispatch(hasError(text!));
    }
  };

export const updatePasswordAsync =
  (currentPassword: string, newPassword: string, successMessage: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(clearError());
      OpenAPI.TOKEN = getState().auth.tokens?.token ?? "";
      const updatedUser = await UsersService.putApiUsersUpdatePassword(
        currentPassword,
        newPassword
      );
      dispatch(currentUser(updatedUser));
      dispatch(showMessage(successMessage));
      return true;
    } catch (e) {
      var error = e as ApiError;
      var text = error.body
        ? (error.body as ApiErrorBody).error
        : error.message;
      dispatch(hasError(text!));
    }
  };

export const onForgotPassword =
  (email: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearError());
      return await AccountService.postApiAccountForgotPassword({
        email: email,
      });
    } catch (e) {
      var error = e as ApiError;
      var text = error.body
        ? (error.body as ApiErrorBody).error
        : error.message;
      dispatch(hasError(text!));
    }
  };

export const onResetPassword =
  (email: string, password: string, token: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearError());
      return await AccountService.postApiAccountResetPassword({
        email,
        password,
        token,
      });
    } catch (e) {
      var error = e as ApiError;
      var text = error.body
        ? (error.body as ApiErrorBody).error
        : error.message;
      dispatch(hasError(text!));
    }
  };

export const authStateSelector = (state: RootState): AuthState => state.auth;

export default authSlice.reducer;
