import dayjs from "dayjs";
import * as _ from "lodash";
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import weekOfYearPlugin from "dayjs/plugin/weekOfYear";
import localeDataPlugin from "dayjs/plugin/localeData";
import "dayjs/locale/sv";
import i18next from "i18next";

dayjs.locale("sv");

dayjs.extend(localeDataPlugin);
dayjs.extend(weekdayPlugin);
dayjs.extend(objectPlugin);
dayjs.extend(isTodayPlugin);
dayjs.extend(weekOfYearPlugin);

export interface DayDateObject {
  week: string;
  display: string;
  weekDay: number;
  startStamp: number;
  endStamp: number;
  day: number;
  month: number;
  year: number;
  isCurrentMonth: boolean;
  isCurrentWeek: boolean;
  isCurrentDay: boolean;
}

const getDateObject = (date: dayjs.Dayjs): DayDateObject => {
  const clonedObject = { ...date.toObject() };
  return {
    week: i18next.t("EventsResources.WeekAbbreviatedX", {
      0: date.locale("sv").week().toString(),
    }),
    display: date.format("ddd D/M"),
    weekDay: date.locale("sv").weekday(),
    startStamp: date.unix(),
    endStamp: date.endOf("day").unix(),
    day: clonedObject.date,
    month: clonedObject.months,
    year: clonedObject.years,
    isCurrentMonth: clonedObject.months === dayjs().month(),
    isCurrentWeek: date.week() === dayjs().week(),
    isCurrentDay: date.isToday(),
  } as DayDateObject;
};



export const getCurrentTimeString = (date: Date, time: number): string => {
  date.setSeconds(time / 10);

  if (date.toString() === "Invalid Date") {
    return "";
  }
  return date.toLocaleTimeString();
};

export const getCurrentTime = (date: Date, time: number): Date | null => {
  var onlyDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0
  )
  onlyDate.setSeconds(time / 10);

  if (onlyDate.toString() === "Invalid Date") {
    return null;
  }
  return onlyDate;
};

export const getDatePartFromString = (dateString?: string): string | null => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  if (date.toString() === "Invalid Date") {
    return null;
  }
  return date.toLocaleDateString();
};

export const getOnlyDateFromString = (dateString?: string): Date | null => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  if (date.toString() === "Invalid Date") {
    return null;
  }

  var onlyDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0
  )

  return onlyDate;
};

export const getDateFromString = (dateString?: string | undefined): Date | null => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  if (date.toString() === "Invalid Date") {
    return null;
  }
  return date;
};

export const getTimeFromString = (dateString?: string): string | null => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  if (date.toString() === "Invalid Date") {
    return null;
  }
  return date.toLocaleTimeString();
};

export const getStringFromDate = (date: Date): string | null => {
  if (!date) {
    return null;
  }
  if (date.toString() === "Invalid Date") {
    return null;
  }
  return new Date(date).toISOString();
};

export const toStringFromDate = (date: Date): string | null => {
  if (!date) {
    return null;
  }
  if (date.toString() === "Invalid Date") {
    return null;
  }

  return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay() + "T00:00:00.000Z" 
};

export const isDateStringToday = (dateString?: string): Boolean => {
  if (!dateString) {
    return false;
  }
  const date = new Date(dateString);
  if (date.toString() === "Invalid Date") {
    return false;
  }

  if (date.getDate() === new Date().getDate()) return true;
  else return false;
};

const EVENT_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  weekday: "short",
  day: "numeric",
  month: "short",
  year: "numeric",
};

export const getDateRangeString = (
  startDate: Date | null,
  finishDate: Date | null
): string | null => {
  const startFormat = { ...EVENT_DATE_FORMAT };
  const finishFormat = { ...EVENT_DATE_FORMAT };

  let startDateString =
    startDate?.toLocaleString(undefined, startFormat) ?? null;
  const finishDateString =
    finishDate?.toLocaleString(undefined, finishFormat) ?? null;
  if (
    startDateString &&
    finishDateString &&
    startDateString !== finishDateString
  ) {
    if (startDate?.getFullYear() === finishDate?.getFullYear()) {
      delete startFormat.year;
      startDateString =
        startDate?.toLocaleString(undefined, startFormat) ?? null;
    }
    return `${startDateString} - ${finishDateString}`;
  }
  return startDateString ?? finishDateString ?? null;
};
