import {
  Avatar,
  Box,
  Button,
  Link,
  ListItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useBoolean } from "api/hooks/use-boolean";
import Iconify from "components/iconify";
import React, { useState } from "react";
import { fDate } from "types/FormatTime";
import SequenceCommentForm from "./SequenceCommentForm";
import { useSelector } from "react-redux";
import { authStateSelector } from "store/slices/authSlice";
import SequenceEditCommentForm from "./SequenceEditCommentForm";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useLocales } from "locales";

type SequenceCommentItemProps = {
  id?: number;
  sequenceId: number;
  name: string;
  avatarUrl: string;
  message: string;
  tagUser?: string;
  postedAt: Date;
  hasReply?: boolean;
  userId: number;
};

const SequenceCommentItem = ({
  id,
  sequenceId,
  name,
  avatarUrl,
  message,
  tagUser,
  postedAt,
  hasReply,
  userId,
}: SequenceCommentItemProps) => {
  const reply = useBoolean();
  const edit = useBoolean();
  const {t} = useLocales();
  const { currentUser } = useSelector(authStateSelector);

  function onSave(): void {
    reply.onToggle();
  }

  function onCancelEdit(): void {
    edit.onToggle();
  }

  function onSaveEdit(): void {
    edit.onToggle();
  }

  return (
    <ListItem
      sx={{
        p: 0,
        pt: 1,
        alignItems: "flex-start",
        ...(hasReply && {
          pl: 6,
        }),
      }}
    >
      <Avatar
        alt={name}
        color={"secondary"}
        src={avatarUrl}
        sx={{ mr: 2, width: 24, height: 24 }}
      />

      <Stack
        flexGrow={1}
        sx={{
          pb: 1,
          maxWidth: "88%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#ffffff",
            p: "7px",
            borderRadius: "10px",
          }}
        >
          <RouterLink
            to={"/user/profile/" + userId}
            style={{ textDecoration: "none" }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#000",
              }}
            >
              {name}
            </Typography>
          </RouterLink>
          {!edit.value && (
            <Typography
              variant="body2"
              sx={{ mt: 1, wordWrap: "break-word", overflowWrap: "break-word" }}
            >
              {tagUser && (
                <Box component="strong" sx={{ mr: 0.5 }}>
                  @{tagUser}
                </Box>
              )}
              {message}
            </Typography>
          )}
          {edit.value && (
            <SequenceEditCommentForm
              commentId={id!}
              comment={message}
              onCancel={onCancelEdit}
              onSave={onSaveEdit}
            />
          )}
        </Box>
        <Stack direction={"row"} spacing={1}>
          <Tooltip title={fDate(postedAt, "p")}>
            <Typography variant="caption" sx={{ color: "text.disabled" }}>
              {fDate(postedAt)}
            </Typography>
          </Tooltip>

          {!hasReply && (
            <Link
              sx={{ color: "#000000", fontWeight: 700, cursor: "pointer" }}
              variant="caption"
              underline="hover"
              onClick={reply.onToggle}
            >
              {t("reply")}
            </Link>
          )}

          {userId == currentUser?.id && (
            <Link
              sx={{ color: "#000000", fontWeight: 700, cursor: "pointer" }}
              variant="caption"
              underline="hover"
              onClick={edit.onToggle}
            >
              {t("edit")}
            </Link>
          )}
        </Stack>
        {reply.value && (
          <Box sx={{ mt: 2 }}>
            <SequenceCommentForm
              sequenceId={sequenceId}
              parentId={id}
              onSave={onSave}
            />
          </Box>
        )}
      </Stack>
    </ListItem>
  );
};

export default SequenceCommentItem;
