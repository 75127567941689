import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  SequenceCommentDto,
  SequenceDto,
  SequenceImageDto,
  SequenceLikeDto,
  SequenceService,
  SequenceUserDto,
} from "api";
import { AppThunk, RootState } from "store";

interface SequenceLikesPayload {
  sequenceId: number;
  data: SequenceLikeDto[];
}

interface SequenceCommentsPayload {
  sequenceId: number;
  data: SequenceCommentDto[];
}

export interface SequenceState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  sequences: SequenceDto[];
  mySequences: SequenceDto[];
  sharedSequences: SequenceDto[];
  images: SequenceImageDto[];
  saving: boolean;
}

const initialState: SequenceState = {
  loading: false,
  hasError: false,
  error: "",
  sequences: [],
  mySequences: [],
  sharedSequences: [],
  images: [],
  saving: false,
};

export const sequenceSlice = createSlice({
  name: "sequence",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    saving: (state, action: PayloadAction<boolean>) => {
      state.hasError = !action.payload;
      state.saving = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.loading = false;
      state.error = `${action.payload}`;
    },
    setAllSequences: (state, action: PayloadAction<SequenceDto[]>) => {
      state.sequences = action.payload;
    },
    setMySequences: (state, action: PayloadAction<SequenceDto[]>) => {
      state.mySequences = action.payload;
    },
    setSharedSequences: (state, action: PayloadAction<SequenceDto[]>) => {
      state.sharedSequences = action.payload;
    },
    setImages: (state, action: PayloadAction<SequenceImageDto[]>) => {
      state.images = action.payload;
    },
    resetState: (state) => {
      state.loading = false;
      state.hasError = false;
      state.error = "";
      state.sequences = [];
      state.mySequences = [];
      state.sharedSequences = [];
      state.images = [];
    },
    removeSequence: (state, action: PayloadAction<number>) => {
      const indexSequences = state.sequences!.findIndex(
        (c) => c.sequenceId === action.payload
      );
      if (indexSequences! > -1) {
        state.sequences!.splice(indexSequences!, 1);
      }

      const indexMySequences = state.mySequences!.findIndex(
        (c) => c.sequenceId === action.payload
      );
      if (indexMySequences! > -1) {
        state.mySequences!.splice(indexMySequences!, 1);
      }
    },
    updateSequence: (state, action: PayloadAction<SequenceDto>) => {
      state.sequences! = state.sequences?.map((sequence) =>
        sequence.sequenceId === action.payload.sequenceId
          ? action.payload
          : sequence
      );
      state.mySequences! = state.mySequences?.map((sequence) =>
        sequence.sequenceId === action.payload.sequenceId
          ? action.payload
          : sequence
      );
    },
    addSequence(state, action: PayloadAction<SequenceDto>) {
      if (
        !state.sequences?.some(
          (p) => p.sequenceId === action.payload.sequenceId
        )
      )
        state.sequences?.push(action.payload);

      if (
        !state.mySequences?.some(
          (p) => p.sequenceId === action.payload.sequenceId
        )
      )
        state.mySequences?.push(action.payload);
    },
    updateSequenceLikes: (
      state,
      action: PayloadAction<SequenceLikesPayload>
    ) => {
      state.sequences! = state.sequences?.map((sequence) => {
        if (sequence.sequenceId === action.payload.sequenceId) {
          sequence.likes = action.payload.data;
          return sequence;
        } else {
          return sequence;
        }
      });

      state.mySequences! = state.mySequences?.map((sequence) => {
        if (sequence.sequenceId === action.payload.sequenceId) {
          sequence.likes = action.payload.data;
          return sequence;
        } else {
          return sequence;
        }
      });
    },
    setSequenceComments: (
      state,
      action: PayloadAction<SequenceCommentsPayload>
    ) => {
      state.sequences! = state.sequences?.map((sequence) => {
        if (sequence.sequenceId === action.payload.sequenceId) {
          sequence.comments = action.payload.data;
          return sequence;
        } else {
          return sequence;
        }
      });

      state.mySequences! = state.mySequences?.map((sequence) => {
        if (sequence.sequenceId === action.payload.sequenceId) {
          sequence.comments = action.payload.data;
          return sequence;
        } else {
          return sequence;
        }
      });
    },
    updateSequenceComments: (
      state,
      action: PayloadAction<SequenceCommentDto>
    ) => {
      state.sequences! = state.sequences?.map((sequence) => {
        if (sequence.sequenceId === action.payload.sequenceId) {
          sequence.comments = sequence.comments?.map((comment) => {
            if (comment.id === action.payload.id) {
              return action.payload;
            } else {
              return comment;
            }
          });

          return sequence;
        } else {
          return sequence;
        }
      });

      state.mySequences! = state.mySequences?.map((sequence) => {
        if (sequence.sequenceId === action.payload.sequenceId) {
          sequence.comments = sequence.comments?.map((comment) => {
            if (comment.id === action.payload.id) {
              return action.payload;
            } else {
              return comment;
            }
          });

          return sequence;
        } else {
          return sequence;
        }
      });
    },
    addSequenceUser(state, action: PayloadAction<SequenceUserDto>) {
      
      state.sequences = state.sequences?.map((sequence) => {
          if(sequence.sequenceId === action.payload.sequenceId)
            {
              const updatedSequence = {...sequence}
              updatedSequence.sequenceUsers?.push(action.payload)
              return updatedSequence;
            }
            else return sequence;
        })

        state.mySequences = state.mySequences?.map((sequence) => {
          if(sequence.sequenceId === action.payload.sequenceId)
            {
              const updatedSequence = {...sequence}
              updatedSequence.sequenceUsers?.push(action.payload)
              return updatedSequence;
            }
            else return sequence;
        })

      
    },
    removeSequenceUser(state, action: PayloadAction<{sequenceId: number, sequenceUserId: number}>) {
      
      state.sequences = state.sequences?.map((sequence) => {
        if(sequence.sequenceId === action.payload.sequenceId)
          {
            const updatedSequence = {...sequence}
            updatedSequence.sequenceUsers = updatedSequence.sequenceUsers?.filter((s) => s.id !== action.payload.sequenceUserId)
            return updatedSequence;
          }
          else return sequence;
      })
      state.mySequences = state.mySequences?.map((sequence) => {
        if(sequence.sequenceId === action.payload.sequenceId)
          {
            const updatedSequence = {...sequence}
            updatedSequence.sequenceUsers = updatedSequence.sequenceUsers?.filter((s) => s.id !== action.payload.sequenceUserId)
            return updatedSequence;
          }
          else return sequence;
      })
    
  },
  },
});

export const {
  hasError,
  loading,
  saving,
  setAllSequences,
  setMySequences,
  setImages,
  removeSequence,
  updateSequence,
  addSequence,
  resetState,
  updateSequenceLikes,
  setSequenceComments,
  updateSequenceComments,
  addSequenceUser,
  removeSequenceUser,
  setSharedSequences
} = sequenceSlice.actions;

export const loadAllSequencesAsync =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const data = await SequenceService.getApiSequences();
      dispatch(setAllSequences(data));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const loadMySequencesAsync =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const mySequences = await SequenceService.getApiSequencesMy();
      dispatch(setMySequences(mySequences));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

  export const loadSharedSequencesAsync =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const sequences = await SequenceService.getApiSequencesShared();
      dispatch(setSharedSequences(sequences));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const loadSequenceImagesAsync =
  (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const data = await SequenceService.getApiSequencesImages();
      dispatch(setImages(data));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const onLikeSequenceAsync =
  (sequenceId: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      const data = await SequenceService.putApiSequencesLikeSequence(
        sequenceId
      );
      dispatch(updateSequenceLikes({ sequenceId, data }));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const onAddCommentAsync =
  (item: SequenceCommentDto): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(saving(true));
      const data = await SequenceService.postApiSequencesCreateComment(item);
      dispatch(setSequenceComments({ sequenceId: item.sequenceId!, data }));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
    dispatch(saving(false));
  };

export const onUpdateCommentAsync =
  (item: SequenceCommentDto): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(saving(true));
      const updatedData = await SequenceService.putApiSequencesUpdateComment(
        item
      );
      dispatch(updateSequenceComments(updatedData));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
    dispatch(saving(false));
  };

  export const addSequenceAccess =
  (id: number, item: SequenceUserDto): AppThunk =>
  async (dispatch, getState) => {
    try {
      
      const data = await SequenceService.postApiSequencesAddAccess(id, item);
      dispatch(addSequenceUser(data));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
    
  };

  export const removeSequenceAccess =
  (id: number, sequenceUserId: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      
      const data = await SequenceService.deleteApiSequencesRemoveAccess(id, sequenceUserId);
      dispatch(removeSequenceUser({sequenceId:id, sequenceUserId}));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
    return false
  };

export const sequenceStateSelector = (state: RootState): SequenceState =>
  state.sequence;

export default sequenceSlice.reducer;
