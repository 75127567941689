/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSequenceRequest } from '../models/CreateSequenceRequest';
import type { SequenceCommentDto } from '../models/SequenceCommentDto';
import type { SequenceDto } from '../models/SequenceDto';
import type { SequenceImageDto } from '../models/SequenceImageDto';
import type { SequenceLikeDto } from '../models/SequenceLikeDto';
import type { SequenceUserDto } from '../models/SequenceUserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SequenceService {

    /**
     * @returns SequenceDto Success
     * @throws ApiError
     */
    public static getApiSequences(): CancelablePromise<Array<SequenceDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sequences',
        });
    }

    /**
     * @param id 
     * @returns SequenceDto Success
     * @throws ApiError
     */
    public static getApiSequences1(
id: number,
): CancelablePromise<SequenceDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sequences/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SequenceDto Success
     * @throws ApiError
     */
    public static getApiSequencesMy(): CancelablePromise<Array<SequenceDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sequences/my',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns SequenceDto Success
     * @throws ApiError
     */
    public static getApiSequencesShared(): CancelablePromise<Array<SequenceDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sequences/shared',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns SequenceImageDto Success
     * @throws ApiError
     */
    public static getApiSequencesImages(): CancelablePromise<Array<SequenceImageDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sequences/images',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SequenceDto Success
     * @throws ApiError
     */
    public static postApiSequencesCreateUserSequence(
requestBody?: CreateSequenceRequest,
): CancelablePromise<SequenceDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sequences/createUserSequence',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SequenceDto Success
     * @throws ApiError
     */
    public static putApiSequencesUpdateUserSequence(
requestBody?: SequenceDto,
): CancelablePromise<SequenceDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/sequences/updateUserSequence',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param sequenceId 
     * @returns number Success
     * @throws ApiError
     */
    public static deleteApiSequences(
sequenceId: number,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/sequences/{sequenceId}',
            path: {
                'sequenceId': sequenceId,
            },
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param sequenceId 
     * @returns SequenceLikeDto Success
     * @throws ApiError
     */
    public static putApiSequencesLikeSequence(
sequenceId?: number,
): CancelablePromise<Array<SequenceLikeDto>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/sequences/likeSequence',
            query: {
                'sequenceId': sequenceId,
            },
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SequenceCommentDto Success
     * @throws ApiError
     */
    public static postApiSequencesCreateComment(
requestBody?: SequenceCommentDto,
): CancelablePromise<Array<SequenceCommentDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sequences/createComment',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SequenceCommentDto Success
     * @throws ApiError
     */
    public static putApiSequencesUpdateComment(
requestBody?: SequenceCommentDto,
): CancelablePromise<SequenceCommentDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/sequences/updateComment',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param commentId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiSequencesDeleteComment(
commentId: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/sequences/deleteComment/{commentId}',
            path: {
                'commentId': commentId,
            },
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns SequenceUserDto Success
     * @throws ApiError
     */
    public static postApiSequencesAddAccess(
id: number,
requestBody?: SequenceUserDto,
): CancelablePromise<SequenceUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sequences/{id}/addAccess',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param userId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiSequencesRemoveAccess(
id: number,
userId?: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/sequences/{id}/removeAccess',
            path: {
                'id': id,
            },
            query: {
                'userId': userId,
            },
            errors: {
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

}
