import { Alert, Button } from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { onResetPassword } from "store/slices/authSlice";
import * as yup from "yup";
import PasswordField from "components/forms/PasswordField";
import AuthCard from "components/cards/AuthCard";

const ResetPasswordView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showError, setError] = useState(false);

  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Ange lösenord tack!")
      .matches(
        /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Lösenordet måste innehålla minst 8 karaktärer, en versal, ett nummer och ett specialtecken"
      ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), ""],
        "Lösenorden måste överenstämma med varandra"
      )

      .required("Ange lösenord tack!"),
  });

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true);
        var result = await dispatch<any>(
          onResetPassword(
            searchParams.get("email")!,
            data.password,
            searchParams.get("token")!
          )
        );
        if (result) {
          navigate("/");
        } else setError(true);

        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
          <AuthCard
            title="Återställ lösenord"
            form={
              <>
                {showError && (
                  <Alert severity="error" sx={{ mb: "10px" }}>
                    Ett fel uppstod när lösenordet skulle ändras, försök igen
                  </Alert>
                )}
                <Box
                  sx={{
                    mb: 3,
                  }}
                >
                  <PasswordField
                    required
                    fullWidth
                    name="password"
                    label="Lösenord"
                    id="password"
                    size="small"
                  />

                  <PasswordField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Verifiera Lösenord"
                    id="password"
                    size="small"
                  />
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  sx={{
                    minWidth: 160,
                    fontSize: 16,
                    padding: "4px 16px 8px",
                  }}
                  type="submit"
                  fullWidth
                >
                  Spara
                </Button>
              </>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordView;
