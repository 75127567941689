import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { InfoAccordion } from "components/accordions/InfoAccordion";
import React, { useEffect, useState } from "react";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import RadarIcon from "@mui/icons-material/Radar";
import PositionSlideshow from "../../components/position/PositionSlideshow";
import { PositionDto } from "api";
import { PrePositionsAccordion } from "components/accordions/PrePositionsAccordion";
import { PostPositionsAccordion } from "components/accordions/PostPositionsAccordion";
import Grid from "@mui/material/Grid2";
import { getLevelTitle, getMainTypesText } from "utils/model";
import { useDispatch } from "react-redux";
import { loadPositionAsync } from "store/slices/positionSlice";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { useLocales } from "locales";

export interface PositionsInfoViewProp {
  position?: PositionDto | null;
  refreshData?: boolean;
  addToSequence?: boolean;
}

export default function PositionsInfoView({
  position,
  refreshData,
  addToSequence,
}: PositionsInfoViewProp) {
  const dispatch = useDispatch();
  const { t, currentLang } = useLocales();
  const [positionData, setPositionData] = useState<PositionDto | null>(null);

  useEffect(() => {
    setPositionData(position!);
    if (refreshData) refreshPositionData();
  }, [position]);

  const refreshPositionData = async () => {
    const data = await dispatch<any>(loadPositionAsync(position!.positionId!));
    setPositionData(data);
  };

  return (
    <Box sx={{ maxWidth: 1000 }}>
      <Stack spacing={0}>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, sm: 8 }}>
            <Typography fontSize={"2rem"} sx={{ pb: "5px" }}>
              {positionData?.sanskrit}
            </Typography>
            <Typography sx={{ pb: "10px" }}>
              {currentLang.value === "sv"
                ? `${positionData?.english} / ${positionData?.swedish}`
                : positionData?.english}
            </Typography>

            <Box
              sx={{
                backgroundColor: "#FFF",
                borderRadius: "10px",
                p: 2,
                mt: "20px",
              }}
            >
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, sm: 4 }}>
                  <Stack direction={"row"}>
                    <SignalCellularAltIcon sx={{ pr: "10px" }} />
                    <Typography sx={{ fontWeight: "bold" }}>
                      {t("level")}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid size={{ xs: 12, sm: 8 }}>
                  <Typography>
                    {t(getLevelTitle(positionData?.level!))}
                  </Typography>
                </Grid>

                <Grid size={{ xs: 12, sm: 4 }}>
                  <Stack direction={"row"}>
                    <SelfImprovementIcon sx={{ pr: "10px" }} />
                    <Typography sx={{ fontWeight: "bold" }}>
                      {t("type")}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid size={{ xs: 12, sm: 8 }}>
                  <Typography>
                    {positionData?.types?.map(
                      (item, index) =>
                        (index > 0 ? ", " : "") + t(getMainTypesText(item))
                    )}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }}>
                  <Stack direction={"row"}>
                    <RadarIcon sx={{ pr: "10px" }} />
                    <Typography sx={{ fontWeight: "bold" }}>
                      {t("mainPurpose")}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid size={{ xs: 12, sm: 8 }}>
                  <Typography sx={{ wordWrap: "break-word" }}>
                    {currentLang.value === "sv"
                      ? positionData?.mainPurpose
                      : positionData?.mainPurposeEnglish}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, sm: 4 }}>
            <PositionSlideshow data={positionData} />
          </Grid>
        </Grid>

        <Stack spacing={1}>
          {(positionData?.prePose1 ||
            positionData?.prePose2 ||
            positionData?.prePose3) && (
            <PrePositionsAccordion
              title={t("prePose")}
              data={positionData}
              addToSequence={addToSequence}
              refreshData
            />
          )}

          {(positionData?.postPose1 ||
            positionData?.postPose2 ||
            positionData?.postPose3) && (
            <PostPositionsAccordion
              title={t("postPose")}
              data={positionData}
              addToSequence={addToSequence}
              refreshData
            />
          )}

          {(positionData?.benefitsText ||
            positionData?.benefitsTextEnglish) && (
            <InfoAccordion
              title={t("benefitsText")}
              text={
                currentLang.value === "sv"
                  ? positionData?.benefitsText!
                  : positionData?.benefitsTextEnglish!
              }
            />
          )}
          {(positionData?.assistanceText ||
            positionData?.assistanceTextEnglish) && (
            <InfoAccordion
              title={t("assistanceText")}
              text={
                currentLang.value === "sv"
                  ? positionData?.assistanceText!
                  : positionData?.assistanceTextEnglish!
              }
            />
          )}
          {(positionData?.payAttentionToText ||
            positionData?.payAttentionToTextEnglish) && (
            <InfoAccordion
              title={t("payAttentionToText")}
              text={
                currentLang.value === "sv"
                  ? positionData?.payAttentionToText!
                  : positionData?.payAttentionToTextEnglish!
              }
            />
          )}
          {(positionData?.variantsText ||
            positionData?.variantsTextEnglish) && (
            <InfoAccordion
              title={t("variantsText")}
              text={
                currentLang.value === "sv"
                  ? positionData?.variantsText!
                  : positionData?.variantsTextEnglish!
              }
            />
          )}
          {(positionData?.instructionsText ||
            positionData?.instructionsTextEnglish) && (
            <InfoAccordion
              title={t("instructionsText")}
              text={
                currentLang.value === "sv"
                  ? positionData?.instructionsText!
                  : positionData?.instructionsTextEnglish!
              }
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
