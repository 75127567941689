import * as React from "react";
import { ReactNode, useEffect, useState } from "react";
import { Alert, Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { authStateSelector } from "store/slices/authSlice";

interface NoSubscriptionOverlayProps {
  children?: ReactNode;
  nosubscription?: boolean;
}

export const NoSubscriptionOverlay = (
  props: NoSubscriptionOverlayProps
): JSX.Element => {
  const { currentUser } = useSelector(authStateSelector);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(true);

  useEffect(() => {
    if (
      currentUser?.subscriptions &&
      currentUser?.subscriptions.length > 0 &&
      currentUser?.subscriptions[0].isActive
    )
      setHasActiveSubscription(true);
    else setHasActiveSubscription(false);
  }, [currentUser]);

  if (currentUser?.isAdmin) return <>{props.children}</>;

  if (props.nosubscription) return <>{props.children}</>;

  if (hasActiveSubscription) return <>{props.children}</>;

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          backgroundColor: "#000",
          position: "absolute",
          zIndex: 1,
          height: "100%",
          width: "100%",
          opacity: 0.1,
          borderRadius: '10px',
          
        }}
      ></Box>
      
      {props.children}
    </Box>
  );
};
