import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { authStateSelector } from "store/slices/authSlice";
import { Box } from "@mui/system";
import {
  initPaymentAsync,
  paymentStateSelector,
} from "store/slices/paymentSlice";

interface PaymentFormProps {
  returnPath: string;
}

const PaymentForm = ({ returnPath }: PaymentFormProps) => {
  const dispatch = useDispatch();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

  const { currentUser } = useSelector(authStateSelector);
  const { clientSecret, selectedPriceId } = useSelector(paymentStateSelector);

  useEffect(() => {
    dispatch<any>(
      initPaymentAsync(selectedPriceId, currentUser?.id!, returnPath)
    );
  }, []);
  
  console.log(clientSecret)

  return (
    <Box sx={{ backgroundColor: "transparent" }}>
      <div id="checkout">
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </Box>
  );
};

export default PaymentForm;
