import {
  Button,
  Card,
  CardMedia,
  MobileStepper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { ImageType, PositionDto } from "api";
import { Box, Stack, useTheme } from "@mui/system";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import { Watermark } from "@hirohe/react-watermark";

export interface PositionSlideshowProps {
  data?: PositionDto | null;
}

export default function PositionSlideshow({ data }: PositionSlideshowProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps =
    data != null && data.positionImages != null
      ? data.positionImages.length
      : 1;
  const photoList =
    data != null && data.positionImages != null ? data.positionImages : null;

  const defaultImage = "/images/no-image.png";

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  useEffect(() => {
    console.log(data?.positionImages);
  }, [data]);

  return (
    <Box
      sx={{
        p: 0,
        "& .MuiCard-root": {
          border: 0,
          backgroundColor: "#fff",
          boxShadow: "none",
          borderRadius: 3,
          maxWidth: { xs: "100%", sm: 300 },
          maxHeight: { xs: 300 },
        },
        "& .MuiCardMedia-root": {
          maxWidth: { xs: "100%", sm: 300 },
          maxHeight: { xs: 300 },
        },
        "& .MuiCardMedia-img": {
          width: { xs: "100%" },
          height: "calc(100vh - 1px) !important",
          maxWidth: { xs: "100%" },
          maxHeight: { xs: 300 },
        },
        "& .MuiMobileStepper-root": {
          backgroundColor: "transparent",
        },
        "& .MuiMobileStepper-dot": {
          border: 1,
          backgroundColor: "#fff",
        },
        "& .MuiMobileStepper-dotActive": {
          backgroundColor: theme.palette.primary.dark,
        },
        "& .PositionWatermark": {
          position: "absolute",
          bottom: 13,
          right: 13,
          opacity: 0.5,
          pointerEvents: "none"
        },
      }}
    >
      <Stack>
        <SwipeableViews
          style={{
            //backgroundColor: theme.palette.secondary.lighter,
            height: "100%",
          }}
          enableMouseEvents
          index={activeStep}
          onChangeIndex={handleStepChange}
        >
          {photoList && photoList.length > 0 ? (
            photoList.map((photoItem, index) => (
              <div key={index}>
                {photoItem.imageType === ImageType._1 && (
                  <Card variant="outlined">
                    <CardMedia
                      component="img"
                      sx={{pointerEvents:"none"}}
                      src={
                        photoItem.path != null
                          ? "/PositionImages/" + photoItem.path
                          : defaultImage
                      }
                      alt=""
                    />
                    <img
                      src="/images/symbol_vit.png"
                      width={"60px"}
                      className="PositionWatermark"
                    ></img>
                  </Card>
                )}
                {photoItem.imageType === ImageType._2 && (
                  <Card variant="outlined" sx={{ border: 3 }}>
                    <CardMedia
                      component="video"
                      controls
                      autoPlay
                      sx={{
                        objectFit: "contain",
                        height: "400px",
                        width: "400px",
                      }}
                      src={
                        photoItem.path != null
                          ? "/PositionImages/" + photoItem.videoPath
                          : defaultImage
                      }
                    />
                  </Card>
                )}
              </div>
            ))
          ) : (
            <Card variant="outlined" key={1}>
              <CardMedia component="img" src={defaultImage} alt="" />
            </Card>
          )}
        </SwipeableViews>
        <MobileStepper
          sx={{
            backgroundColor: theme.palette.secondary.lighter,
          }}
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="large"
              color="primary"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft fontSize="large" />
              ) : (
                <KeyboardArrowRight fontSize="large" />
              )}
            </Button>
          }
          backButton={
            <Button
              size="large"
              color="primary"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight fontSize="large" />
              ) : (
                <KeyboardArrowLeft fontSize="large" />
              )}
            </Button>
          }
        />
      </Stack>
    </Box>
  );
}
