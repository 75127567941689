import { Button, Drawer } from "@mui/material";
import { PhilosophyDto } from "api";
import React, { useState } from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import PhilosophyInfoView from "views/CardViews/PhilosophyInfoView";
import DrawerHeader from "./DrawerHeader";
import DrawerContent from "./DrawerContent";
import { alpha, useTheme } from "@mui/material/styles";

export interface PhilosophyDrawerProps {
  philosophy: PhilosophyDto;
  variant?: "readMore";
}

const PhilosophyDrawer = ({
  philosophy,
  variant = "readMore",
}: PhilosophyDrawerProps) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  function handleClose(): void {
    setOpen(false);
  }

  return (
    <div>
      {variant == "readMore" && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#EDEAE4",
            color: "#43594C",
            "&:hover": {
              color: "#fff",
              backgroundColor: alpha("#EDEAE4", 0.5),
            },
          }}
          onClick={() => setOpen(true)}
          endIcon={<ArrowCircleRightOutlinedIcon fontSize="inherit" />}
        >
          Läs mer
        </Button>
      )}

      <Drawer
        open={open}
        anchor={"right"}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#FFF",
            maxWidth: "1000px",
          },
        }}
      >
        <DrawerHeader handleClose={handleClose} />
        <DrawerContent>
          <PhilosophyInfoView philosophy={philosophy} />
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default PhilosophyDrawer;
