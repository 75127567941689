import { Box, Container, SxProps } from "@mui/system";
import React from "react";

export type DrawerHeaderProps = {
  children?: React.ReactNode;
  sx?: SxProps;
};

const DrawerContent = ({ children, sx }: DrawerHeaderProps) => {
  return (
    <Container
      sx={{
        backgroundColor: "#F5F4F0",
        overflowY: "scroll",
        pb: "40px",
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};

export default DrawerContent;
